body * {

	font-family: 'Poppins', sans-serif;

	&::-webkit-scrollbar {
	  width: 5px;
	  height: 5px;
	  transition: .3s background;
	}
  
	&::-webkit-scrollbar-thumb, &:hover::-webkit-scrollbar-thumb {
	  background: #c2cbdf;
	}
  
	&::-webkit-scrollbar-track {
	  background: $white;
	}
  }
  
  /*------ Headers -------*/
  
  .page-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 1.5rem 0 1.5rem;
	-ms-flex-wrap: wrap;
	justify-content: space-between;
	padding: 0;
	position: relative;
  }
  
  .page-title {
	margin: 0;
	font-size: 18px;
	font-weight: 700;
	line-height: 2.5rem;
	position: relative;
  }
  
  .page-title-icon {
	color: #9aa0ac;
	font-size: 1.25rem;
  }
  
  .page-subtitle {
	font-size: 0.8125rem;
	color: #6e7687;
	margin-left: 2rem;
	position: relative;
  
	a {
	  color: inherit;
	}
  }
  
  .page-options {
	margin-left: auto;
  }
  
  .page-description {
	margin: .25rem 0 0;
	color: #6e7687;
  
	a {
	  color: inherit;
	}
  }
  
  .page-single, .page-single-content {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
  }
  
  .content-heading {
	font-weight: 400;
	margin: 2rem 0 1.5rem;
	font-size: 1.25rem;
	line-height: 1.25;
  
	&:first-child {
	  margin-top: 0;
	}
  }
  
  .aside {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 22rem;
	background: $white;
	border-left: 1px solid $border;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	z-index: 100;
	visibility: hidden;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  }

  .aside-progress {
	  margin-right: 1.5rem;
	  margin-left: 1.5rem;
  }

  .aside-progress-position {
	  position: relative;
	  bottom: 1.5rem;
	  width: 100%;
  }
  
  @media (min-width: 1600px) {
	body.aside-opened .aside {
	  visibility: visible;
	}
  }
  
  .aside-body {
	padding: 1.5rem;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	overflow: auto;
  }
  
  .aside-footer {
	padding: 1rem 1.5rem;
	border-top: 1px solid $border;
  }
  
  .aside-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid $border;
  }
  
  .header {
	padding-top: .75rem;
	padding-bottom: .75rem;
	background: $white;
  }
  
  body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
  }
  
  .header {
	.dropdown-menu {
	  margin-top: 0;
	  border-radius: 0px 0 4px 4px !important;
	}
  
	.mega-menu {
	  width: 350px;
	}
  }
  
  .nav-unread {
	position: absolute;
	top: .10rem;
	right: .4rem;
	background: #ecd938;
	width: .5rem;
	height: .5rem;
	border-radius: 50%;
  
	&.badge {
	  position: absolute;
	  top: 0;
	  text-align: center;
	  right: 0;
	  width: 1.2rem;
	  height: 1.2rem;
	  border-radius: 50%;
	}
  }
  
  .nav-link.icon {
	padding: 5px;
	text-align: center;
	height: 2.5rem;
	font-size: 1.2rem;
	position: relative;
	border-radius: 3px;
  }
  
  .app-header.header .nav-link.icon {
	padding: 5px;
  }
  
  .nav-link.icon .nav-span {
	font-size: 0.9rem;
	color: #576482;
	vertical-align: middle;
	margin-left: 0.5rem;
	font-weight: 400;
  }
  
  .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
  }
  
  .app-header .header-brand {
	color: inherit;
	margin-right: 1rem;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 220px;
	text-align: center;
  
	.header-brand-img {
	  margin-right: 0;
	}
  }
  
  .app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
	text-align: center;
  
	.header-brand-img {
	  margin-right: 0;
	}
  }
  
  .header-brand:hover {
	color: inherit;
	text-decoration: none;
  }
  
  .header-brand-img {
	height: 2.5rem;
	line-height: 2rem;
	vertical-align: bottom;
	margin-right: 0;
	width: auto;
  }

  .desktop-lgo {
	  height: auto!important;
	  margin-top: -0.7rem;
  }

  .csp-brand-img {
	height: 70px;
	margin-right: 0;
	width: auto;
	margin-top: -1rem;
  }
  
  .header-btn.has-new {
	position: relative;
  
	&:before {
	  content: '';
	  width: 6px;
	  height: 6px;
	  background: #dc0441;
	  position: absolute;
	  top: 4px;
	  right: 4px;
	  border-radius: 50%;
	}
  }
  
  .header-toggler {
	width: 3rem;
	height: 3rem;
	position: relative;
	color: $black;
  
	&:hover {
	  color: #32e448;
	}
  }
  
  .header-toggler-icon {
	position: absolute;
	width: 1rem;
	height: 2px;
	color: inherit;
	background: currentColor;
	border-radius: 3px;
	top: 50%;
	left: 50%;
	margin: -2px 0 0 -.5rem;
	box-shadow: 0 5px currentColor, 0 -5px currentColor;
  }
  
  .footer {
	font-size: 12px;
	padding: 1.25rem 0;
  }
  
  footer.footer {
	font-size: 12px;
	border-top: 0;
	padding: 0 1.3rem 1rem;
	display:block;
	margin-top:auto;
  }
  
  .footer {
	.social {
	  ul li {
		float: left;
		padding: 7px;
  
		a {
		  color: #abb8c3;
		}
	  }
  
	  margin: 0 auto;
	}
  
	a:not(.btn) {
		color: #007BFF;
		font-weight: 800;
	}
  }
  
  @media print {
	.footer, .header {
	  display: none;
	}
  }
  
  .br-100 {
	border-radius: 100% !important;
  }
  
  .br-3 {
	border-radius: 3px !important;
  }
  
  .br-7 {
	border-radius: 7px !important;
  }
  
  .br-tl-7 {
	border-top-left-radius: 7px !important;
  }
  
  .br-bl-7 {
	border-bottom-left-radius: 7px !important;
  }
  
  .br-tr-7 {
	border-top-right-radius: 7px !important;
  }
  
  .br-br-7 {
	border-bottom-right-radius: 7px !important;
  }
  
  .br-0 {
	border-radius: 0px;
  }
  
  .br-tl-0 {
	border-top-left-radius: 0px !important;
  }
  
  .br-bl-0 {
	border-bottom-left-radius: 0px !important;
  }
  
  .br-tr-0 {
	border-top-right-radius: 0px !important;
  }
  
  .br-br-0 {
	border-bottom-right-radius: 0px !important;
  }
  
  .list.d-flex img {
	width: 60px;
  }
  
  #popup {
	position: absolute;
	width: auto;
	height: 30px;
	background: #fe6b1f;
	display: none;
	color: white;
	border-radius: 5px;
  }
  
  #copy {
	background: none;
	color: white;
	font-weight: bold;
	padding: 8px 25px;
	border: 0;
  }
  
  /*------ Charts styles ------*/
  
  .h-85 {
	height: 85px !important;
  }
  
  .chart-visitors {
	min-height: 18rem;
	overflow: hidden;
  }
  
  .chart-height {
	height: 20rem;
	overflow: hidden;
  }
  
  .chart-tasks {
	height: 15rem;
	overflow: hidden;
  }
  
  .chart-donut {
	height: 21rem;
	overflow: hidden;
  }
  
  .chart-pie {
	height: 22rem;
	overflow: hidden;
  }
  
  .chartsh {
	height: 16rem;
	overflow: hidden;
  }
  
  .chartwidget {
	height: 17rem;
	overflow: hidden;
  }
  
  /*-----Countdown-----*/
  
  .countdown span:first-child {
	font-size: 30px;
	font-weight: 500;
  }
  
  .social {
	i {
	  font-size: 40px;
	}
  
	.social-icon i {
	  font-size: 14px;
	}
  }
  
  .instagram {
	background: #de497b;
  }
  
  .linkedin {
	background: #0077b5;
  }
  
  .twitter {
	background: #1da1f2;
  }
  
  .facebook {
	background: #3b5998;
  }
  
  .widget-info {
	i {
	  width: 100px;
	  height: 100px;
	  padding: 27px 0;
	  text-align: center;
	  border: 2px solid $white;
	  border-radius: 50%;
	}
  
	a {
	  border-bottom: 1px solid $white;
	}
  }
  

  
  .z-index-10 {
	z-index: 10 !important;
  }
  
  .card-blog-overlay .card-header {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  
  .card-blog-overlay1:before, .card-blog-overlay2:before {
	content: '';
	display: block;
	position: absolute;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
	border-radius: 7px;
  }

  .page-breadcrumb {
	background: none;
	padding: 0;
	margin: 1rem 0 0;
	font-size: 0.875rem;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
  
	.breadcrumb-item {
	  color: #9aa0ac;
  
	  &.active {
		color: #6e7687;
	  }
	}
  }
  
  @media (min-width: 768px) {
	.page-breadcrumb {
	  margin: -.5rem 0 0;
	}
  }
  
  @media (max-width: 320px) {
	.fc-toolbar .fc-right {
	  float: right;
	  width: 100%;
	  text-align: center;
	  margin: 10px 0;
	}
  }
  
  .pagination-simple .page-item {
	.page-link {
	  background: none;
	  border: none;
	}
  
	&.active .page-link {
	  color: #495057;
	  font-weight: 700;
	}
  }
  
  .pagination-pager {
	.page-prev {
	  margin-right: auto;
	}
  
	.page-next {
	  margin-left: auto;
	}
  }
  
  .page-total-text {
	margin-right: 1rem;
	-ms-flex-item-align: center;
	align-self: center;
	color: #6e7687;
  }
  
  .icons-list {
	list-style: none;
	margin: 0 -1px -1px 0;
	padding: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
  
	> li {
	  -ms-flex: 1 0 2.5rem;
	  flex: 1 0 2.5rem;
	}
  }
  
  .icons-list-wrap {
	overflow: hidden;
  }
  
  .flags-icons .icons-list-item {
	line-height: inherit;
  }
  
  .payment-icons .icons-list-item {
	line-height: 2rem;
  }
  
  .icons-list-item {
	display: block;
	width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
	text-align: center;
	border-radius: 100%;
	font-size: 18px;
	border-radius: 50px;
	box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	border: 1px solid $border;
	vertical-align: middle;
	color: #5c678f;
	margin: 5px;
  }
  
  .img-gallery {
	margin-right: -.25rem;
	margin-left: -.25rem;
	margin-bottom: -.5rem;
  
	> {
	  .col, [class*="col-"] {
		padding-left: .25rem;
		padding-right: .25rem;
		padding-bottom: .5rem;
	  }
	}
  }
  
  .link-overlay {
	position: relative;
  
	&:hover .link-overlay-bg {
	  opacity: 1;
	}
  }
  
  .link-overlay-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(70, 127, 207, 0.8);
	display: -ms-flexbox;
	display: flex;
	color: $white;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.25rem;
	opacity: 0;
	transition: .3s opacity;
  }
  
  .media-icon {
	width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
	text-align: center;
	border-radius: 100%;
	font-size: 18px;
  }
  
  .media-list {
	margin: 0;
	padding: 0;
	list-style: none;
  }
  
  textarea[cols] {
	height: auto;
  }
  
  /* --------Added--------- css*/
  
  .overflow-hidden {
	overflow: hidden;
  }
  
  .user-profile .user-pro-body .u-dropdown {
	color: $white;
  }
  
  #sidebar {
	ul li {
	  a {
		color: #384056;
		font-weight: 400;
	  }
  
	  position: relative;
  
	  ul li:last-child {
		border-bottom: 0;
	  }
	}
  
	li a[aria-expanded="true"]:hover {
	  color: $white !important;
	}
  }
  
  .timeline__content {
	background-color: #eaf0f9;
  }
  
  .tab_wrapper {
	> ul {
	  li {
		border: 1px solid $border;
		border-top: 1px solid $border;
	  }
  
	  border-bottom: 1px solid $border;
	}
  
	&.right_side {
	  .content_wrapper {
		border: 1px solid $border;
	  }
  
	  > ul {
		li {
		  &.active {
			border-color: 1px solid $border;
		  }
  
		  &:after {
			background: 1px solid $border;
		  }
		}
  
		border-bottom: 1px solid $border;
	  }
	}
  }
  
  .text-right {
	text-align: right;
  }
  

  /*--- img container---*/
  
  .search {
	position: absolute;
	width: 320px;
  
	svg {
	  transform: translateX(-126px);
	  width: 180px;
	  height: auto;
	  stroke-width: 8px;
	  stroke: $white;
	  stroke-width: 1px;
	  stroke-dashoffset: 0;
	  stroke-dasharray: 64.6 206.305;
	  transition: all 0.5s ease-in-out;
	  margin-top: 5px;
	}
  }
  
  .input-search {
	position: absolute;
	width: calc(100% - 148px);
	height: 34px;
	top: 0;
	right: 20px;
	bottom: 0;
	left: 0;
	border: none;
	background-color: transparent;
	outline: none;
	padding: 20px;
	font-size: 16px;
	color: $white;
  }
  
  .search-label {
	position: absolute;
	display: block;
	width: 50px;
	height: 50px;
	top: 0;
	left: 50%;
	margin-left: -54px;
	z-index: 100;
	transition: 0.5s ease-in-out;
  }
  
  .isActive {
	.search-label {
	  transform: translateX(246px);
	}
  
	svg, &.full svg {
	  stroke-dashoffset: -65;
	  stroke-dasharray: 141.305 65;
	  transform: translateX(0);
	}
  }
  
  .full {
	.search-label {
	  transform: translateX(246px);
	}
  
	svg {
	  stroke-dashoffset: 0;
	  stroke-dasharray: 64.6 206.305;
	  transform: translateX(0);
	}
  }
  
  
  .search-inline {
	width: 100%;
	left: 0;
	padding: 33px 0;
	top: 0;
	position: absolute;
	opacity: 0;
	visibility: hidden;
	background-color: $white;
	z-index: 9;
	transition: all .5s ease-in-out;
  
	&.search-visible {
	  opacity: 1;
	  visibility: visible;
	  animation: fadeInDown 0.2s ease-in-out;
	}
  
	button[type="submit"] {
	  position: absolute;
	  right: 52px;
	  top: 0;
	  background-color: transparent;
	  border: 0px;
	  top: 0;
	  right: 80px;
	  padding: 0px;
	  cursor: pointer;
	  width: 80px;
	  height: 100%;
	  color: $white;
	}
  
	.form-control {
	  border: 0px;
	  padding-left: 0;
	  font-size: 20px;
	  position: absolute;
	  left: 1%;
	  top: 0;
	  height: 100%;
	  width: 99%;
	  outline: none;
  
	  &:focus {
		box-shadow: none;
	  }
	}
  
	.search-close {
	  position: absolute;
	  top: 0;
	  right: 0;
	  color: #616161;
	  width: 80px;
	  height: 100%;
	  text-align: center;
	  display: table;
	  background: #efefef;
	  text-decoration: none;
  
	  i {
		display: table-cell;
		vertical-align: middle;
	  }
	}
  }
  
  .searching i {
	font-size: 18px;
  }
  
  .search-close i {
	padding: 24px 0;
  }
  
  @keyframes fadeInDown {
	from {
	  opacity: 0;
	  transform: translate3d(0, -20%, 0);
	}
  
	to {
	  opacity: 1;
	  transform: translate3d(0, 0, 0);
	}
  }
  
  .lockscreen {
	background: inherit;
	width: 300px;
	height: 280px;
	overflow: hidden;
  
	&:before {
	  content: '';
	  width: 350px;
	  height: 400px;
	  background: inherit;
	  position: absolute;
	  left: -25px;
	  right: 0;
	  top: -25px;
	  bottom: 0;
	  box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.4);
	  filter: blur(1px);
	}
  }
  
  .table-stats table {
	th, td {
	  border: none;
	  border-bottom: 1px solid #e8e9ef;
	  font-size: 12px;
	  font-weight: normal;
	  padding: .75em 1.25em;
	  text-transform: uppercase;
	}
  
	th img, td img {
	  margin-right: 10px;
	  max-width: 45px;
	}
  
	th .name {
	  font-size: 14px;
	  text-transform: capitalize;
	}
  
	td {
	  .name {
		font-size: 14px;
		text-transform: capitalize;
	  }
  
	  font-size: 14px;
	  text-transform: capitalize;
	  vertical-align: middle;
	}
  }
  
  .traffic-sales-content {
	padding: 14px;
  }
  
  .traffic-sales-amount {
	float: right;
  }
  
  .pulse {
	display: block;
	position: absolute;
	top: 11px;
	right: 15px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #ff3547;
	cursor: pointer;
	box-shadow: 0 0 0 rgba(247, 45, 102, 0.9);
	animation: pulse 2s infinite;
  
	&:hover {
	  animation: none;
	}
  }
  
  @-webkit-keyframes pulse {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.9);
	}
  
	70% {
	  -webkit-box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
	}
  
	100% {
	  -webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
	}
  }
  
  @keyframes pulse {
	0% {
	  -moz-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.9);
	  box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.7);
	}
  
	70% {
	  -moz-box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
	  box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
	}
  
	100% {
	  -moz-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
	  box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
	}
  }
  
  @media (max-width: 992px) {
	.header.top-header {
	  .header-option, .header-setting {
		display: none;
	  }
	}
	.hor-header.header {
		position: fixed;
		width: 100%;
		z-index: 999;
	}
	.hor-content.main-content {
		margin-top: 6rem;
	}
	.horizontal-main.hor-menu {
	  border-bottom: 0;
	}
  
	.page-body {
	  padding-top: 1px;
	}
  
	.app-content.page-body .container, .header.top-header .container, .hor-content.main-content .container {
	  max-width: none;
	}
  
	.active .header.top-header {
	  z-index: 999;
	}
  
	.app-content.page-body .side-app {
	  padding: 6.2rem 0.75rem 0 0.75rem !important;
	}
  
	.navsearch {
	  margin: 5px;
	  padding: 0px;
	  text-align: center;
	  height: 2.5rem;
	  font-size: 1.2rem;
	  position: relative;
	  border: 0px solid $border;
	  border-radius: 3px;
  
	  svg {
		font-size: 25px;
		line-height: 30px;
		text-align: center;
		vertical-align: middle;
		width: 35px;
		height: 35px;
		background: $white;
		padding: 8px;
		border-radius: 50px;
		box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
		border: 1px solid $border;
	  }
  
	  i {
		padding-top: 0 !important;
	  }
	}
  
	.app-content.page-body {
	  margin-top: 4.7rem;
	}
  
	.comb-page .app-content.page-body {
	  margin-top: 0;
	}
  }
  
  .header.top-header {
	.search-element input {
	  border-radius: 4px;
	}
  }
  
  @media (max-width: 992px) {
	.media.profile-footer .media-user {
	  display: none;
	}
  }
  
  @media (max-width: 992px) and (min-width: 768px) {
	.header-brand-img.desktop-lgo {
	  margin-left: 2rem;
	}
  
	.hor-header .header-brand-img.desktop-lgo, .header-brand-img.dark-logo {
	  margin-left: 1rem;
	}
  
	.animated-arrow.hor-toggle {
	  text-align: center;
	  height: 2.5rem;
	  width: 2.5rem;
	  font-size: 1.2rem;
	  position: relative;
	  border: 1px solid $border;
	  border-radius: 50px;
	  margin: 5px;
	  top: 0;
  
	  span {
		margin-left: 8px;
		margin-top: 2px;
		vertical-align: middle;
	  }
	}
  }
  
  .activity {
	position: relative;
	border-left: 3px solid $border;
	margin-left: 16px;
  
	.img-activity {
	  width: 40px;
	  height: 40px;
	  text-align: center;
	  line-height: 44px;
	  border-radius: 50%;
	  position: absolute;
	  left: -20px;
	  border: 3px solid;
	}
  
	.item-activity {
	  margin-left: 40px;
	  margin-bottom: 26px;
	}
  }
  
  #AreaChart2 {
	width: 105% !important;
	height: 88px !important;
	bottom: -20px;
	position: relative;
	left: -7px;
  }
  
  #CryptoChart, #CryptoChart1, #CryptoChart2, #CryptoChart3 {
	width: 105% !important;
	height: 70px !important;
	bottom: -15px;
	position: relative;
	left: -7px;
  }
  
  /* News ticker */
  
  [class*="js-conveyor-"] ul li {
	padding: 9px 15px;
  
	/* line-height: 47px; */
	border: 1px solid #efefef;
	margin-right: 5px;
	background: $white;
	margin: 15px 6px;
	border-radius: 5px;
	box-shadow: 0 0px 10px -5px rgba(68, 84, 195, 0.5);
  }
  
  .news-crypto span {
	font-size: 0.8rem;
  }
  
  /*latetes news timeline */
  
  .latest-timeline {
	height: 355px;
  
	ul.timeline {
	  list-style-type: none;
	  position: relative;
  
	  &:before {
		content: ' ';
		display: inline-block;
		position: absolute;
		left: 10px;
		height: 100%;
		z-index: 1;
		border-left: 3px dotted $border;
	  }
  
	  > li {
		margin: 20px 0;
		padding-left: 2.5rem;
  
		&:before {
		  content: ' ';
		  background: white;
		  display: inline-block;
		  position: absolute;
		  border-radius: 50%;
		  border: 3px solid #1753fc;
		  left: 5px;
		  width: 13px;
		  height: 13px;
		  z-index: 1;
		}
  
		&:first-child:before {
		  border: 2px solid #0dd157;
		}
  
		&:nth-child(2):before {
		  border: 2px solid #2d9eef;
		}
  
		&:nth-child(3):before {
		  border: 2px solid #f35e90;
		}
  
		&:nth-child(4):before {
		  border: 2px solid $orange;
		}
  
		&:nth-child(5):before {
		  border: 2px solid #05a5ba;
		}
  
		&:nth-child(6):before {
		  border: 2px solid #920bfa;
		}
  
		&:last-child:before {
		  border: 2px solid #ec2d38;
		}
	  }
	}
  }
  
  .app-content.page-body {
	min-height: 67vh;
  }
  
  .time-data {
	font-size: 14px;
	font-weight: 500;
  }
  
  /*
   *  Accordion-Wizard-Form
   * ----------------------
   */
  
  div {
	&[data-acc-content] {
	  display: none;
	}
  
	&[data-acc-step]:not(.open) {
	  background: #f0f0f2;
  
	  h5 {
		color: #8e9cad;
	  }
  
	  .badge-primary {
		background: #afb4d8;
	  }
	}
  }
  
  #form .list-group-item:hover {
	background: none;
  }
  
  
  .sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
	background: transparent;
  }
  
  .fc-widget-content .fc-bgevent {
	background-color: #f0f0f2 !important;
  }
  
  .apexcharts-toolbar {
	z-index: 0 !important;
	display: none;
  }
  
  .card-bgimg {
	background-size: cover;
	position: relative;
	border-radius: 3px;
	overflow: hidden;
	border: 0;
  
	&:before {
	  opacity: 0.9;
	  border-radius: 3px;
	  content: '';
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  left: 0;
	  right: 0;
	  display: block;
	  top: 0;
	}
  
	.card-body {
	  z-index: 10;
	}
  }
  
  /*Horizontal Centerlogo */
  
  @media (min-width: 992px) {
	.top-header.hor-top-header {
	  .header-brand-img {
		display: none;
	  }
  
	  .header-brand.center-logo .desktop-lgo {
		left: 0;
		display: block;
		position: absolute;
		margin: 0 auto;
		right: 0px;
	  }
  
	  .form-inline .form-control {
		width: 175px;
	  }
  
	  .header-brand {
		margin-right: 0rem;
	  }
	}
  
	.search-element {
	  margin-top: 4px;
	}
  
	.sticky-pin {
	  position: fixed;
	  top: 0;
	  width: 100%;
	  z-index: 99;
	}
  }
  
  @media (max-width: 991px) {
	.top-header.hor-top-header .header-brand.center-logo .desktop-lgo {
	  display: none;
	}
  
	.header.top-header.hor-top-header {
	  border-bottom: 0px;
	}
  }
  
  .icon.icon-shape {
	width: 2.5rem;
	height: 2.2rem;
	line-height: 2.2rem;
	text-align: center;
	font-size: 12px;
  }
  
  .h-250 {
	height: 150px;
  }
  
  .latest-timeline1 li {
	position: relative;
  }
  
  .latest-timeline.latest-timeline1-icon ul.timeline > li:before {
	display: none;
  }
  
  .latest-timeline1-icon {
	position: absolute;
	width: 2rem;
	height: 2rem;
	border-radius: 7px;
	line-height: 2rem;
	text-align: center;
	left: 0;
	z-index: 1;
	color: $white;
	font-weight: 500;
  }
  
  .h-330 {
	height: 330px;
  }
  
  .shadow {
	box-shadow: 0 2px 16px rgba(179, 186, 212, 0.7) !important;
  }
  
  .shadow3 {
	box-shadow: 0 0px 6px rgba(179, 186, 212, 0.7) !important;
  }
  
  .apexcharts-tooltip.light {
	border: 1px solid #e3e3e3;
	background: rgba(255, 255, 255, 0.96);
	box-shadow: 0 0px 17px -5px rgba(68, 84, 195, 0.5);
  }
  
  .chart-dropshadow-primary {
	filter: drop-shadow((-6px) 12px 4px rgba(68, 84, 195, 0.2));
  }
  
  .icon-dropshadow {
	filter: drop-shadow(0px 10px 4px rgb(229, 233, 242));
  }
  
  .icon-dropshadow-info {
	filter: drop-shadow(0px 4px 4px rgba(69, 170, 242, 0.3));
  }
  
  .icon-dropshadow-success {
	filter: drop-shadow(0px 4px 4px rgba(45, 206, 137, 0.3));
  }
  
  .icon-dropshadow-danger {
	filter: drop-shadow(0px 4px 4px rgba(255, 91, 81, 0.3));
  }
  
  .icon-dropshadow-warning {
	filter: drop-shadow(0px 4px 4px rgba(236, 180, 3, 0.3));
  }
  
  .chart-dropshadow {
	-webkit-filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.5));
	filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.5));
  }
  
  .GradientlineShadow {
	-webkit-filter: drop-shadow(0 -8px 4px rgba(179, 186, 212, 0.2));
	filter: drop-shadow(0 -8px 4px rgba(179, 186, 212, 0.2));
  }
  
  .BarChartShadow {
	-webkit-filter: drop-shadow(0px 1px 4px rgba(179, 186, 212, 0.3));
	filter: drop-shadow(0px 1px 4px rgba(179, 186, 212, 0.3));
  }
  
  .donutShadow {
	-webkit-filter: drop-shadow((-5px) 0px 10px rgba(179, 186, 212, 0.4));
	filter: drop-shadow((-5px) 0px 10px rgba(179, 186, 212, 0.4));
  }
  
  .transaction-table.table {
	td {
	  vertical-align: middle;
	}
  
	.apexcharts-tooltip.light {
	  display: none;
	}
  
	thead th {
	  padding-bottom: 20px;
	}
  }
  
  .inde4-table tr {
	td:first-child {
	  position: relative;
  
	  &:before {
		content: '';
		position: absolute;
		width: 2px;
		height: 20px;
		left: 0px;
		top: 17px;
	  }
	}
  
	&:nth-child(1) td:first-child:before {
	  background: rgba(68, 84, 195, 0.7);
	}
  
	&:nth-child(2) td:first-child:before {
	  background: rgba(247, 45, 102, 0.7);
	}
  
	&:nth-child(3) td:first-child:before {
	  background: rgba(45, 206, 137, 0.7);
	}
  
	&:nth-child(4) td:first-child:before {
	  background: rgba(69, 170, 242, 0.7);
	}
  
	&:nth-child(5) td:first-child:before {
	  background: rgba(236, 180, 3, 0.7);
	}
  
	&:nth-child(6) td:first-child:before {
	  background: rgba(255, 91, 81, 0.7);
	}
  
	&:nth-child(7) td:first-child:before {
	  background: rgba(68, 84, 195, 0.7);
	}
  
	&:nth-child(8) td:first-child:before {
	  background: rgba(247, 45, 102, 0.7);
	}
  
	&:nth-child(9) td:first-child:before {
	  background: rgba(45, 206, 137, 0.7);
	}
  
	&:nth-child(10) td:first-child:before {
	  background: rgba(69, 170, 242, 0.7);
	}
  }
  
  .crypto-icon {
	width: 3rem;
	height: 3rem;
	border-radius: 4px;
	line-height: 3rem;
	text-align: center;
	font-size: 1.8rem;
	margin-right: 18px;
  }
  
  .crypto-tabs {
	padding: 0 !important;
	border: 0 !important;
  
	.tabs-menu1 ul li {
	  .active {
		background: $white;
		border-bottom: 0;
		border-radius: 3px 3px 0 0;
		box-shadow: 0px 10px 10px 20px rgba(179, 186, 212, 0.09), 10px 10px 15px -5px rgb(176, 184, 214);
		border: 0px solid $border;
		border-bottom: 0;
	  }
  
	  a {
		display: block;
		font-size: 16px;
	  }
	}
  }
  
  table.table-bordered.dataTable td:first-child {
	border-left: 0px solid $border !important;
  }
  
  .js-conveyor-example {
	&:before {
	  width: 20%;
	  position: absolute;
	  content: '';
	  height: 60px;
	  background: linear-gradient(to right, #f0eff1, transparent);
	  z-index: 9;
	  left: 0;
	  top: 8px;
	}
  
	&:after {
	  width: 20%;
	  position: absolute;
	  content: '';
	  height: 60px;
	  background: linear-gradient(to left, #f0eff1, transparent);
	  z-index: 9;
	  right: 0;
	  top: 8px;
	}
  }
  
  .user-info .social {
	li {
	  display: inline-block;
	}
  
	.social-icon {
	  color: $white;
	  width: 2.5rem;
	  height: 2.5rem;
	  display: block;
	  border: 1px solid rgba(255, 255, 255, 0.1);
	  background: rgba(255, 255, 255, 0.1);
	  line-height: 2.5rem;
	  border-radius: 50%;
	}
  }
  
  .date-range-btn {
	font-size: 0.9375rem;
  }
  
  .side-menu__icon {
	color: #344050;
	opacity: 0.9;
  }
  
  .sidemneu-icon2 {
	fill: #8e98db;
  }
  
  .svg-icon {
	color: #5c678f;
	fill: #5c678f;
  }
  
  .svg-icon1 {
	font-size: 25px;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	margin-right: 5px;
	width: 37.5px;
	height: 37.5px;
	background: $white;
	padding: 8px;
	border-radius: 50px;
	box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	border: 1px solid $border;
	color: #5c678f;
	fill: #5c678f;
  }
  
  .profile-dropdown .header-icon {
	line-height: 20px;
  }
  
  .header-option {
	.header-icon {
	  margin-top: -3px;
	}
  
	.nav-link {
	  margin: 7px 0 0 0;
	}
  }
  
  .card-custom-icon.text-success {
	fill: #2dce89;
  }
  
  .feature .feature-icon {
	width: 4rem;
	height: 4rem;
	line-height: 4rem;
	border-radius: 50%;
	background: rgba(68, 84, 195, 0.2);
	text-align: center;
	font-size: 25px;
  }
  
  .expenses-card {
	#Chart {
	  width: 105% !important;
	  bottom: -15px;
	  position: relative;
	  left: -7px;
	  height: 120px !important;
	}
  
	.card-body {
	  position: absolute;
	  top: 0;
	}
  
	padding-top: 170px;
  }
  
  .page-rightheader .breadcrumb {
	padding: 0;
	background: transparent;
  }
  
  .under-countdown .countdown {
	color: $white;
	padding: 20px;
	border-radius: 5px;
  }
  
  /*----- clipboard-----*/
  
  .clip-widget {
	position: relative;
  }
  
  .clipboard-icon {
	position: absolute;
	top: 10px;
	right: 10px;
	border-radius: 0px;
	background: $white;
	color: #212529;
	border-radius: 5px;
	width: 35px;
	cursor: pointer;
  
	/* line-height: 37px; */
	height: 35px;
	margin: 0 auto;
	text-align: center;
	border: 1px solid rgba(255, 255, 255, 0.1);
  
	i {
	  margin-top: 5px;
	  font-size: 20px;
	}
  }
  
//   .highlight {
// 	position: relative;
// 	background: #f7f6fd;
// 	padding: 0;
// 	border-radius: 0 0 3px 3px;
  
// 	pre {
// 	  margin-bottom: 0;
// 	  max-height: 15rem;
// 	  -moz-tab-size: 4;
// 	  -o-tab-size: 4;
// 	  tab-size: 4;
// 	  -webkit-hyphens: none;
// 	  -moz-hyphens: none;
// 	  -ms-hyphens: none;
// 	  hyphens: none;
// 	}
//   }
  
  /*----- clipboard-----*/
  
  table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > {
	td:first-child:before, th:first-child:before {
	  top: 12px;
	  left: 4px;
	  height: 14px;
	  width: 14px;
	  display: block;
	  position: absolute;
	  color: white;
	  border: 2px solid #4757c4;
	  border-radius: 14px;
	  box-shadow: none;
	  box-sizing: content-box;
	  text-align: center;
	  text-indent: 0 !important;
	  line-height: 14px;
	  content: '+';
	}
  }
  
  .usertab-list li {
	margin: 5px 0;
  }
  
  .profile-media-img {
	border: 0px solid #efefef;
	display: block;
  
	h6 {
	  position: absolute;
	  bottom: 15px;
	  left: 15px;
	  color: $white;
	}
  
	position: relative;
	display: block;
  
	&:before {
	  content: '';
	  background: rgba(0, 0, 0, 0.5);
	  left: 0;
	  right: 0;
	  top: 0;
	  bottom: 0;
	  display: block;
	  position: absolute;
	}
  
	h6 a {
	  position: relative;
	  z-index: 999;
	}
  }  

  #form .list-group-item {
	padding: 25px;
  }
  
  .custom-switch {
	padding-left: 0;
	font-size: 12px;
  }

  .custom-switch-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
  }

  .custom-switch-indicator {
	display: inline-block;
	height: 1.25rem;
	width: 2.25rem;
	background: #f5f9fc;
	border-radius: 50px;
	position: relative;
	vertical-align: bottom;
	border: 1px solid $border;
	transition: .3s border-color, .3s background-color;
  
	&:before {
	  content: '';
	  position: absolute;
	  height: calc(1.25rem - 4px);
	  width: calc(1.25rem - 4px);
	  top: 1px;
	  left: 1px;
	  background: $white;
	  border-radius: 50%;
	  transition: .3s left;
	  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
	}
  }
  
  .custom-switch-input:checked ~ .custom-switch-indicator:before {
	left: calc(1rem + 1px);
  }

  .custom-switch-input:focus ~ .custom-switch-indicator {
	box-shadow: none;
  }
  
  .custom-switch-description {
	margin-left: .5rem;
	color: #6e7687;
	transition: .3s color;
  }
  
  .custom-switch-input:checked ~ .custom-switch-description {
	color: $primaryTextColor;
  }
  
  .page-rightheader .date-range-btn {
	position: relative;
	padding-left: 40px;
  }
  
  .main-profile {
	position: relative;
	background: #FFF;
	padding: 25px;
	padding-bottom: 0;
	margin-bottom: 30px;
	border-top: 1px solid $border;
	border-radius: 8px;
	overflow: hidden;
  
	.tab-menu-heading {
	  border-left: 0;
	  border-right: 0;
	  margin: 0 -30px;
	  margin-top: 25px;
	}
  }
  
  .profile-edit {
	position: relative;
  }

  #profile-pages {
	a {
		&:hover, &:focus {
			color: $primaryTextActiveColor!important;
		}
	}
  }
  
  .profile-share {
	width: 100%;
	padding: 10px;
	background: $white;
	border-radius: 0 0 5px 5px;
  
	a {
	  background: $white;
	  color: #5c678f;
	  width: 2.5rem;
	  height: 2.5rem;
	  line-height: 2.5rem;
	  text-align: center;
	  border-radius: 100%;
	  font-size: 18px;
	  border-radius: 50px;
	  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	  border: 1px solid $border;
	  vertical-align: middle;
	  margin-right: 5px;
	  display: inline-block;
	}
  }
  
  .main-proifle .profile-icon, .profile-footer .new, .pro-option {
	background: $white;
	color: #5c678f;
	width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
	text-align: center;
	border-radius: 100%;
	font-size: 18px;
	border-radius: 50px;
	box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	border: 1px solid $border;
	vertical-align: middle;
	margin-right: 10px;
	display: inline-block;
  }
  
  .pro-details li {
	width: 50%;
	float: left;
	margin-bottom: 0.5rem;
  }
  
  .profile-edit textarea {
	background: $white;
	border-radius: 5px 5px 0 0;
  
	&:focus {
	  background: $border;
	  border: 1px solid $border;
	}
  }
  
  .social-profile-buttons li {
	float: left;
	display: block;
  
	a {
	  display: block;
	  width: 2rem;
	  height: 2rem;
	  line-height: 2rem;
	  border-radius: 50%;
	  background: #d2d6f1;
	  border: 2px solid $text-color;
	  margin: 2px;
	}
  }
  
  .fill-white {
	fill: transparent;
  }
  
  .page-header {
	.breadcrumb-icon {
	  margin-top: 3px;
	  margin-left: 4px;
	}
  
	.breadcrumb-item {
	  .svg-icon {
		width: 20px;
	  }
  
	  &:first-child {
		margin-top: 0;
	  }
	}
  }

  .only-subscription {
	  display: block;
  }
  
  .main-nav-calendar-event .nav-link {
	padding: 0;
	height: 30px;
	line-height: 30px;
  
	&.exclude {
	  position: relative;
	  opacity: 0.3;
  
	  &:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		border-top: 1px solid #737688;
	  }
	}
  }
  
  .form-wizard-title {
	line-height: 25px;
	margin-left: 10px;
  }
  
  .footer .social .social-icon {
	width: 40px;
	height: 40px;
	line-height: 40px;
	display: block;
	border: 1px solid $border;
	border-radius: 50%;
	background: $white;
	box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	border: 1px solid $border;
  }
  
  .richText {
	.richText-undo, .richText-redo {
	  border-right: $border solid 1px !important;
	}
  }
  
  .modal-open .select2-dropdown {
	z-index: 10000 !important;
  }


  .templates-scroller {
	width: 300px;
  }

  .page-leftheader,
  .page-rightheader {
	ol {
	  padding-left: 0;
	  background: none;
	  padding: 0;

	}
  
	.breadcrumb-item {
	  + .breadcrumb-item::before {
		color: $text-color;
		padding-left: 0;
		font: var(--fa-font-solid);
		content: "\f101";
		font-weight: 400;
		float: none;
		font-size: 8px;
	  }
	  
	  font-size: 11px;
	  font-family: 'Poppins', sans-serif;
	  margin-top: 0;
  
	  a {
		color: $primaryTextColor;
	  }
	}
  }
  
  .sub-pageheader {
	display: flex;
	margin-bottom: 2.3rem;
	font-size: 14px;
  
	.nav-link {
	  &:first-child {
		padding-left: 0;
	  }
  
	  &:last-child {
		padding-right: 0;
	  }
  
	  &.border-right {
		border-right: 1px solid #cfd1e1 !important;
	  }
	}
  }
  
  hr.pageheader-border {
	border-top: 1px solid #dfe0e7;
	margin-top: 0.9rem;
	margin-bottom: 0.9rem;
  }
  
  @media (max-width: 992px) {
	.sub-pageheader {
	  .nav-link {
		display: none;
	  }
  
	  .left-nav .nav-link {
		&:first-child, &:nth-child(2) {
		  display: block;
		}
	  }
  
	  .right-nav .nav-link.d-md-none {
		display: block;
	  }
	}
  
	.page-header {
	  display: block;
  
	  .page-leftheader ol {
		padding-top: 0;
	  }
	}
  
	.page-rightheader h6 {
	  font-size: 10px;
	}
  }
  
  .dash1-card {
	p {
	  font-size: 14px;
	}
  
	position: absolute;
  
	.ratio {
	  position: absolute;
	  right: -10px;
	  top: 20px;
	  padding: 5px 15px;
	  background-color: #222222;
	  font-size: 13px;
	  line-height: 20px;
	  font-weight: 600;
	  color: $white;
	  border-radius: 15px 0px 0px 15px;
	}
  
	.ratio-text {
	  position: absolute;
	  top: 55px;
	  right: 5px;
	  font-size: 13px;
	}
  }
  
  .sales-bar {
	min-height: 280px !important;
	position: relative;
	bottom: -21px;
	left: -10px;
  }

  
  .header-dropdown-list .ps__thumb-y {
	opacity: 0;
  }
  
  
  .page-header.main-pageheader {
	margin-bottom: 0;
  }
  
  .page-rightheader .breadcrumb-item {
	a {
	  color: $text-color;
	}
  
  }
  
  .card-over {
	position: relative;
	top: -20px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	background: $white;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
	border: 1px solid #dbe2eb;
	box-shadow: $card-shadow;
	margin-bottom: 1.5rem !important;
  }
  
  .bread-select {
	display: flex;
  
	.select2-container .select2-selection--single .select2-selection__rendered {
	  padding-left: 10px !important;
	}
  }
  
  .emp-circle-chart {
	background: rgba(0, 0, 0, 0.04);
	border-radius: 100px;
	font-weight: 500;
	font-size: 20px;
  }
  
  .dash1-iocns {
	padding: 6px 14px;
	border-radius: 50px;
	border: 2px dotted;
  }
  
  .country-card {
	overflow: auto;
  }
  
  #talent-chart {
	-webkit-filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.5));
	filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.2));
  }
  
  .custom-btns .btn {
	border: 1px solid rgba(0, 0, 0, 0.1);
	text-align: center;
	background: 0 0;
	font-size: 20px;
	color: rgba(255, 255, 255, 0.9);
	margin: 0 5px;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.1);
	padding: 0.5rem;
	width: 3rem;
	height: 3rem;
	line-height: 2rem;
  }
  
  .widget-user-image {
	height: 100px;
	width: 100px;
	clear: both;
	margin: 0 auto;
	display: block;
	border-radius: 100%;
	box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
	position: relative;
  }

  .widget-user-image-sm {
	height: 44px;
	width: 44px;
	clear: both;
	display: block;
	border-radius: 0.42rem;
	background: #E1F0FF;
	position: relative;
  }

  .widget-user-name {
	  line-height: 1.8;
  }

  .vendor-image-sm {
	clear: both;
	display: block;
	position: relative;

	img {
		width: 20px;
	}
  }
  
  .header .nav-link.icon i {
	color: #5c678f;
  }
  
  .mail-inbox .list-group-item {
	border: 0 !important;
  }
  
  .user-details {
	.user-icon {
	  background: $white;
	  color: #5c678f;
	  width: 2.5rem;
	  height: 2.5rem;
	  line-height: 2.5rem;
	  text-align: center;
	  border-radius: 100%;
	  font-size: 18px;
	  border-radius: 50px;
	  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	  border: 1px solid $border;
	  vertical-align: middle;
	  margin-right: 10px;
	  display: inline-block;
	}
  
	span.h6 {
	  vertical-align: middle;
	}
  }
  
  .item7-card-desc .fe {
	color: #5c678f;
  }
  
  .nav-link.icon .header-icon {
	font-size: 17px;
	text-align: center;
	vertical-align: middle;
	color: $primaryTextColor;//#475F7B;
	padding: 11px 10px 10px;
  }
  
  .profile-dropdown {
	img {
	  width: 40px;
	  height: 40px;
	  border: 1px solid $border;
	  border-radius: 50%;
	  box-shadow: $special-shadow;
	  margin-top: -3px;
	  object-fit: cover;
	}
  
	.nav-link {
	  padding: 5px 0 0;
	}
  }
  
  .hor-header {
	.search-element {
	  margin-left: 1rem;
	}
  
	border-bottom: 1px solid #dbe2eb;
  }
  
  
@media (min-width: 992px){
	
	.horizontalMenucontainer .header.top-header {
		box-shadow: none;
	}
	
}



@media (min-width: 991px) {
    .top-header1 .header-brand2 .header-brand-img {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 18px;
    }

    .top-header1 .header-brand {
        display: none;
    }

    .top-header1 .form-inline .form-control {
        width: 250px;
    }
}

@media (max-width: 575px) and (min-width: 569px) {
	.animated-arrow.hor-toggle {
		padding: 16px 35px 16px 0px !important;
	}
}
/*---- IE11 Support ----*/
*::-ms-backdrop, .img-height{
    height:100%;
}
*::-ms-backdrop, .img-height1{
    height:333px;
}
*::-ms-backdrop, .social-profile-buttons li{
    float:none;
    display:inline-block;
}
*::-ms-backdrop, .social-profile-buttons ul{
    margin-bottom:0;
}
*::-ms-backdrop, .btn-icon.btn-svg .svg-icon{
    height:16px;
}
*::-ms-backdrop, .price-svg .price-title{
    top:0 !important;
}
*::-ms-backdrop, .price-svg svg{
    height:65px !important;
}
*::-ms-backdrop, .item7-card-img {
    height:100%;
}
*::-ms-backdrop, .main-content{
    height:100%;
}
*::-ms-backdrop, .app-sidebar-help{
    display:none;
}
*::-ms-backdrop, .list-card .media-body{
    display:block !important;
}
*::-ms-backdrop, .media-body.time-title1{
    display:block !important;
}
*::-ms-backdrop, .media .media-body{
    display:table-cell;
}
*::-ms-backdrop, .chat-widget-header .option-dots new-list2 .fe{
    display:block;
}
*::-ms-backdrop, .message-feed.right .media-body{
    display:table-cell;
}
*::-ms-backdrop, .message-feed.media .media-body{
    display:block;
}
*::-ms-backdrop, .main-chat-body .media .media-body{
    display:-ms-flexbox;
}
*::-ms-backdrop, .main-chat-list .media-body{
    display:block;
}
*::-ms-backdrop, .chat-widget-header .option-dots.new-list2 .fe{
    display:block;
}
*::-ms-backdrop, .card-img-top  {
    height: 250px;
}
*::-ms-backdrop, .image10  {
    height: 250px;
}
*::-ms-backdrop, .card-image1  {
    height: 250px;
}
*::-ms-backdrop, .sweet-alert .sa-info.sa-icon::after  {
    margin-left: 37px !important;
    top: 17px !important;
}
*::-ms-backdrop, .main-contact-info-header .media-body{
    display:block;
}
*::-ms-backdrop, .main-contact-info-body .media-body{
    display:-ms-flexbox;
}
*::-ms-backdrop, #media3 .media-body ,#media1 .media-body{
    display:block;
}

*::-ms-backdrop, .page-style1{
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

*::-ms-backdrop, .page-single, .page-single-content{
    display:block !important;
}
*::-ms-backdrop, .page-style1 .page-single-content{
    display:block !important;
}
*::-ms-backdrop, .page, .page-content{
    display:flex !important;
}
*::-ms-backdrop, .page-style1 .page-single {
    display:block !important;
}
*::-ms-backdrop, .page-content img {
    min-width:50%;
}
*::-ms-backdrop, .chat-conatct-list .option-dots {
    padding:2px 12px !important;
}
.sidenav-toggled .header.py-4 .header-brand .header-brand-img.desktop-lgo{
    display: flex !important;
	margin:0;
}
*::-ms-backdrop,.chat-conatct-list .media {
  overflow: visible; 
  
}

.data-percentage-change {
	vertical-align: middle;
}

.remove-voice {
	display: none;
}

.voice-neural-sign {
	font-size: 10px;
	color: #D13212;
	font-style: normal; 
}

.no-italics {
    font-style: normal;   
}

/* ==========================================================================           
 *
 *    DATA TABLE STYLES
 *
 * ========================================================================== */

.data-table {
	margin-bottom: 1rem!important;
}

.dataTable {

	tbody tr.shown + tr td:first-of-type {
		padding: 0 3rem 0 2rem;
		border-top: none;
	}
}

.dataTable thead tr {
	font-family: 'Poppins', sans-serif;
    font-size: 12px;
    text-transform: none;
    font-weight: 800;
	color: #728096;
}

.dataTable tbody td {
 	font-size: 12px;
 	vertical-align: middle;
 	padding-right:0;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	border-style: dashed!important;
    border-color: rgb(243 243 243/1)!important;

	tbody td button {
		background-color: #1E1E2D;
		color: #FFF;
		border-radius: 0;
		font-size: 11px;
	}

	tbody td button {
		background-color: #1E1E2D;
	}

	tbody td button:hover {
		background-color: #7B83EB;
	}

	tbody td .processing {
		padding: 0.4rem 0.7rem;
		background-color: #F6AD0F;
	}

	tbody td .in_progress {
		padding: 0.4rem 0.7rem;
		background-color: #F6AD0F;
	}

	tbody td .completed {
		padding: 0.4rem 0.7rem;
		color: #FFF;
		background-color: #008c00;
	}

	tbody td .failed {
		padding: 0.4rem 0.7rem;
		color: #FFF;
		background-color: #D13212;
	}

}

.table > :not(:last-child) > :last-child > * {
	border-bottom-color: inherit!important;
}

tbody > tr:last-child > td {
	border-bottom: 0;
  }

.cell-box {
	border-radius: 35px;
	padding: 3px 20px;
    color: #fff;
	font-weight: 700;
}

.user-pending, 
.user-suspended, 
.support-closed, 
.support-pending, 
.payout-processing, 
.priority-low,
.user-group-user,
.plan-closed,
.payment-pending,
.subscription-pending,
.notification-info,
.type-file,
.plan-free { 
	background: #D1D3E0;
	color: $primaryTextColor;
}

.notification-new-user,
.type-both,
.notification-marketing,
.user-group-admin,
.payment-monthly,
.glacier,
.plan-regular,
.support-resolved { 
	background: #E1F0FF;
	color: $primaryTextActiveColor;
}

.glacier-ir { 
	background: rgba(0,188,126,.1);
	color: #00bc7e;
}

.user-active, 
.plan-paid,
.priority-normal,
.support-open, 
.notification-announcement,
.notification-new-payment,
.plan-active,
.payment-completed,
.subscription-active,
.promocode-valid,
.payout-completed, 
.blog-published, 
.status-active,
.faq-visible,
.adsense-activated,
.payment-prepaid,
.user-group-subscriber { 
	background: rgba(0,188,126,.1);
	color: #00bc7e;
}

.support-replied, 
.priority-high,
.payment-yearly,
.support-replied,
.user-group-guest,
.plan-professional,
.deep-archive { 
	background: #FFF4DF;
	color: #FF9D00;
}

.voice-mixed {
	background: #FF9D00;
}

.user-deactivated,  
.priority-critical,
.payment-failed,
.subscription-cancelled,
.subscription-suspended,
.subscription-expired,
.payout-cancelled,
.payout-declined,  
.promocode-invalid,
.payment-cancelled,
.payment-declined,
.blog-hidden,
.notification-payout-request,
.status-deactive,
.faq-hidden,
.adsense-deactivated,
.notification-warning { 
	background: #FFE2E5; 
	color: #ff0000;
}

.table-actions {
	font-size: 14px;
	color: #555;
}

.table-actions-dropdown {
	font-size: 12px;
	min-width: auto;
	padding: 0;
	color: #1e1e2d;

	i {
		font-size: 14px;
		margin-right: 10px;
	}

	.dropdown-item {
		padding: 0.5rem 1rem;

		&:hover, &:focus {
			background: $primaryBGColor;
		}
	}
}

.table#subscriptionsAdminTable th, .table#subscriptionsAdminTable td {
	border-top: none!important;
}

.dataTables_wrapper {
	font-family: 'Poppins', sans-serif;
	font-size: 12px;

	.dataTables_filter {
		text-align: right!important;

		.form-control-sm {
			padding: 0 1rem!important;
		}
	}

	.dataTables_filter label {
		font-weight: 800;
	}

	.dataTables_length label {
		font-weight: 800;
		margin-bottom: 0!important;
		padding-top: 0.3rem!important;

		select {
			margin-bottom: 0!important;
		}
	}

	.dataTables_length select {
		font-size: 10px;
		border-radius: 0;
		border: none;
		border-bottom: 1px solid #ebecf1;
		margin-bottom: 0.5rem;
	}

	.dataTables_filter input {
		border-radius: 0;
		font-size: 12px;
		border: none;
		padding-left: 15px;
		border-bottom: 1px solid #ebecf1;

		&:hover, &:focus {
			border-color: $primaryTextHoverColor;
			box-shadow: none;
			transition: all 0.2s;
		}
	}

	.dataTables_filter .search-icon {
		position: absolute;
		top: 8px;
		margin-left: 5px;
		color: #728096;
	}

	.dataTables_length .custom-select:focus {
		border-color: $primaryTextHoverColor;
		box-shadow: none;
		transition: all 0.2s;
	}

	.dataTables_paginate .pagination .page-item.disabled a {
		color: #728096!important;
	}

	.dataTables_paginate .pagination .page-item.active {
		color: $white;
	}

	.dataTables_paginate .pagination .page-item .page-link {
		font-family: $font-family-secondary;
		font-size: 10px;
		margin-bottom: 0.5rem;
		border-radius: 0;
		color: #555;
		border-color: #ebecf1;
	}

	.dataTables_paginate .pagination .page-item .page-link:hover {
		background: $primaryBGColor;
	}

	.dataTables_paginate .pagination .page-item.active .page-link {
		background-color: $primaryTextActiveColor;
		border-color: $primaryTextActiveColor;
		color: $white;
	}

	.dataTables_paginate .pagination .first .page-link {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.dataTables_paginate .pagination .last .page-link {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}

.dataTables_info {
	padding-left: 12px;
	font-size: 10px;
	color: #728096;
}

.details-table {
	width: 100%;
	margin-bottom: 0;

	td {
		padding: 12px 0 12px 12px!important;
		border-top: 1px solid #ebecf1!important;
	}

	audio {
		width: 100%;
	}

	.details-title, .details-result {
		font-weight: 700;
	}
}

.dataTable div.slider {
    display: none;
}

table.dataTable tbody>tr.selected, table.dataTable tbody>tr>.selected {
	background-color: $body-background;
	color: $primaryTextColor;
}

table.dataTable thead th.dt-checkboxes-select-all, table.dataTable tbody td.dt-checkboxes-cell {
	line-height: 1;
	padding-left: 0;
}

table.dataTable thead th.dt-checkboxes-select-all {
	padding-left: 0.8rem;
}

@media (max-width: 575px) { 

	.dataTable tbody tr.parent {
		display: contents;
	}
}

@media (max-width: 820px) { 

	.dataTable tbody tr.parent {
		display: contents;
	}
}

@media (max-width: 1920px) { 

	.dataTable tbody tr.parent {
		display: contents;
	}
}

#no-results-img {
	width: 200px;
    opacity: 0.7;
    margin-bottom: 0.2rem;
}

.result-play {
	background: none;
	padding: 3px 6px 1px 8px;
    border: 1px solid white;
    border-radius: 50%;
    margin-left: -5px;
	line-height: 1.4;
    color: $primaryTextColor;

	&:hover {
		color: $primaryTextActiveColor;
	}
}

.table-info-button {
	margin-top: 2px;

	i {
		padding-right: 0!important;
	}
	
}

.green {
	color: #00bc7e;
}

.red {
	color:#dc0441;
}

.yellow {	
	color: #FF9D00;
}

.vertical-middle {
	vertical-align: top;
}

.table-action-buttons {
	background: #f5f9fc;
    border: 1px solid white;
    border-radius: 0.42rem;
    line-height: 2.2;
	font-size: 14px;
    color: #67748E;
    width: 33px;
    height: 33px;
    text-align: center;
	transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
	transition-duration: .15s;
}

.view-action-button {
	&:hover, &:focus {
		background: #e1f0ff;
		color: $primaryTextHoverColor;
	}
}

.edit-action-button {
	&:hover, &:focus {
		background: #FFF4DE;
		color: #FFA800;
	}
}

.request-action-button {
	&:hover, &:focus {
		background: rgba(0,188,126,.1);
		color: #00bc7e;
	}
}

.delete-action-button {
	&:hover, &:focus {
		background: #FFE2E5;
		color: #F64E60;
	}
}

.transcribe-action-buttons {
	background: none;
    border: 1px solid white;
    border-radius: 50%;
    line-height: 2;
    box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(150 150 150 / 12%), 0 1px 1px 0 rgb(150 150 150 / 14%);
    color: $primaryTextColor;
    margin-right: 0.2rem;
    width: 26px;
    height: 25px;
    text-align: center;
	padding-right: 1px;
}

.refresh-button {
	position: absolute;
	right: 1.5rem;
	z-index: 100;

	i {
		font-size: 12px;
		border-radius: 0.42rem;
		padding-right: 0;
		padding-top: 2px;
	}
}

.template-buttons {
	z-index: 100;
	i {
		font-size: 12px;
		border-radius: 0.42rem;
		padding-right: 0;
		padding-top: 2px;
	}
}

.special-refresh {
	position: initial!important;
	float: right;
}

.balance-highlight {
	color: $primaryTextActiveColor;
	font-weight: 600;
}

#dashboard-users {
	font-family: 'Poppins', sans-serif;

	tr th {
		vertical-align: middle!important;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		border-color: rgb(243, 243, 243) !important;
	   border-style: dashed;
	}

	tr td {
		vertical-align: middle!important;
		padding-top: 1.55rem;
		padding-bottom: 1.55rem;
		border-color: rgb(243, 243, 243) !important;
	   border-style: dashed;
	}

	tr:hover td {
		background-color: $primaryBGColor!important;
	}
}



/* ==========================================================================           
 *
 *    INPUT FORM BOX STYLES
 *
 * ========================================================================== */

.input-box {
	margin-bottom: 1.5rem;

	h6 {
		font-family: 'Poppins', sans-serif;
		margin-bottom: 0.6rem;
		font-weight: 600;
		font-size: 11px;
	}

	.form-control {
		font-family: 'Poppins', sans-serif;
		font-size: 12px;
		color: $primaryTextColor;
		-webkit-appearance: none;
		 -moz-appearance: none;
		 outline: none;
		 appearance: none;
		 background-color: $body-background;
		 border-color: transparent;
		 border-radius: 0.5rem;
		 border-width: 1px;
		 font-weight: 400;
		 line-height: 1rem;
		 padding: 0.75rem 1rem;
		 width: 100%;
	}

	input {
		&:hover, &:focus {
		border-color: $primaryTextHoverColor;
		box-shadow: none;
		transition: all 0.2s;
		}
	}

	textarea {
		font-weight: 400!important;

		&:hover, &:focus {
			border-color: $primaryTextHoverColor;
			box-shadow: none;
			transition: all 0.2s;		
		}
	}
}

/* ==========================================================================           
 *
 *    AWSELECT DROPDOWN SELECT BOX
 *
 * ========================================================================== */

 .awselect {
	text-align: left;
	margin-bottom: 1.5rem;
	font-size: 12px!important;
	font-weight: 600;

	.bg {
		border-radius: 10px;
	}
}

#tts-project .awselect {
	margin-bottom: 1rem;
}

.awselect .front_face {
	padding: 0.75rem 1rem!important;
}

.awselect .front_face > .bg {
	background: $body-background!important;
	color: $primaryTextColor;
	border-radius: 0.5rem;
	padding: 0.75rem 1rem;
}

.awselect .front_face .current_value {
	font-family: 'Poppins', sans-serif!important;
	font-size: 12px;
	font-weight: 400!important;
}

.awselect .back_face {
	padding: 0px!important;
	float: left;
}

.awselect.animate2 > .bg {
	box-shadow: 0px 4px 7px -2px rgba(0,0,0,.5);
}

.awselect .back_face ul li a {
	padding: 11px 20px!important;
	float: left;
	border-bottom: 1px solid $border;
	font-family: 'Poppins', sans-serif;
}

.awselect .back_face ul li a:hover {
	background: $body-background;
	color: $primaryTextHoverColor;
}

.awselect .awselect-img {
	vertical-align: middle;
    border-style: none;
    width: 20px;
    padding-bottom: 1px;
    margin-right: 1rem;
}

.awselect.placeholder_animate2 .front_face .placeholder {
	color: #728096;
}

.awselect_bg {
	position: relative;
	.animate {
		opacity: 0!important;
	}
}

#checkbox-types {
	display: inline-flex;

	.radio-control {
		display: inline-flex;
	}

	.radio-control {
		display: inline-flex;
	}
	
	.radio-control:hover > .label-control {
		  color: $primaryTextActiveColor;
		  cursor: pointer;
	}
	
	.input-control {
		 appearance: none;
		 display: inline-block;
		 position: relative;
		 background-color: $body-background;
		 color: $primaryTextActiveColor;
		 top: 5px;
		 height: 15px;
		 width: 15px;
		 border: 0;
		 cursor: pointer;     
		 outline: none;
		 transition: all 0.2s;
		 border-radius: 3px;
	}
	
	.input-control:checked::before {
		 position: absolute;
		 font: 9px/1 'Open Sans', sans-serif;
		 left: 5px;
		 top: 2px;
		 content: '\02143';
		 transform: rotate(40deg);
	}
	
	.input-control:checked {
		background-color: $primaryTextActiveColor;
		color: #FFF;
		transition: all 0.2s;
	}
	
	.input-control:checked + .label-control {
		color: $primaryTextActiveColor;
	}
	
	.label-control {
		margin-left: 5px;
		margin-bottom: 0;
		margin-right: 1rem;
		padding-top: 3px;
		font-size: 10px;
		font-weight: 800;
		line-height: 1.8;

		&:hover {
			color: $primaryTextActiveColor;
		}
	}
}

#checkbox-types {
	.label-control {
		padding-top: 2px!important;
		font-size: 12px!important;
		font-weight: 400!important;
	}
}

/* ==========================================================================           
 *
 *    SWAL Modal Box
 *
 * ========================================================================== */ 

 .swal2-title {
	font-size: 20px!important;
	font-weight: 800!important;
	color: $primaryTextColor!important;
	padding-top: 20px!important;
 }

 .swal2-html-container {
	font-size: 14px!important;
	margin-top: 1rem!important;
 }

 .swal2-actions {
	margin: 1em auto 0!important;
 }

 .swal2-file {
	font-size: 14px!important;
	border: 1px solid $primaryTextActiveColor!important;
	margin-top: 1.5rem!important;
    margin-bottom: 0.5rem!important;
 }

 .swal2-input {
	font-family: "Poppins", sans-serif!important;
    border-radius: 0px!important;
    padding: 1.15rem 1.3rem!important;
    font-size: 10px!important;
    color: #1e1e2d!important;
    font-weight: 600!important;
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance: none!important;
    outline: none!important;
	margin-top: 1rem!important;
	margin-left: 2.5rem!important;
	margin-right: 2.5rem!important;

	&:hover, &:focus {
		border-color: $primaryTextHoverColor!important;
		box-shadow: 0px 0px 3px 1px rgba(0,123,255,.4)!important;
		transition: all 0.2s!important;
	}
 }

 .swal2-input-label {
	font-size: 12px;
    margin-top: 1.2rem;
 }

 .swal2-validation-message {
	font-size: 14px!important;
 }

 .swal2-styled.swal2-confirm,
 .swal2-styled.swal2-cancel {
	font-size: 10px!important;
    min-width: 80px!important;
    padding-left: 15px!important;
    padding-right: 15px!important;
    border-radius: 35px!important;
    text-transform: uppercase!important;
    color: #fff !important;
    box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%)!important;

	&:hover, &:focus {
		color: $white !important;
		background-color: $primaryTextColor!important;
		border-color: $primaryTextColor!important;
	}
 }

 .swal2-styled.swal2-confirm {
	background-color: $primaryTextActiveColor!important;
    border-color: $primaryTextActiveColor!important;
 }

 .swal2-styled.swal2-cancel {
	background-color: $btnCancelBGColor!important;
	border-color: $btnCancelBGColor!important;
 }

 .swal2-select {
	 font-size: 13px!important;
	 color: $primaryTextColor!important;
	 margin: 1em 4em 3px!important;
 }


/* ==========================================================================           
 *
 *    PROFILE/EDIT - USERS
 *
 * ========================================================================== */

 .card-body {
	table tbody tr {
		font-size: 12px;
	}
}

.intl-tel-input .flag-dropdown .selected-flag {
	margin-left: 2px;
	border: none;
	background: none;
}

#phone-number {
	padding-left: 55px;
}

.country-users {

	h6 {
		font-size: 12px;
		font-weight: 800;
		margin-bottom: 1rem;
	}

	ul {
		column-count: 3;
		font-size: 12px;

		li {
			margin-bottom: 0.5rem;
		}
	}
}

.special-btn {
	padding: 0.68rem 15px!important;
}

.usage-icon {
	i {
		font-size: 30px;
		padding-top: 1rem;
		color: $primaryTextActiveColor;
	}
}

.progress {
	background-color: #ECF4F7;
	overflow: visible;
	border-radius: 10px!important;

	.zip-bar { 
		box-shadow: 0 3px 7px rgb(0 123 255 / 50%)!important; 
		border-top-left-radius: 10px;
    	border-bottom-left-radius: 10px;
	}
	.zip-bar2 { 
		box-shadow: 0 3px 7px rgb(0 123 255 / 50%)!important; 
	}
	.documents-bar { box-shadow: 0 3px 10px rgb(255 157 0 / 50%)!important; }
	.subscription-bar { 
		box-shadow: 0 3px 7px rgb(255 157 0 / 50%)!important; 
		border-top-left-radius: 10px;
    	border-bottom-left-radius: 10px;
	}
	.media-bar { box-shadow: 0 3px 7px rgb(239 75 75 / 50%)!important; }
	.images-bar { box-shadow: 0 3px 7px rgb(10 187 135 / 50%)!important; }
}

.label {
	align-items: center;
    display: flex;

	.label-dot {
		border-radius: 8px;
		display: block;
		flex: none;
		height: 8px;
		margin-right: 10px;
		width: 8px;
	}

	.label-title {
		font-family: 'Poppins', sans-serif;
		font-size: 12px;
    	font-weight: 700;
	}

	.zip { background: $primaryTextActiveColor; }
	.documents { background: #FF9D00; }
	.media { background: #ef4b4b; }
	.images  { background: #0abb87; }
	.other { background: #f5f9fc; }
}

.overflow-x-auto {
    overflow-x: auto;
}

#dashboard-background {
	background: rgb(2,0,36);
	background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(15,24,43,1) 0%, rgba(51,65,85,1) 100%);
}

.yellow {
	color: #FF9D00;
}

.file-placeholder-container {
	position: relative;
}

.file-placeholder {
	color: rgb(236 244 247/1);
	margin-right: 1rem;
}

.file-placeholder-text {
	position: absolute;
	font-size: 7px;
	font-weight: 600;
	text-align: center;
	text-overflow: ellipsis;
	color: $primaryTextActiveColor;
	top: 36%;
    left: 8px;
}

#return-sound {
    position: absolute;
    right: 0;
    margin-right: 1.2rem;
    top: 17px;
}

/* ==========================================================================           
 *
 *    SUPPORT/CREATE - USERS
 *
 * ========================================================================== */

.ql-toolbar.ql-snow {
	border: 1px solid #ced4da;
	font-family: $font-family-secondary;
	background: $white;
}

.ql-container.ql-snow {
	border: 1px solid #ced4da;
	font-family: $font-family-secondary;
}

#support-messages-box {

	min-height: 600px;
	border-radius: 8px;
	height: 100%;

	.support-message {
		padding: 1rem;
		float:left;
		width: 90%;
		border-radius: 8px;
		box-shadow: 0 1px 8px rgb(179 186 212 / 70%) !important;

		p {
			span {
				float: right;
				color: #FF9D00;
			}
		}
	}

	.support-response {
		float: right;
		background-color: #E1F0FF!important;
	}
}

.support-header {
	position: absolute;
	right: 0;
	top: 2rem;
	margin-right: 1.2rem;
}

/* ==========================================================================           
 *
 *    RESULT/SHOW - USERS/ADMINS
 *
 * ========================================================================== */

 #user-text {
	border: 1px solid #ced4da;
	padding: 10px 20px;
	font-size: 14px;
 }

 .actions-total button.btn {
	padding: 0.3rem 0.4rem;
	background-color: #FFF;
	width: 35px;
	border: none;
	outline: none;
	text-decoration: none;
	border-radius: 0;
	color: #1e1e2d;
	margin-right: 0.1rem;
	transition: all 0.2s ease;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.actions-total button:hover {
	background-color: #007BFF;
	color: #FFF;
	transition: all 0.2s ease;
}

.actions-total a.btn {
	padding: 0.3rem 0.4rem;
	background-color: #FFF;
	width: 35px;
	border: none;
	outline: none;
	text-decoration: none;
	border-radius: 0;
	color: #1e1e2d;
	margin-right: 0.1rem;
	transition: all 0.2s ease;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.actions-total a:hover {
	background-color: #007BFF;
	color: #FFF;
	transition: all 0.2s ease;
}

/* ==========================================================================           
 *
 *    FLASH ALERT MESSAGES
 *
 * ========================================================================== */

 .alert {
	z-index: 100;
	position: absolute;
    right: 2rem;
    margin-top: 2rem;
    font-size: 12px;
    line-height: 2.2;
	color: $white;
	font-weight: 600;
	border: none!important;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);

	i {
		font-size: 16px;
    	vertical-align: middle;
    	margin-right: 10px;
    	margin-bottom: 3px;
	}
 }

 .alert-login {
	 right: auto;
	 width: 66.7%!important;
	 top: 10px!important;
 }

.alert-success {		
	background: #00c851!important;
}

.alert-info {
	background: #33b5e5!important;
}

.alert-warning {
	background: #fb3!important;
}

.alert-danger {
	background: #ff3547!important;
}

.close {
	line-height: 1.3;
	opacity: 0.8;
	color: $white;
	padding: 0 0.5rem 0 0;
}

#verify-email {
	.alert {
		font-size: 10px;
		line-height: 3;
	}
}

/* ==========================================================================           
 *
 *    MODAL STYLES
 *
 * ========================================================================== */

.modal-header {
	padding: 1rem 2rem;
	border-bottom: none;

	.close {
		color: $primaryTextColor;
	}
}

.modal-title {
   	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 800;
	text-transform: uppercase;

	i {
		font-weight: 600;
		margin-right: 0.5rem;
		color: $primaryTextActiveColor;
	}

	button {
		outline: none;
		color: $primaryTextColor;
	 }
}

.modal-body {
	padding-top: 5px;
	padding-bottom: 1.5rem;

	p {
		font-size: 12px;
		margin-bottom: 0;
	 }
}

.modal-footer {
	padding: 0 10px 0 0;
   	border-top: none;
}

.modal-footer-awselect {
	display: inline-flex!important;
	float: right!important;
}


/* ==========================================================================           
 *
 *    TTS STYLES
 *
 * ========================================================================== */

.special-buttons {
	.btn-special {
		background: $white;
		box-shadow: $special-shadow;
		font-size: 12px;
		border-radius: 0;
		margin-right: 1rem;
		font-weight: 600;
		width: 100%;
		height: 38px;

		&:hover, &:focus {
			background-color: $primaryTextActiveColor;
			color: $white;
		}
	}

	.dropdown-toggle::after {
		vertical-align: middle;
	}

	.dropdown-menu {
		border-radius: 0;
		font-size: 12px;
		box-shadow: $card-shadow;	
		border: none;	
		margin-top: 3px;
		width: 100%;	
	}

	.dropdown-item {
		padding: 0.5rem 1.5rem;	

		&:hover, &:focus {
			background-color: $body-background;
		}
	}
}


/* ==========================================================================           
 *
 *    DATABASE BACKUP TABLE
 *
 * ========================================================================== */

 #database-backup {
	 font-family: 'Poppins', sans-serif;

	 tr th, tr td {
		 vertical-align: middle!important;
		 padding-top: 0.75rem;
		 padding-bottom: 0.75rem;
		 border-color: rgb(243, 243, 243) !important;
    	border-style: dashed;
	 }

	//  tr:hover td {
	// 	 background-color: $primaryBGColor!important;
	//  }
 }


 input {
	outline: none;
}

input[type=search] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
	display: none; 
}

/* ==========================================================================           
 *
 *    SEARCH FIELD IN NAVBAR
 *
 * ========================================================================== */

#search-field input[type=search] {	
	background: #fff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
	border: solid 1px #fff;
	padding: 5px 10px;
	font-size: 12px;
	border-radius: 10em;
	transition: all .5s;
	width: 15px;
	padding-left: 10px;
	color: $primaryTextColor;
	cursor: pointer;
	margin-top: 6px;
	font-weight: 600;
}

#search-field input[type=search]:focus {
	border-color: $primaryTextActiveColor;
	box-shadow: 0 0 5px rgba(109,207,246,.5);
	width: 300px;
	padding-left: 32px;
	color: #000;
	background-color: transparent;
	cursor: auto;
}

#search-field input[type=search]:hover {
	background-color: transparent;
}

#search-field input:-moz-placeholder {
	color: #fff;
}
#search-field input::-webkit-input-placeholder {
	color: #fff;
}

/* ==========================================================================           
 *
 *    CHECKOUT
 *
 * ========================================================================== */

.checkout-wrapper-box {
	border: 1px solid $border;
	padding: 1.5rem 2rem;
	border-radius: 5px;
	margin-top: 5px;
	margin-bottom: 1rem;

	label.gateway {
		margin-bottom: 1rem;
		width: 150px;
		height: 100px;
		line-height: 90px;
		border: 1px solid #ebecf1;

		&:hover, &:focus {
			border: 1px solid $primaryTextActiveColor;
		}

		.active, .focus {
			border: 1px solid $primaryTextActiveColor!important;
		}
	}

	label.btn:focus, label.btn.focus, label.btn.active {
		border: 1px solid $primaryTextActiveColor!important;
	}

	.btn-group {
		margin-bottom: 1.4rem;
	}

	label[aria-expanded="true"]{
		border: 1px solid $primaryTextActiveColor!important;
	  }

	

	#BankTransferCollapse {
		border: 1px solid #ebecf1;
		padding: 1rem;
		margin-bottom: 1.5rem;

		p {
			line-height: 1.2rem;
			text-align: justify;
		}

		pre {
			text-align: left;
			border-radius: 0;
			padding: 1rem;
			background-color: $primaryBGColor;
			border-left: 3px solid $primary;
			
		}
	}
}

.checkout-title {
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	font-size: 12px;
	line-height: 1;
	margin-bottom: 0;
}

.checkout-cost {
	float: right;
	font-weight: 800;
	font-size: 12px;
	font-family: 'Poppins', sans-serif;
}

.checkout-success {
	font-size: 20px;
	font-weight: 700;
	color: $primaryTextActiveColor;
}

.gateway-radio {
	display: none;
	bottom: 0;
	right: 0;
}

.gateway-logo {
	width: 5%;
	margin-right: 0.5rem;
}

.gateway-small-logo {
	width: 3%!important;
}

.transaction-gateway-logo {
	width: 50%;
}

.payment-image {
	width: 70%;
	padding-top: 9px;
}

.payment-image-braintree {
	width: 70%;
	padding-top: 9px;
}

.payment-image-mollie {
	width: 40%;
	padding-top: 6px;
}

.payment-image-coinbase {
	width: 60%;
	padding-top: 8px;
}

.payment-image-stripe {
	padding-top: 6px;
}

.p-family {
	font-family: 'Poppins', sans-serif;
}

#promocode-apply {
	padding: 0.67rem 15px;
}

.tabs-menu ul {
	padding: 5px 7px;
	background: #f5f9fc;
	border-radius: 1.5rem;
  }

  .tabs-menu ul li a {
	width: auto;
	text-transform: none;
	border-radius: 1rem;
  }


/* ==========================================================================           
 *
 *    INSTALLATION STYLES
 *
 * ========================================================================== */

 #install-wrapper {
	 i {
		 padding: 1.5rem 2.5rem;
		 background: $body-background;
		 border-radius: 10px;
		 margin-bottom: 2rem;
		 color: $primaryTextActiveColor;
		 font-size: 68px;
	 }

	 .svg-success {
		 color: #28a745!important;
		 width: 1rem;
		 height: 1rem;
		 fill: currentColor;
	 }

	 .svg-error {
		color: #D13212!important;
		width: 1rem;
		height: 1rem;
		fill: currentColor;
	 }
 }

 .install-path {
	 span {
		padding: 0.3rem 0.4rem;
		background-color: #FFF;
		width: 35px;
		border: none;
		outline: none;
		text-decoration: none;
		border-radius: 5px;
		color: #1e1e2d;
		margin-right: 0.1rem;
		transition: all 0.2s ease;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	 }

	 span.active {
		background-color: $primaryTextActiveColor!important;
		color: #FFF;
	 }
 }

 .install-notification,
 #manual-activation {
	 .alert {
		 position: relative;
		 margin-top: 0;
		 margin-bottom: 2rem;
		 line-height: normal;
		 width: 100%!important;
	 }
 }

 #install-footer {
	position: absolute;
    bottom: 0;
    left: 42%
 }

 #upgrade {
	 min-width: 154px;
 }

 #installed-info {
	display: none;
 }


/* ==========================================================================           
 *
 *    REFERRALS 
 *
 * ========================================================================== */
 
 .referral-icon {
	vertical-align: middle;
	color:#ffab00;
	text-shadow: 0 1px 2px rgb(50 50 50 / 20%);
}

.referral-icon-user i {
	font-size: 20px;
	color: $primaryTextActiveColor;
    line-height: 59px;
    text-align: center;
    vertical-align: middle;
    width: 60px;
    border-radius: 50%;
	background-color: #f5f9fc;
	margin-bottom: 0.5rem;
}

.referral-social-icons {
	.actions-total a.btn {
		padding: 0.45rem 0.4rem;
		width: 37px;
		margin-right: 0.3rem;
	}
}

.form-control:disabled, .form-control[readonly] {
	color: $primaryTextActiveColor;
	font-weight: 400;
}

.input-group-btn {
	position: absolute;
    right: 0;
    top: 1px;
}

.file-data {
	.input-group-btn {
		.btn-primary {
			padding: 0.6rem 5px!important;
			min-width: 50px!important;
		}
	}
}

#invite-friends-button {
	padding: 0.75rem 15px !important;
	border-radius: 0!important;
}

#left-direction {
	position: absolute;
    top: 27px;
    left: 65%;
}

#right-direction {
	position: absolute;
    top: 15px;
    left: 65%;
}

.referral-box {
	padding: 2rem;
	margin-right: 1rem;
	border: 1px solid #FFF;

	&:hover, &:focus {
		border: 1px solid #ffab00;
		color: $primaryTextActiveColor;

		i {
			color: #ffab00;
		}
	}

	i {
		font-size: 34px;
		line-height: 1rem;
	}

	a {
		font-family: 'Poppins', sans-serif;
	}
}

.poppins {
	font-family: 'Poppins', sans-serif!important;
}

.referral-guideline {
	line-height: 2;
	border-radius: 0;
	font-family: 'Poppins', sans-serif;
}

#option-bank {
	line-height: 1;

	label {
		line-height: 1.5;
	}
}


/* ==========================================================================           
 *
 *    FILE UPLOADS CONTAINER (FILEPOND PLUGIN)
 *
 * ========================================================================== */

#upload-container {
	display: none;
	overflow: hidden;
}

#upload-results {
	display: none;
	overflow: hidden;
}

.filepond--root {
	color: $primaryTextColor;
	border-radius: 0.4rem;
	margin-bottom: 0;
}

.filepond--drop-label.filepond--drop-label label {
	font-weight: 800;

	.restrictions {
		font-size: 10px;
		font-weight: 600;
	}

	.restrictions-highlight {
		color: #007bff;
	}
}

.filepond--drop-label.filepond--drop-label label:hover {
	cursor: pointer;
}

.filepond--drop-label {
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	color: $primaryTextColor;
	height: 90px;
}

.filepond--root .filepond--drop-label {
	min-height: 0;
}

.filepond--browser.filepond--browser {
	bottom: 1.75em;
}

.filepond--file-info {
	font-weight: 400;
}

.filepond--item-panel {
	background: $primaryTextColor;
}

.filepond--panel-root {
	background-color: #FFF;	
}

.filepond--label-action {
	text-decoration: none;
    color: $primaryTextActiveColor;
}

.filepond--label-action:hover {
	color: #0e2e40;
}

.filepond--file-status {
	flex-shrink: 1;

	.filepond--file-status-sub {
		display: none;
	}
}

[data-filepond-item-state*='invalid'] .filepond--item-panel, [data-filepond-item-state*='error'] .filepond--item-panel {
	background-color: #c44e47!important;
}

#multipartupload {
	.h6 {
		text-transform: uppercase;
		font-weight: 10px!important;
		font-weight: 600;
	}
}

.email-box,
.expiration-time-box,
.password-box {
	display: none;
}

#datetime {
	font-family: "Poppins", sans-serif;
    font-size: 12px;
    color: #1e1e2d;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    appearance: none;
    background-color: #f5f9fc;
    border-color: transparent;
    border-radius: 0.5rem;
    border-width: 1px;
    font-weight: 700;
    line-height: 1rem;
    padding: 0.75rem 1rem;
    width: 100%;
	margin-bottom: 1.5rem;
}

.processing-image {
	display: none;
}

/* -------------------------------------------- */
/* 		Radio Button Checkboxes
/* -------------------------------------------- */

#storage-type-radio {
	display: inline-flex;

	.radio-control {
		display: inline-flex;
	}

	.radio-control {
		display: inline-flex;
	}
	
	.radio-control:hover > .label-control {
		  color: $primaryTextActiveColor;
		  cursor: pointer;
	}
	
	.input-control {
		 appearance: none;
		 display: inline-block;
		 position: relative;
		 background-color: $body-background;
		 color: $primaryTextActiveColor;
		 top: 5px;
		 height: 15px;
		 width: 15px;
		 border: 0;
		 cursor: pointer;     
		 outline: none;
		 transition: all 0.2s;
		 border-radius: 3px;
	}
	
	.input-control:checked::before {
		 position: absolute;
		 font: 9px/1 'Open Sans', sans-serif;
		 left: 5px;
		 top: 2px;
		 content: '\02143';
		 transform: rotate(40deg);
	}
	
	.input-control:checked {
		background-color: $primaryTextActiveColor;
		color: #FFF;
		transition: all 0.2s;
	}
	
	.input-control:checked + .label-control {
		color: $primaryTextActiveColor;
	}
	
	.label-control {
		margin-left: 5px;
		margin-bottom: 0;
		margin-right: 1rem;
		padding-top: 3px;
		font-size: 10px;
		font-weight: 800;
		line-height: 1.8;

		&:hover {
			color: $primaryTextActiveColor;
		}
	}
}

/* -------------------------------------------- */
/* 		Upload Progress Bar
/* -------------------------------------------- */

#upload-status {
	.file-info {
		font-family: 'Poppins', sans-serif;
		font-size: 12px;

		span {
			font-weight: 700;
		}

		.file-meta {
			//font-weight: 700;
		}
	}

	.status {
		font-family: 'Poppins', sans-serif;
		font-size: 12px;
		font-weight: 700;
	}

	.progress {

		.upload-bar { 
			box-shadow: 0 3px 7px rgb(0 123 255 / 50%)!important; 
			border-radius: 10px!important;
		}

		.progress-statistics {
			position: absolute;
			left: 50%;
			font-weight: 800;
			color: white;
			font-family: 'Poppins', sans-serif;
		}
	}
}

/* -------------------------------------------- */
/* 		Frontend FilePond
/* -------------------------------------------- */
#settings {
	position: absolute;
	z-index: 100;
	right: 1rem;
	cursor: pointer;

	&:hover, &:focus {
		color: $primaryTextActiveColor;
	}
}



/* ==========================================================================           
 *
 *    PRELOADER
 *
 * ========================================================================== */
 #preloader {
	background-color: rgba(255,255,255,.70);
    height: 100%;
    width: 100%;
    position: fixed;
    margin-top: 0;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden!important;
    right: 0;
    z-index: 999999;

	img {
		text-align: center;
		left: 0;
		position: absolute;
		right: 0;
		top: 40%;
		z-index: 99;
		margin: 0 auto;
	}
}


/* ==========================================================================           
 *
 *    PRELOADER
 *
 * ========================================================================== */

#toast-container {
	margin-top: 4.2rem;

	.toast {

		width: 100%;
		font-size: 12px;

		.toast-title {
			display: none;
		}

		.toast-info { 
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important; 
		  } 
		 .toast-error { 
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important; 
		  } 
		  .toast-success { 
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important; 
		  } 
		  .toast-warning { 
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important; 
		  } 
	}
} 



/* ==========================================================================           
 *
 *    AI DASHBOARD
 *
 * ========================================================================== */

.text-shadow {
	text-shadow: 1px 1px 2px rgb(0 0 0 / 50%);
}

.favorite-templates-panel {
	min-height: 200px;
}

.internal-special-links {

	&:hover, &:focus {
		color: #1e1e2d!important;
		font-weight: 600;
	}
}

.template {

	.card-body {
		min-height: 155px;
	}
	
	margin-top: -1.5rem;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover, &:focus {
		transform: scale(1.05);
		z-index: 100;
	}

	.favorite {
		border: 2px solid #FF9D00;
		background-color: #FEFCE8;
	}

	.professional {
		border: 2px solid #AB54EB;
		background-color: #F6E9FF;
	}

	.template-icon {

		i.main-icon {
			font-size: 16px;
			padding: 7px;
			background: #FFF4DF;
			color: #FF9D00;
			border-radius: 5px;
		}

		i.blog-icon {
			font-size: 16px;
			padding: 7px;
			background: #E1F0FF;
			color: #007bff;
			border-radius: 5px;
		}

		i.web-icon {
			font-size: 16px;
			padding: 7px;
			background: rgba(0,188,126,.1);
			color: #00bc7e;
			border-radius: 5px;
		}

		i.social-icon {
			font-size: 16px;
			padding: 7px;
			background: rgb(235, 232, 255);
			color: rgb(81, 56, 238);
			border-radius: 5px;
		}
	
		i.other-icon {
			font-size: 16px;
			padding: 7px;
			background: #D1D3E0;
			color: $primaryTextColor;
			border-radius: 5px;
		}
		
		i.star {
			font-size: 16px;
			padding-top: 7px;
			color: #FF9D00;
		}

		i.fa-stars {
			font-size: 18px;
		}
	}

	a {
		position: relative;
		right: 27px;
		top: 50px;
		float: right;
		z-index: 200;

		i.star {
			font-size: 16px;
			padding-top: 7px;
			color: #FF9D00;
		}

		i.fa-stars {
			font-size: 18px;
		}
	}

	.btn-yellow {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: #FF9D00;
		border-color: #FF9D00;
		box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
		position: absolute;
		right: 10px;
		bottom: 10px;
	}
}

.template-edit {
	i.main-icon {
		font-size: 16px;
		padding: 7px;
		background: #FFF4DF;
		color: #FF9D00;
		border-radius: 5px;
	}

	i.blog-icon {
		font-size: 16px;
		padding: 7px;
		background: #E1F0FF;
		color: #007bff;
		border-radius: 5px;
	}

	i.web-icon {
		font-size: 16px;
		padding: 7px;
		background: rgba(0,188,126,.1);
		color: #00bc7e;
		border-radius: 5px;
	}

	i.social-icon {
		font-size: 16px;
		padding: 7px;
		background: rgb(235, 232, 255);
		color: rgb(81, 56, 238);
		border-radius: 5px;
	}

	i.other-icon {
		font-size: 16px;
		padding: 7px;
		background: #D1D3E0;
		color: $primaryTextColor;
		border-radius: 5px;
	}
}


/* ==========================================================================           
 *
 *    TEMPLATES
 *
 * ========================================================================== */

 .template-view {

	.favorite {
		border: 2px solid #FF9D00;
		background-color: #FEFCE8;
	}

	.template-icon {

		i.main-icon {
			font-size: 12px;
			padding: 7px;
			background: #FFF4DF;
			color: #FF9D00;
			border-radius: 5px;
		}

		i.green-icon {
			font-size: 12px;
			padding: 7px;
			background: rgba(0,188,126,.1);
			color: #00bc7e;
			border-radius: 5px;
		}

		i.web-icon {
			font-size: 16px;
			padding: 7px;
			background: rgba(0,188,126,.1);
			color: #00bc7e;
			border-radius: 5px;
		}

		i.blog-icon {
			font-size: 16px;
			padding: 7px;
			background: #E1F0FF;
			color: #007bff;
			border-radius: 5px;
		}

		i.social-icon {
			font-size: 16px;
			padding: 7px;
			background: rgb(235, 232, 255);
			color: rgb(81, 56, 238);
			border-radius: 5px;
		}
	
		i.other-icon {
			font-size: 16px;
			padding: 7px;
			background: #D1D3E0;
			color: $primaryTextColor;
			border-radius: 5px;
		}
		
		i.star {
			font-size: 14px;
			padding: 7px;
			color: #FF9D00;
		}

		i.fa-stars {
			padding-top: 3px!important;
			font-size: 16px;
		}

		a {
			position: absolute;
			right: 17px;
			cursor: pointer;
		}
	}

	.btn-yellow {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: #FF9D00;
		border-color: #FF9D00;
		box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
	}
}

.awselect-icon-style {
	width: auto!important;
	i.main-icon {
		font-size: 16px;
		padding: 7px;
		background: #FFF4DF;
		color: #FF9D00;
		border-radius: 5px;
	}

	i.blog-icon {
		font-size: 16px;
		padding: 7px;
		background: #E1F0FF;
		color: #007bff;
		border-radius: 5px;
	}

	i.web-icon {
		font-size: 16px;
		padding: 7px;
		background: rgba(0,188,126,.1);
		color: #00bc7e;
		border-radius: 5px;
	}

	i.social-icon {
		font-size: 16px;
		padding: 7px;
		background: rgb(235, 232, 255);
		color: rgb(81, 56, 238);
		border-radius: 5px;
	}

	i.other-icon {
		font-size: 16px;
		padding: 7px;
		background: #D1D3E0;
		color: $primaryTextColor;
		border-radius: 5px;
	}
}

#template-output {
	min-height: 700px;
}

#awselect_project {
	margin-bottom: 0;
}

#template-textarea {

	.richText {
		border: none;

		.richText-editor {
			border: none!important;
			padding: 20px 10px;
			height: 500px;
			font-size: 14px;
			font-family: 'Lato', sans-serif;
		}

		.richText-toolbar {
			border: none;
			background-color: #FFF;

			.richText-btn,
			.richText-undo,
			.richText-redo {
				border: none!important;
				font-size: 14px;
				padding: 10px;

				&:hover, &:focus {
					color: $primaryTextActiveColor;
				}

			}
			.richText-help {
				display: none;
			}
		}
	}
}

#template-input {
	.input-box .form-control {
		font-weight: normal;
	}

	.awselect .front_face .placeholder, 
	.awselect .front_face .current_value {
		font-weight: normal!important;
	}
}

.create-project {
	box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
    border-radius: 0.5rem;
    background-color: #007BFF;
    color: #FFF;

	&:hover {
		cursor:pointer;
		background-color: $primaryTextColor;
		color: #FFF;
	}
}

.jQTAreaExt {
	margin-bottom: 0;
	height: 2px;
	background-color: $primaryTextActiveColor;
  }

.jQTAreaCount,
.jQTAreaValue {
  font-style: normal;
}

.character-counter {
	font-style: normal;
	font-size: 10px;
	color: #728096;
	float: right;
}

.form-text {
	position: absolute;
    right: 1rem;
    top: 0;
    font-size: 10px;
	margin-top: 0;
}
	
.avoid-clicks {
	pointer-events: none;
	cursor: not-allowed!important;
	opacity: 0.4!important;
}

.side-word-notification {
    padding: 5px 20px;
}

#balance-status {
	background: #F5F9FC;
    padding: 8px;
    border-radius: 0.5rem;
}

/* ==========================================================================           
 *
 *    TEMPLATES
 *
 * ========================================================================== */

 .template-view {

	.template-icon {

		i.email-icon {
			font-size: 12px;
			padding: 7px;
			background: #F8D7DA;
			color: #B02A37;
			border-radius: 5px;
		}

		i.video-icon {
			font-size: 12px;
			padding: 7px;
			background: #CFF4FC;
			color: #087990;
			border-radius: 5px;
		}
		
	}
}

.template {

	.template-icon {

		i.email-icon {
			font-size: 12px;
			padding: 7px;
			background: #F8D7DA;
			color: #B02A37;
			border-radius: 5px;
		}

		i.video-icon {
			font-size: 12px;
			padding: 7px;
			background: #CFF4FC;
			color: #087990;
			border-radius: 5px;
		}
		
	}
}

.awselect-icon-style {
	
	i.email-icon {
		font-size: 16px;
		padding: 7px;
		background: #F8D7DA;
		color: #B02A37;
		border-radius: 5px;
	}

	i.video-icon {
		font-size: 12px;
		padding: 7px;
		background: #CFF4FC;
		color: #087990;
		border-radius: 5px;
	}
}

.mt-24 {
  margin-top: 2.4rem!important;
}

#awselect_template-selection {
  margin-top: 0.25rem !important;
	margin-bottom: 0!important;
	box-shadow: none;
  width: 250px;

	.front_face .placeholder {
		font-weight: 400!important;
		text-align: center;
	}
}

#awselect_template-selection .front_face > .bg {
	background-color: #FFF!important;
}

.table-action-buttons-big {
  line-height: 2.4!important;
	font-size: 16px!important;
  width: 40px!important;
  height: 40px!important;
}

#template-buttons-group {
	#save-button i {
		background: $primaryTextActiveColor!important;
		color: #FFF!important;
	
		&:hover, &:focus {
			background: $primaryTextColor!important;
		}
	}
}

i.main-icon {
	font-size: 16px;
	padding: 7px;
	background: #FFF4DF;
	color: #FF9D00;
	border-radius: 5px;
}

i.blog-icon {
	font-size: 16px;
	padding: 7px;
	background: #E1F0FF;
	color: #007bff;
	border-radius: 5px;
}

i.web-icon {
	font-size: 16px;
	padding: 7px;
	background: rgba(0,188,126,.1);
	color: #00bc7e;
	border-radius: 5px;
}

i.social-icon {
	font-size: 16px;
	padding: 7px;
	background: rgb(235, 232, 255);
	color: rgb(81, 56, 238);
	border-radius: 5px;
}

i.other-icon {
	font-size: 16px;
	padding: 7px;
	background: #D1D3E0;
	color: $primaryTextColor;
	border-radius: 5px;
}

i.email-icon {
	font-size: 12px;
	padding: 7px;
	background: #F8D7DA;
	color: #B02A37;
	border-radius: 5px;
}

i.video-icon {
	font-size: 12px;
	padding: 7px;
	background: #CFF4FC;
	color: #087990;
	border-radius: 5px;
}

.category-blog {
	background: #E1F0FF;
	color: #007bff;
}

.category-video {
	background: #CFF4FC;
	color: #087990;
}

.category-email {
	background: #F8D7DA;
	color: #B02A37;
}

.category-other {
	background: #D1D3E0;
	color: $primaryTextColor;
}

.category-social {
	background: rgb(235, 232, 255);
	color: rgb(81, 56, 238);
}

.category-web {
	background: rgba(0,188,126,.1);
	color: #00bc7e;
}

.category-main {
	background: #FFF4DF;
	color: #FF9D00;
}

.favorite-templates-panel {
	.nav-tabs {
		border-bottom: none;

		.nav-link {
			font-size: 12px;
			margin-right: 0.7rem;
			min-width: 80px;
			padding-left: 15px;
			padding-right: 15px;
			border-radius: 35px;
			color: $primaryTextColor !important;
			background-color: #FFF;
			border: none;
			box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
		
			&:hover {
				color: $white !important;
				background-color: $primaryTextColor;
				border-color: $primaryTextColor;
			}
		
			&:focus, &.focus {
				background-color: $btnMainBGColor;
				border-color: $btnMainBGColor;
			}
		}

		.nav-link.active {
			color: #FFF !important;
			background-color: $primaryTextColor;
		}
	}
}

/* ==========================================================================           
 *
 *    CUSTOM TEMPLATES
 *
 * ========================================================================== */

.field {
	input, select {
		font-family: "Poppins", sans-serif;
		font-size: 12px;
		color: #1e1e2d;
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: none;
		appearance: none;
		background-color: #f5f9fc;
		border-color: transparent;
		border-radius: 0.5rem;
		border-width: 1px;
		font-weight: 400;
		line-height: 1rem;
		padding: 0.75rem 1rem;
	}

	input {
		margin-right: 1rem;
	}

	input, select {
		&:hover, &:focus {
			border-color: $primaryTextHoverColor;
			box-shadow: none;
			transition: all 0.2s;
		}
	}

	span {
		border-top-right-radius: 35px!important;
    	border-bottom-right-radius: 35px!important;
		
		i {
			line-height: 2.6;
		}
	}

	span:last-child {
		display: none;
		padding: 12px;
	}
}


/* ==========================================================================           
 *
 *    DOCUMENTS
 *
 * ========================================================================== */

 .category-text {
	background: #FFF4DF;
	color: #FF9D00;
}

#createButton {
	position: absolute;
	right: 1rem;
}


/* ==========================================================================           
 *
 *    VOICEOVER
 *
 * ========================================================================== */

 .special-buttons {
	.btn-special {
		background: #f5f9fc;
    	border-radius: 0.5rem;
		font-size: 12px;
		margin-right: 1rem;
		font-weight: 600;
		width: 100%;
		height: 38px;
		box-shadow: none;

		&:hover, &:focus {
			background: $primaryTextActiveColor;
			color: $white;
		}
	}

	.dropdown-toggle::after {
		vertical-align: middle;
	}

	.dropdown-menu {
		border-radius: 0;
		font-size: 12px;
		box-shadow: $card-shadow;	
		border: none;	
		margin-top: 3px;
		width: 100%;	
	}

	.dropdown-item {
		padding: 0.5rem 1.5rem;	

		&:hover, &:focus {
			background-color: $body-background;
		}
	}
}

#textarea-outer-box {
	border: 1px solid #ebecf1;
	position: relative;

	.input-label {
		position: absolute;
		top: 0;
		padding-left: 5px;
		font-size: 14px;
		color: #728096;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.input-label::after {
		content: '';
		position: absolute;
	}

	.input-label-content {
		position: relative;
		padding: 0 5px;
		color: #728096;
		background-color:#FFF;
		opacity: 1;
		display: inline-block;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		top: -7px;
		left: 15px;
		font-size: 10px;
	}

	.input-label-main {
		top: -13px;
	}

	#textarea-container {
		min-height: 300px;
		background: #f5f9fc;
    	height: auto;
		padding-bottom: 0.7rem;

		.textarea-row {
			display: flex;
			padding: 10px 15px;

			.textarea-voice {
				padding-left: 1rem;
				
				div {
					box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
					height: 30px;
					width: 30px;
					clear: both;
					border-radius: 100%;
					position: relative;
	
					img {
						width: 100%;
						height: 100%;
						border-radius: 50%;
						object-fit: cover;
						box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
					}
				}				
			}

			.textarea-text {
				width: 100%;
				margin: 0 2rem;

				textarea {
					min-height: 37px;
				}

				.form-control {
					font-size: 12px;
					font-family: "Poppins", sans-serif;
					border-radius: 0px;
					padding: 0.6rem 1rem;
					color: $primaryTextColor;
					appearance: none;
    				outline: none;
					box-shadow: none;
					border: 0 solid transparent;

					&:hover, &:focus {
						background: #fff;
						border: 0;
						box-shadow: 0 3px 10px rgba(0, 0, 0, .1);
						-webkit-transition: all .5s ease-out 0s;
						-moz-transition: all .5s ease-out 0s;
						-ms-transition: all .5s ease-out 0s;
						-o-transition: all .5s ease-out 0s;
						transition: all .5s ease-out 0s;
						border-color: transparent
					 }
				}
			}

			.textarea-actions {
				.textarea-buttons {
					display: flex;
					margin-right: 0.5rem;

					.buttons {
						border: none;
						box-shadow: none;
						margin-right: 0.5rem;
						transition: all 0.1s ease;
						text-shadow: 0px 1px 2px rgb(50 50 50 / 20%);
					}

					.synthesizeText {						
						&:hover, &:focus {
							background-color: $primaryTextActiveColor;
							color: white;
							box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
						}
					}

					.addPause {
						&:hover, &:focus {
							background-color: #FF9D00;
							color: white;
							box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
						}
					}

					.deleteText {
						&:hover, &:focus {
							background-color: red;
							color: white;
							box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
						}
					}
				}
			}
		}

		#addTextRow {		
			transition: all 0.05s ease-in-out;
			text-shadow: 0px 1px 3px rgb(50 50 50 / 20%);

			&:hover, &:focus {
				background: $primaryTextActiveColor;
				color: #FFF;
				border-radius: 50%;
				padding-bottom: 8px;
				box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
			}				
		}
	}

}

#upload-file {
	button {
		background-color: $primaryTextActiveColor;
		color: #FFF;

		&:hover, &:focus {
			background-color: $primaryTextColor;
		}
	}
}

#textarea-box .form-control {
	font-size: 12px!important;
}

#textarea-box .input-label {
	position: absolute;
	top: 0;
	padding-left: 5px;
	font-size: 14px;
	color: #728096;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#textarea-box .input-label::after {
	content: '';
	position: absolute;
}

#textarea-box .input-label-content {
	position: relative;
	padding: 0 5px;
	color: #728096;
	background-color:#FFF;
	opacity: 1;
	display: inline-block;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	top: -13px;
    left: 15px;
    font-size: 10px;
}

.input-label-content-modal {
	top: -7px!important;
}



/* -------------------------------------------- */
/* 		Textarea Counter and Button
/* -------------------------------------------- */

.jQTAreaExt {
  margin-bottom: 0;
  height: 2px;
  background-color: $primaryTextActiveColor;
}

#textarea-settings {
	padding: 5px 0 14px;
}

#textarea-settings .character-counter {
	float: left;
}

#textarea-settings .jQTAreaCount,
#textarea-settings .jQTAreaValue {
  font-style: normal;
}

#textarea-settings .clear-button {
  float: right;
  font-size: 10px;
}

#textarea-settings .clear-button button {
  font-size: 10px;
  color: #728096;
  padding-right: 0;
  background: none;
  border: none;
  outline: none;
	text-decoration: none;
}

#textarea-settings .clear-button button:hover {
  color: $primaryTextActiveColor;
}

#textarea-settings .character-counter {
  font-style: normal;
  font-size: 10px;
  color: #728096;
}

.main-action-button {
	width: 142px;
	font-size: 12px!important;
}

#processing {
	display: none;
}

/* -------------------------------------------- */
/* 		Radio Button Checkboxes
/* -------------------------------------------- */

#audio-format,
#voice-types {
	display: inline-flex;

	.radio-control {
		display: inline-flex;
	}

	.radio-control {
		display: inline-flex;
	}
	
	.radio-control:hover > .label-control {
		  color: $primaryTextActiveColor;
		  cursor: pointer;
	}
	
	.input-control {
		 appearance: none;
		 display: inline-block;
		 position: relative;
		 background-color: $body-background;
		 color: $primaryTextActiveColor;
		 top: 5px;
		 height: 15px;
		 width: 15px;
		 border: 0;
		 cursor: pointer;     
		 outline: none;
		 transition: all 0.2s;
		 border-radius: 3px;
	}
	
	.input-control:checked::before {
		 position: absolute;
		 font: 9px/1 'Open Sans', sans-serif;
		 left: 5px;
		 top: 2px;
		 content: '\02143';
		 transform: rotate(40deg);
	}
	
	.input-control:checked {
		background-color: $primaryTextActiveColor;
		color: #FFF;
		transition: all 0.2s;
	}
	
	.input-control:checked + .label-control {
		color: $primaryTextActiveColor;
	}
	
	.label-control {
		margin-left: 5px;
		margin-bottom: 0;
		margin-right: 1rem;
		padding-top: 3px;
		font-size: 10px;
		font-weight: 800;
		line-height: 1.8;

		&:hover {
			color: $primaryTextActiveColor;
		}
	}
}

#voice-types {
	.label-control {
		padding-top: 2px!important;
		font-size: 12px!important;
		font-weight: 400!important;
	}
}

#mp3-format,
#ogg-format,
#webm-format,
#wav-format {
	line-height: 1;

	.label-control {
		line-height: 1.8!important;
	}
}

.block-radio {
	cursor: not-allowed!important;
}

.block-label {
	color: #708096!important;

	&:hover, &:focus {
		cursor: not-allowed!important;
	}
}

#tts-awselect .awselect {
	margin-bottom: 0.7rem;
}


/* ==========================================================================           
 *
 *    GREEN AUDIO PLAYER
 *
 * ========================================================================== */

 .voice-player {
	position: relative;

 	img {
		position: absolute;
		padding: 0 1.5rem;
		border: 1px solid #EBECF2;
		border-radius: 20px;
		background: #fff;
		top: -15px;
		left: 75px;
	}

	i {
		position: absolute;
    	top: 27px;
    	right: 21px;
	}

 }

.green-audio-player {
	box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
	padding-left: 100px;
   	padding-right: 25px;
   	width: auto;
	min-width: auto;
	height: 70px;

	.slider .progress .pin {
		background-color: $primaryTextColor;
	}

	.controls span {
		color: $primaryTextColor;
	}

	.controls {
		color: $primaryTextColor;
		font-size: 10px;
		margin-left: 20px;

		a {
			cursor: pointer;

			&:hover, &:focus {
				color: $primaryTextActiveColor;
			}
		}
	}

	.slider .gap-progress .pin {
		background: $primaryTextColor;
	 }

	 .slider .gap-progress {
		background: $primaryTextColor;
	 }
}

#controls {

	a {
		cursor: pointer;

		&:hover, &:focus {
			color: $primaryTextActiveColor;
		}
	}

	button {
		cursor: pointer;

		&:hover, &:focus {
			color: $primaryTextActiveColor;
		}
	}
}

#user-result .green-audio-player {
	padding-left: 24px;
   	padding-right: 24px;
	height: 50px;
}

.green-audio-player .play-pause-btn svg {
   width: 12px;
   margin-left: 0.5rem;
   margin-right: 0.2rem;
}

.green-audio-player .volume .volume__button svg {
   width: 16px;
}

.green-audio-player .download .download__link svg {
   width: 16px;
   margin-right: 0.3rem;
}

.green-audio-player .holder .play-pause-btn path {
   fill: $primaryTextColor;
}

.green-audio-player .volume .volume__button path {
   fill: $primaryTextColor;
}

.green-audio-player .volume .volume__button.open path {
   fill: $primaryTextColor;
}
.green-audio-player .volume .volume-btn.open path {
 fill: $primaryTextColor;
}

.green-audio-player .download .download__link path {
   fill: $primaryTextColor;
}

.tooltip {
  font-size: 11px;
}

.tooltip-inner {
  padding: 5px 15px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 15px;
  box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
}

.info-notification {
	padding: 3px 6.5px 3px 6.4px;
    background: $primaryTextColor;
    color: white;
    font-size: 9.5px;
    border-radius: 50%;
	box-shadow: $special-shadow;
	cursor: pointer;
	margin-bottom: -7px;
}

.green-color {
	color:#007e08;
}

#listen-result-player .green-audio-player {
	padding-left: 10px!important;
   	padding-right: 10px!important;
	height: 56px!important;
	box-shadow: none!important;
}

#listenModal .modal-footer {
	justify-content: center;
}

#listenModal .modal-body {
	padding-bottom: 10px;
}

#notificationModal .modal-title {
	line-height: 1.7;
	text-transform: none;
}

#waveform-box {
    display: none;
} 

#return-sound {
	position: absolute;
	right: 0;
	margin-right: 1rem;
}

#list-music {
	line-height: 2;
}

.voice-neural { 
	background: #E1F0FF;
	color: $primaryTextActiveColor;
}

.rounded-circle {
	border-radius: 0!important;
	height: 100%;
	object-fit: cover;
	width: 100%;
  }

#template-output-transcript {
	min-height: 400px;
}

.result-play {
    color: $primaryTextColor;

	&:hover {
		color: $primaryTextActiveColor;
	}
}

.task-transcribe {
	background: #E1F0FF;
	color: #007bff;
}

.task-translate {
	background: #FFF4DF;
	color: #FF9D00;
}


/* ==========================================================================           
 *
 *    VIEW TRANSCRIBE RESULT
 *
 * ========================================================================== */

 .control-button {
	height: 30px;
	cursor: pointer;
	filter: drop-shadow( 1px 1px 1px #7B7B7B );
}

.control-button-left {
	float: left!important;
	cursor: pointer;
	filter: drop-shadow( 1px 1px 1px #7B7B7B );
}

.control-button-right {
	float: right!important;
}

#transcript-table {
	.transcript-table-column {
		font-family: 'Poppins', sans-serif;
		font-size: 14px;
		font-weight: 700;
	}
}

#transcript {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
	background-color: $body-background;
}

.card-title-inner {
	font-weight: 700;
	font-size: 14px;
}

#create-category {
    position: absolute;
    right: 1.5rem;
}



/* ==========================================================================           
 *
 *    CHAT SYSTEM
 *
 * ========================================================================== */

#chat-system {

	min-height: 400px;

	font-size: 13px;

	.chat-button {
		//padding: 0.75rem 15px !important;
		z-index: 100;
	}

	#chat-container {
		max-height: 490px;
		height: 490px;
		overflow-y: scroll;
		padding-left: 1rem;
		padding-right: 1rem;
	}
	  
	.msg {
	display: flex;
	align-items: flex-end;
	margin-bottom: 1rem;
	}
	  
	.msg:last-of-type {
	margin: 0;
	}
	  
	.message-img {
		min-width: 40px;
		min-height: 40px;
		margin-right: 15px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: 50%;
		margin-bottom: auto;
	}
	  
	.message-bubble {
		padding: 10px 15px;
		border-radius: 10px;
		background: #f5f9fc;
		position: relative;
		height: auto;
	}

	.left-msg .message-bubble:before {
		content: "";
		width: 0;
		height: 0;
		border-top: 6px solid transparent;
		border-bottom: 6px solid transparent;
		border-right: 6px solid #f5f9fc;
		left: -6px;
		right: auto;
		top: 15px;
		position: absolute;
	}

	.right-msg .message-bubble:before {
		content: "";
		width: 0;
		height: 0;
		border-top: 6px solid transparent;
		border-bottom: 6px solid transparent;
		border-right: 6px solid #1e1e2d;
		left: auto;
		right: -6px;
		transform: rotate(180deg); 
		top: 15px;
		position: absolute;
	}
	
	.msg-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
	}
	
	.msg-info-name {
		margin-right: 10px;
		font-weight: bold;
	}
	  
	.msg-info-time {
		font-size: 0.85em;
	}
	
	.right-msg {
		flex-direction: row-reverse;
	}
	
	.right-msg .message-bubble {
		background: #16171c;
		color: #fff;
	}
	
	.right-msg .message-img {
		margin: 0 0 0 15px;
		margin-bottom: auto;
	}

	#clear-button {
		position: absolute;
		right: 0;
		margin-right: 1rem;
	}
}

.mt-12 {
	margin-top: 1.2rem!important;
}

/* ==========================================================================           
 *
 *    PROMOCODE
 *
 * ========================================================================== */

 .promocode-field {
	padding: 0.55rem 1rem!important;
	margin-right: 80px;
 }

 .payment-lifetime { 
	background: rgba(0,188,126,.1);
	color: #00bc7e;
}

#total_discount {
	color: #00bc7e;
}

/* ==========================================================================           
 *
 *    CODE
 *
 * ========================================================================== */
 #code-textarea {
	display: none;
 }

 #code-textarea,
 #code-result,
 #code {

    padding-top: 1rem;
    padding-bottom: 1rem;

    p {
        font-size: 14px;
    }

	pre {
		padding: 1.5rem;
		border-radius: 10px;
		font-size: 14px;

		code {
			font-family: source-code-pro,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace!important;
			font-size: 14px;
			line-height: 1.3rem;
		}
	}
 }

 #code {
	padding-top: 0;
	padding-bottom: 0;
 }

 #generating-status,
 #generating-message {
    position: absolute;
    top: 41%;
    left: 41%;
    font-size: 14px;
 }

 #generating-status {
   display: none;
 }

 .show-chat-loader {
	display: block!important;
 }

 .copy-code {
	float: right;
    background: none;
    border: none;
    color: white;
	font-size: 12px;

	&:hover, &:focus {
		color: $primary;
	}
 }


 /* ==========================================================================           
 *
 *    CHAT
 *
 * ========================================================================== */

 .chat-boxes {

	position: relative;
	.card-body {
		min-height: 155px;
	}
	
	margin-top: -1.5rem;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover, &:focus {
		transform: scale(1.05);
		z-index: 100;
	}

	.favorite {
		border: 2px solid #FF9D00;
		background-color: #FEFCE8;
	}

	.professional {
		border: 2px solid #AB54EB;
		background-color: #F6E9FF;
	}
	
	a {
		position: relative;
		top: 45px;
		margin-left: 1rem;
		float: left;
		z-index: 200;

		i.star {
			font-size: 16px;
			padding-top: 7px;
			color: #FF9D00;
		}

		i.fa-stars {
			font-size: 18px;
		}
	}

	.btn-yellow {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: #FF9D00;
		border-color: #FF9D00;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		position: absolute;
		right: 20px;
		top: 50px;
		z-index: 10;
	}

	.btn-free {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: #1e1e2d;
		border-color: #1e1e2d;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		position: absolute;
		right: 20px;
		top: 50px;
		z-index: 10;
	}

	.btn-premium {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: #1e1e2d;
		border-color: #1e1e2d;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		position: absolute;
		right: 20px;
		top: 50px;
		z-index: 10;
	}

	.btn-pro {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: $primaryTextActiveColor;
		border-color: $primaryTextActiveColor;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		position: absolute;
		right: 20px;
		top: 50px;
		z-index: 10;
	}

	.premium {
		border: 2px solid #AB54EB;
		background-color: #F6E9FF;
	}

	.professional {
		border: 2px solid $primaryTextActiveColor;
		background-color: #E1F0FF; 
	}

	.widget-user-image {
		height: 130px;
		width: 130px;
	}
 }

 :root {
    --sidebar-width: 300px;
    --window-content-width: calc(100% - var(--sidebar-width));
}

 .chat-main-container {
    min-width: 800px;
    min-height: 500px;
    max-height: 750px;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    width: 100vw;
    height: auto;
	font-family: "Lato", sans-serif;
    position: relative;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
	padding-left: 0;
	padding-right: 0;

	#expand {
		display: none;
	}

	.chat-sidebar-container {
		top: 0;
		width: var(--sidebar-width);
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		border-right: 1px solid #ebecf1;
		position: relative;
		transition: width .05s ease;

		.chat-sidebar-messages {
			flex: 1 1;
			overflow: auto;
			overflow-x: hidden;
			padding: 20px;

			.chat-sidebar-message {
				padding: 10px 14px;
				background-color: #F5F9FC;
				border-radius: 10px;
				margin-bottom: 10px;
				border: 1px solid #F5F9FC;
				transition: background-color .3s ease;
				cursor: pointer;
				-webkit-user-select: none;
				-moz-user-select: none;
				user-select: none;
				position: relative;

				.chat-title {
					font-size: 13px;
					font-weight: 600;
					display: block;
					width: 200px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.chat-info {
					display: flex;
					justify-content: space-between;
					color: #a6a6a6;
					font-size: 12px;
					margin-top: 8px
				}

				.chat-actions {
					position: absolute;
					top: 10px;
					right: -20px;
					transition: all .3s ease;
					opacity: 0;
					cursor: pointer;
					z-index: 100;
					color: black;					
				}

				&:hover, &:focus {

					.chat-actions {
						opacity: .5;
						right: 10px;
						
						&:hover, &:focus {
							opacity: 1;
						}
					}

					border: 1px solid #EDF8FD;
					background-color: #FFF;
				}
			}

			.selected-message {
				border: 1px solid $primaryTextActiveColor;
				background-color: #FFF;

				.chat-title {
					color: $primaryTextActiveColor;
				}
			}

			
		}
	}

	.chat-message-container {
		width: calc(100% - var(--sidebar-width));
		height: 100%;
		display: flex;
		flex-direction: column;

		.chat-avatar {
			border-radius: 50%;
			height: 44px;
			width: 44px;
			clear: both;
			display: block;
			background: #E1F0FF;
			position: relative;
		}
	}

	.card-footer {
		border-top: 1px solid #ebecf1!important;
		min-height: 75px!important;
	}
 } 

.template {
	.btn-free {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: #1e1e2d;
		border-color: #1e1e2d;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		position: absolute;
		right: 10px;
		bottom: 10px;
		z-index: 10;
	}

	.btn-premium {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: #1e1e2d;
		border-color: #1e1e2d;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		position: absolute;
		right: 10px;
		bottom: 10px;
		z-index: 10;
	}

	.btn-pro {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: $primaryTextActiveColor;
		border-color: $primaryTextActiveColor;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		position: absolute;
		right: 10px;
		bottom: 10px;
		z-index: 10;
	}

	.premium {
		border: 2px solid #AB54EB;
		background-color: #F6E9FF;
	}

	.professional {
		border: 2px solid $primaryTextActiveColor;
		background-color: #E1F0FF; 
	}
}

.plan-premium {
	color: #AB54EB;
	background: #F6E9FF;
}

.extend {
	left: 0!important;
	transition: all .5s ease!important;
}

.chat-logo-image {
	height: 80px;
    width: 80px;
    margin: 0 auto;
    display: block;
    border-radius: 100%;
    box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
    position: relative;
}

.modal-header .btn-close {
	font-size: 12px;
}

.modal-body,
#create-new-chat {
	.browse-file {
		margin-right: 100px;
	}
} 

/* ==========================================================================           
 *
 *    DASHBOARD
 *
 * ========================================================================== */

 .text-white {
	&:hover, &:focus {
		color: $primaryTextActiveColor!important;
	}
 }



 /* ==========================================================================           
 *
 *    ADMIN USER LIST
 *
 * ========================================================================== */

 .dt-buttons {

	margin-top: 5px;

	.dt-button {
		font-size: 10px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 35px;
		text-transform: uppercase;
		color: #fff !important;
		border-color: $primaryTextActiveColor;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		background: $primaryTextActiveColor;
		min-width: 70px;

		&:hover, &:focus {
			background-color: #1e1e2f!important;
			background: #1e1e2f!important;
		}
	}
 }



 /* ==========================================================================           
 *
 *    AI CHAT BOT
 *
 * ========================================================================== */

 .chat-main-container {
	::-webkit-scrollbar {
		width: 2px!important;
		border: 1px solid #EDF8FD!important;
	}
 }

 /* ==========================================================================           
 *
 *    USER DASHBOARD
 *
 * ========================================================================== */

 #user-dashboard-panels {
	.favorite-templates-panel {
		max-height: 600px;
		overflow-y: scroll;
 	}

	 ::-webkit-scrollbar {
		width: 2px!important;
		border: 1px solid #EDF8FD!important;
	}
}

#user-dashboard-background {
	background-color: #E7F8FF;
	border-radius: 8px;
    margin-left: 0rem;
    margin-right: 0rem;
    padding: 1rem;
	background-image: url(/img/files/waves.png);
    background-size: cover;
	background-position: center;

	.yellow {
		background-color: #FF9D00;
		border-color: #FF9D00;

		&:hover, &:focus {
			background-color: $primaryTextColor;
			border-color: $primaryTextColor;
		}
	}
}

.user-dashboard-special-box {
	box-shadow: none!important;
	border-color: #f5f9fc;
	margin-bottom: 0.5rem;
}

.user-dashboard-button {
	vertical-align: baseline;
}


 /* ==========================================================================           
 *
 *    AI IMAGES
 *
 * ========================================================================== */

 .ai-image-special {
	height: 50px;
	min-width: 50px;
 }


/* ==========================================================================           
 *
 *    TEMPLATES
 *
 * ========================================================================== */
 .templates-nav-header {
	border-radius: 1rem;
	.card-body {
		padding: 1rem;
	}
 }

 .templates-nav-menu {

	.template-nav-menu-inner {
		padding: 10px;
		background-color: $body-background;
		border-radius: 50vh
	}

	.nav-tabs {

		display: flex;
		border-bottom: none;
		overflow: overlay;
		flex-wrap: nowrap!important;
		white-space: nowrap!important;
		overflow-y: hidden;

		.nav-link {
			font-size: 12px;
			margin-right: 0.7rem;
			min-width: 80px;
			padding-left: 15px;
			padding-right: 15px;
			border-radius: 35px;
			color: #1e1e2d;
			border: none;
			font-weight: 600;
			margin-bottom: 0!important;

			&:hover, &:focus {
				background-color: #1e1e2d;
				color: #FFF;
				border-color: #1e1e2d;
				box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
			}
		}

		.active {
			background-color: #1e1e2d;
			color: #FFF;
    		border-color: #1e1e2d;
			box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		}
	}

	::-webkit-scrollbar {
		height: 8px!important;
		border: 1px solid #EDF8FD!important;
	}
 }

 .search-template {
	.input-box {

		margin-bottom: 1rem;

		.form-control {
			height: 48px;
			line-height: 48px;
			padding: 0 20px;
			background-color: #FFF!important;
			border-radius: 50vh;
			border-color: $body-background;

			&:focus {
				border-color: #007bff;
			}

		}
	}
 }

 .btn-yellow {
	background-color: #AB54EB!important;
	border-color: #AB54EB!important;
 }

 .templates-panel-group {
    margin-bottom: 0.5rem;
 }

 .dataTable tbody tr.shown + tr td:first-of-type {
	border-top: 0;
 }


/* ==========================================================================           
 *
 *    v2.2 CSS Updates
 *
 * ========================================================================== */
 
 #awselect_openai-key-usage,
 #awselect_sd-key-usage {
	 margin-bottom: 0;
 }
 
 #chat-search-panel {
	 #search-template {
		 margin-bottom: 2rem;
		 box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	 }
 }
 
 .local-menu {
	 max-height: 300px;
	 overflow-y: scroll;
 }

 /* ==========================================================================           
 *
 *    v2.3 CSS Updates
 *
 * ========================================================================== */
 
#voiceover-character-counter {
	position: absolute;
	right: 2rem;
}

#dashboard-background {
	background-color: #FFF;
	background-image: url(/img/files/waves.png);
	background-size: cover;
	background-position: top;
}

.richText-toolbar:has(.richText-undo) {
	display: none;
}

.template-icon {

	i.marketing-icon,
	i.ad-icon {
		background: #FFE2E5; 
		color: #ff0000;
		font-size: 16px;
		padding: 7px;
		border-radius: 5px;
	}

	i.framework-icon {
		background: #cde4e8;
    	color: #087990;
		font-size: 16px;
		padding: 7px;
		border-radius: 5px;
	}

	i.ecommerce-icon {
		background: #E1F0FF;
    	color: #007bff;
		font-size: 16px;
		padding: 7px;
		border-radius: 5px;
	}
}

.side-word-notification {
    padding: 5px 20px;
}

.font-weight-600 {
	font-weight: 600!important;
}

.upgrade-action-button {
	&:hover, &:focus {
		color: $primaryTextActiveColor!important;
	}
}

hr:not([size]) {
    height: 0px;
}

hr {
	opacity: 1;
}
.template {

	.card-body {
		min-height: 165px;
	}

	.btn-new {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: #007bff;
		border-color: #007bff;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		position: absolute;
		right: 10px;
		bottom: 10px;
		z-index: 10;
	}

	.btn-new-free {
		right: 75px!important;
	}

	.btn-new-pro {
		right: 70px!important;
	}

	.btn-new-premium {
		right: 94px!important;
	}
}

.template-view {
	.btn-new {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: #007bff;
		border-color: #007bff;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		cursor: pointer;
	}

	.btn-free {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: #1e1e2d;
		border-color: #1e1e2d;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		z-index: 10;
		cursor: pointer;
	}

	.btn-premium {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: #1e1e2d;
		border-color: #1e1e2d;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		z-index: 10;
		cursor: pointer;
	}

	.btn-pro {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		background-color: $primaryTextActiveColor;
		border-color: $primaryTextActiveColor;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		z-index: 10;
		cursor: pointer;
	}
}

.ad-icon {
	font-size: 16px;
    padding: 7px;
    background: #FFE2E5; 
	color: #ff0000;
    border-radius: 5px;
}

.ecommerce-icon {
	font-size: 16px;
    padding: 7px;
    background: #E1F0FF;
    color: #007bff;
    border-radius: 5px;
}

.framework-icon {
	font-size: 16px;
    padding: 7px;
    background: #cde4e8;
    color: #087990;
    border-radius: 5px;
}

.marketing-icon {
	font-size: 16px;
    padding: 7px;
    background: #FFE2E5; 
	color: #ff0000;
    border-radius: 5px;
}

.profile-dropdown {
	.dropdown-menu {
		overflow-y: auto;
	}
}


/* ==========================================================================           
 *
 *    v2.6 CSS Updates
 *
 * ========================================================================== */
 
 .btn-primary-pricing {
	font-size: 13px;
    min-width: 100%;
	border-radius: 35px;
    padding: 0.8rem;
	color: $white !important;
	background-color: #000;
	border-color: #000;
	box-shadow: 0 1px 3px 0 rgb(50 50 50 / 20%), 0 2px 1px -1px rgb(50 50 50 / 12%), 0 1px 1px 0 rgb(50 50 50 / 14%);
  
	&:hover, &:focus {
	  color: $white !important;
	  background-color: $primaryTextActiveColor;
	  border-color: $primaryTextActiveColor;
	}
  }

 
  /* ==========================================================================           
 *
 *    Select Styles
 *
 * ========================================================================== */
 select.form-select {
	width: 100%;
	font-size: 12px;
	background-color: #f5f9fc;
	color: #000;
	font-family: "Poppins", sans-serif;
	line-height: 1rem;
    padding: 0.75rem 1rem;
	border-radius: 0.5rem;
	border-color: transparent;
	background-size: 10px 10px;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%233b3b3b' d='M2 0 0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");

	&:hover, &:focus {
		border-color: #007BFF;
		box-shadow: none;
		transition: all 0.2s;
	}
  }

  select.top-form-select {
	width: 250px;
	font-size: 12px;
	background-color: transparent;
	color: #000;
	font-family: "Poppins", sans-serif;
	line-height: 1rem;
    padding: 0.75rem 1rem;
	border-radius: 0.5rem;
	opacity: 0;
	border-color: transparent;
  }


/* ==========================================================================           
 *
 *    AI Image Styles
 *
 * ========================================================================== */
#image-side-space {
	margin-right: 284px;
	transition: margin 0.3s ease;

	.card-top {
		float: right;

		a,
		#main-settings-toggle-minimized {
			i {
				vertical-align: super;
				transition: all 0.3s ease;

				&:hover, &:focus {
					color: $primaryTextActiveColor!important;
				}
			}
		}
	}

	.card-bottom {
		float: right;

		.custom-switch {
			&:hover {
				cursor: pointer;
			}
		}
	}

	#negative-prompt {
		display: none;
	}
}

.image-prompt-wrapper {
	border-radius: 50vh;

	.image-prompt {
		.input-box {

			width: 90%;
			.form-control {
				height: 48px;
				line-height: 48px;
				padding: 0 20px;
				background-color: #FFF!important;
				border-radius: 50vh;
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
				border-color: $body-background;

				&:focus {
					border-color: #007bff;
				}

			}
		}

		.negative {
			width: 100%;
			.form-control {
				border-radius: 50vh;
			}			
		}

		#image-generate {
			height: 48px;
			font-size: 14px;
			text-transform: none;
			font-weight: 600;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			min-width: 170px;
			background: #000;
			border-color: #000;

			&:hover, &:focus {
				background: $primaryTextActiveColor;
				border-color: $primaryTextActiveColor;
				transform: none;
			}

			:disabled {
				background-color: green;
				cursor: not-allowed;
			}
		 }
	}
}

#image-settings-wrapper {
	width: 300px;
    max-height: 100%;
    background: #fff !important;
    transition: right 0.3s ease, width 0.3s ease;
    top: 74px;
    position: fixed;
    right: 0;
    bottom: 0;
	overflow-y: scroll;

	.image-settings {

		#main-settings-toggle-minimized {
			position: absolute;
			right: 1rem;
			display: none;
		}

		.image-numbers {
			.quantity {
				position: relative;
				width: 120px;

				a {
					display: block;
					position: absolute;
					width: 24px;
					height: 24px;
					text-decoration: none;
					border-radius: 100%;
					top: 50%;
					margin-top: -12px;
					background: #f5f9fc;
				}

				.decrease {
					left: 8px;
				}

				.increase {
					right: 8px;
				}

				a:after {
					position: absolute;
					width: 8px;
					height: 2px;
					margin: -1px 0 0 -4px;
				}

				a.increase:before {
					position: absolute;
					width: 2px;
					height: 8px;
					margin: -4px 0 0 -1px;
				}

				a.increase:before, 
				a:after {
					background-color: #1e1e2d;
					left: 50%;
					top: 50%;
					content: '';
				}

				input {
					width: 100%;
					max-width: 100%;
					min-width: 100%;
					border-radius: 20px;
					display: block;
					height: 40px;
					text-align: center;
					font-size: 12px;
					color: #1e1e2d;
					padding: 0 34px;
					border: 1px solid #ebecf1;
				}

				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				input[type=number] {
					-moz-appearance: textfield;
				}
			}			
		}

		#style-button {
			width: 100%;
			font-size: 12px;
			background-color: #f5f9fc;
			color: #000;
			font-family: "Poppins", sans-serif;
			line-height: 1rem;
			padding: 0.45rem 1rem;
			border-radius: 0.5rem;
			border-color: transparent;
			text-align: left;
			box-shadow: none;
			outline: none;

			img {
				width: 25px;
				height: 25px;
				margin-right: 0.5rem;
				border-radius: 5px;
			}

			i {
				float: right;
				font-size: 10px;
				line-height: 1.5;
				transition: all 0.3s ease;
			}

			.extra-line-height {
				line-height: 2.3;
			}

			.style-button-img {
				display: none;
			}

			&:hover {
				border-color: $primaryTextActiveColor;
				transition: all 0.2s;
				
			}
		}

		.rotate-90 {
			border-color: $primaryTextActiveColor!important;
			i {
				rotate: 90deg;
			}
		}

		.style-button-img-placeholder {
			padding: 0.75rem 1rem!important;
		}

		#advanced-settings-wrapper {
			display: none;
		}

		.range {
			position: relative;
			display: flex;
			-ms-align-items: center;
			align-items: center;

			.range_in {
				position: relative;
				width: 100%;

				input {
					-webkit-appearance: none;
					width: 100%;
					margin: 0;
					outline: 0;
					border: none;
					background-color: #c0bcca;
				}

				.slider {
					position: absolute;
					left: 0;
					top: 50%;
					margin-top: -2px;
					pointer-events: none;
					background: $primaryTextActiveColor;
				}

				.slider:before {
					width: 14px;
					height: 14px;
					background: $primaryTextActiveColor;
					cursor: pointer;
					border-radius: 100%;
					position: absolute;
					display: block;
					right: 0;
					top: 50%;
					margin-top: -7px;
					content: '';
					z-index: 2;
				}

				.slider:after {
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					position: absolute;
					content: '';
					border-radius: 5px 0 0 5px;
				}

				.slider, input {
					display: block;
					height: 4px;
					border-radius: 5px;
				}	

				input::-webkit-slider-thumb {
					-webkit-appearance: none;
					appearance: none;
					width: 14px;
					height: 14px;
					background: $primaryTextActiveColor;
					cursor: pointer;
					border-radius: 100%;
					opacity: 0
				}

				input::-moz-range-thumb {
					width: 14px;
					height: 14px;
					background: $primaryTextActiveColor;
					cursor: pointer;
					border-radius: 100%;
					z-index: 3;
					opacity: 0
				}
			}

			.value {
				flex: auto;
				width: 40px;
				text-align: right;
				padding-left: 10px;
				font-size: 12px;
			}
		}

		.middle {
			width: 100%;
			text-align: center;
		
			input[type=radio] {
				display: none;
			}
		
			input[type=radio]:checked + .box {
				background-color: #000;
				box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
			}
		
			input[type=radio]:checked + .box span {
				color: white;
			}
		
			.box {
				background-color: #fff;
				transition: all 250ms ease;
				will-change: transition;
				display: inline-block;
				text-align: center;
				cursor: pointer;
				position: relative;
				font-weight: 800;
				border-radius: 15px;
				padding: 6px 16px;
				line-height: 1;
			}
		
			.box span {
				position: relative;
				transition: all 300ms ease;
				font-size: 12px;
				user-select: none;
				color: #1e1e2d;
			  }
		}
	}
}

.expand-main-width {
	margin-right: 2rem!important;
}

.shrink-main-settings {
	width: 0px!important;
}


.grid-item { 
	width: 100%;
	height: 100%;
	padding: 3%;

	.grid-image-wrapper {
		border-radius: 10px;
		overflow: hidden;
		box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
		position: relative;

		.grid-buttons {
			position: absolute;
			top: 50%;
			text-align: center;
			z-index: 5;
			display: flex;
			width: 120%;
			margin: auto;
			justify-content: center;

			.grid-image-view {
				z-index: 12;
				i {
					background-color: #0000005c;
					color: #fff;
					width: 40px;
					height: 40px;
					border-radius: 33px;
					line-height: 40px;
					box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
					transform: translate(-50%,-50%) scale(.4);
					visibility: hidden;
					opacity: 0;
					-webkit-transition: all 250ms cubic-bezier(.24,.22,.015,1.56);
					transition: all 250ms cubic-bezier(.24,.22,.015,1.56);
					margin-right: 0.5rem;
	
					&:hover {
						background-color: #000;
						color: #FF9D00;
					}
				}
			}
	
		}

		.grid-image {
			display: block;
			position: relative;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				transition: transform .7s cubic-bezier(.2,1,.22,1),opacity 0.6s ease 0.25s;
				-webkit-transition: transform .7s cubic-bezier(.2,1,.22,1),opacity 0.6s ease 0.25s;
			}

			&:hover {
				img {
					transform: scale(1.07);
    				-webkit-transform: scale(1.07)
				}
			}
		}

		.grid-description {
			position: absolute;
			bottom: 0px;
			padding-left: 10px;
			padding-bottom: 5px;
			color: #FFF;
			background: linear-gradient(180.49deg, rgba(0, 0, 0, 0) 37.77%, rgba(0, 0, 0, 0.32) 65.93%, rgba(0, 0, 0, 0.48) 78.19%, rgba(0, 0, 0, 0.64) 87.41%, rgba(0, 0, 0, 0.8) 98.87%);
			transform: translateY(50px);
			transition: all 250ms ease;
			width: 100%;

			span {
				font-weight: 900;
			}

			p {
				line-height: 1.1;
			}
		}

		&:hover {
			.grid-image-view {
				i {
					visibility: visible;
					opacity: 1;
					transform: translate(-50%,-50%) scale(1);
				}
			}

			.grid-description {
				transform: translateY(0);
			}
		}
	}
}

.custom-modal {
	.modal {		
		display: none;
		position: fixed; 
		z-index: 10; 
		width: 100%; 
		height: 100%; 
		overflow: auto; 

		.close {
			i {
				&:hover {
					color: #000;
					cursor: pointer;
				}
			}
		}

		.modal-body {
			overflow: hidden;
    		overflow-y: auto;
		}

		.modal-content {
			background-color: #FFF;
			width: 320px;
			max-height: 400px;
			border-radius: 1rem;
			float: right;
			margin-right: 305px;
			margin-top: 310px;
			padding: 0.5rem;
			border: none;
			transition: all 0.3s ease;
			box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
		}

		.image-styles-wrapper {
			text-align: center;
			
			.image-style {
				input[type=radio] {
					display: none;
				}
			
				input[type=radio]:checked + .image-label {
					border-color: $primaryTextActiveColor;
					box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
				}
				
				.image-label {
					overflow: hidden;
					position: relative;
					border-radius: 10px;
					border: 2px solid #FFF;

					img {
						cursor: pointer;
						object-position: center;
						object-fit: cover;
						height: 100%;
					}

					.bg-dark-overlay {
						background: linear-gradient(180.49deg, rgba(0, 0, 0, 0) 37.77%, rgba(0, 0, 0, 0.32) 65.93%, rgba(0, 0, 0, 0.48) 78.19%, rgba(0, 0, 0, 0.64) 87.41%, rgba(0, 0, 0, 0.8) 98.87%);
						width: 100%;
						height: 30px;
						position: absolute;
						right: 0px;
						bottom: 0px;
						left: 0px;
						opacity: 0.7;
					}

					span {
						position: absolute;
						color: #FFF;
						bottom: 3px;
						font-size: 10px;
						font-weight: 700;
						display: flex;
						width: 100%;
						margin: auto;
						justify-content: center;
					}

					&:hover {
						border-color: $primaryTextActiveColor;
						box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
					}
				}
			}			
		}
	}
}

.image-modal {
	.modal-header {
		background: #0f0f11;
		color: #FFF;
		padding-bottom: 0;

		.btn-close {
			font-size: 16px;
			padding: 0;
			background: #FFF url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQ2NCAzMkg0OEMyMS41IDMyIDAgNTMuNSAwIDgwdjM1MmMwIDI2LjUgMjEuNSA0OCA0OCA0OGg0MTZjMjYuNSAwIDQ4LTIxLjUgNDgtNDhWODBjMC0yNi41LTIxLjUtNDgtNDgtNDh6bS04My42IDI5MC41YzQuOCA0LjggNC44IDEyLjYgMCAxNy40bC00MC41IDQwLjVjLTQuOCA0LjgtMTIuNiA0LjgtMTcuNCAwTDI1NiAzMTMuM2wtNjYuNSA2Ny4xYy00LjggNC44LTEyLjYgNC44LTE3LjQgMGwtNDAuNS00MC41Yy00LjgtNC44LTQuOC0xMi42IDAtMTcuNGw2Ny4xLTY2LjUtNjcuMS02Ni41Yy00LjgtNC44LTQuOC0xMi42IDAtMTcuNGw0MC41LTQwLjVjNC44LTQuOCAxMi42LTQuOCAxNy40IDBsNjYuNSA2Ny4xIDY2LjUtNjcuMWM0LjgtNC44IDEyLjYtNC44IDE3LjQgMGw0MC41IDQwLjVjNC44IDQuOCA0LjggMTIuNiAwIDE3LjRMMzEzLjMgMjU2bDY3LjEgNjYuNXoiLz48L3N2Zz4=);
		}
	}
	.modal-content {
		box-shadow: 0 7px 15px rgba(0,0,0,0.35);
	}

	.modal-body {
		padding: 0.5rem 1rem 1rem;
		background: #0f0f11;

		.image-view-box {
			width: auto;
			height: auto;
			position: relative;

			img {
				border-radius: 5px;
			}

			.download-image {
				position: absolute;
				background-color: #0000005c;
				color: #fff;
				width: 40px;
				height: 40px;
				border-radius: 5px;
				line-height: 40px;
				box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
				left: 10px;
				top: 10px;

				&:hover {
					background: #0f0f11;
					color: $primaryTextActiveColor;
				}
			}
		}

		.image-description-box {
			.description-title {
				color: #7e7a86;
				letter-spacing: 0.5px;
				font-weight: 500;
				text-transform: uppercase;
				margin-bottom: 2px;
				font-size: 12px;
			}

			.description-data {
				font-size: 14px;
				color: #FFF;
			}

			.image-prompt {
				background: #FFF;
				border-radius: 5px;
    			padding: 1rem;
			}
		}
	}
}

.style-initial-state {
	display: none;
}

.hide-all {
	display: none!important;
}

.show-all {
	display: block!important;
}


/* ==========================================================================           
 *
 *    Chat Styles
 *
 * ========================================================================== */
 .chat-card-header {
	min-height: 5rem;
}

.chat-sidebar-search {
	max-height: 79px;
    font-size: 16px;
    margin: 0;
    padding: 1.2rem 1.5rem;
    color: #333;
    display: block;
    position: relative;
	min-height: 3.5rem;
	border-bottom: 1px solid #ebecf1;

	.chat-search-icon {
		position: absolute;
		right: 1rem;
		top: 35%;
	}
}

.chat-sidebar-search-small {
	max-height: 79px;
    font-size: 16px;
    margin: 0;
    padding: 1.2rem 1.5rem;
    color: #333;
    display: block;
    position: relative;
	min-height: 3.5rem;

	.chat-search-icon {
		position: absolute;
		right: 1rem;
		top: 35%;
	}
}

#chat-search {
	border-radius: 1rem;
}

.chat-controllers {
	align-self: flex-end;
    gap: 1rem!important;
    display: flex;
	
	#message {
		background-color: transparent;
		border: none;
	}

	.chat-button {
		font-size: 12px;
		min-width: 100px;
		padding: 0.6rem 10px;
		border-radius: 35px;
		color: #fff;
		background-color: $primaryTextActiveColor;
		border-color: $primaryTextActiveColor;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);

		&:hover {
			background-color: $primaryTextColor;
			border-color: $primaryTextColor;
		}
	}

	.chat-button-icon {
		color: $primaryTextActiveColor;
		border-radius: 50%;
		padding: 0.5rem 0.8rem;
		width: 43px;
		background-color: #E1F0FF;
		outline: none!important;
		text-decoration: none!important;

		&:hover {
			color: $primaryTextColor;
			background-color: #D1D3E0;
		}
	}

	.special-action-color {
		border-color: #E1F0FF;
		color: $primaryTextActiveColor;
		background-color: #E1F0FF;
		box-shadow: none;

		&:hover {
			color: #FFF;
		}
	}
}

#new-chat-button {
	padding: 0.6rem 10px;
	text-transform: none;
}





/* ==========================================================================           
 *
 *    Upgrade Styles
 *
 * ========================================================================== */
 #upgrade-form {
	h1 {
		font-weight: 800;
		font-size: 20px;
	}

	#audio-format .label-control {
		font-size: 13px;
		font-weight: normal;
	 }

	 #webm-format .label-control {
		line-height: 1.5!important;
	 }

	 #audio-format .input-control {
		background-color: #000;
	 }
 }

 .changelogs {
	h5 {
		margin-top: 3rem;
		font-weight: 800;
	}

	.changelog {

		.changelog-description {
			li {
				margin-bottom: 1rem;
			}
		}

		.version-name {
			background: #D1D3E0;
			color: #1e1e2d;
			font-size: 14px;
			padding: 5px 15px;
			border-radius: 15px;
			font-weight: 600;
		}

		.version-new {
			background: rgba(0, 188, 126, 0.1);
    		color: #00bc7e;
			font-size: 10px;
			text-transform: uppercase;
			padding: 2px 15px;
			border-radius: 10px;
			font-weight: 600;
		}

		.version-update {
			background: #E1F0FF;
			color: #007bff;
			font-size: 10px;
			text-transform: uppercase;
			padding: 2px 15px;
			border-radius: 10px;
			font-weight: 600;
		}

		.version-fix {
			background: #F8D7DA;
    		color: #B02A37;
			font-size: 10px;
			text-transform: uppercase;
			padding: 2px 15px;
			border-radius: 10px;
			font-weight: 600;
		}
	}
}

.image-view-outer {
	display: flex;
    align-items: center;
	justify-content: center;
}

.widget-banner-image {
	height: auto;
	width: 100px;
	clear: both;
	display: block;
	position: relative;
  }

.tool-banner-image {
	height: auto;
    width: 100px;
    margin: 0 auto;
    display: block;
    position: relative;
}
 

/* ==========================================================================           
 *
 *    v2.9 Styles
 *
 * ========================================================================== */

 .templates-nav-header {
	margin-bottom: 1rem;
 }

 .chat-nav-menu {
	margin-bottom: 1.5rem;
 }

 .templates-nav-menu .template-nav-menu-inner {
	background-color: #fff;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
 }

 .search-template .input-box .form-control {
	height: 54px;
	margin-bottom: 1rem;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
 }

 #chat-search-panel #search-template {
	margin-bottom: 0;
 }

 .super-strong {
	font-weight: 900!important;
 }


 /* ==========================================================================           
 *
 *    Chat Prompts
 *
 * ========================================================================== */
 .prompts-panel {
	.prompt-boxes {

		.card {
			min-height: 170px;
			margin-top: 0.5rem;
		}

		&:hover, &:focus {
			cursor: pointer;

			.card {
				background-color: $body-background;

				h6 {
					color: $primaryTextActiveColor;
				}
			}			
		}
	}
 }

 .msg-text {
	a {
		color: $primaryTextActiveColor;
	
		&:hover, &:focus {
			color: #ff9d00;
		}
	 }

	 p {
		margin-bottom: 0;
		line-height: 25px;
	 }

	 pre {
		position: relative;
		border-radius: 0.5rem;

		.copy-code {
			position: absolute;
			right: 10px;
			top: 10px;
		}
	 }

	 
 }

 .message-bubble {
	.copy {
		display: none;
		position: absolute;
		right: 5px;
		background: #fff;
		padding: 10px;
		border-radius: 50%;
		bottom: -15px;
		box-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
		transition: all 1s ease;
	 }

	 &:hover, &:focus {
		.copy {
			display: block;
			transition: all 1s ease;
		}
	 }
 }
 
 /* ==========================================================================           
 *
 *    v3.0 Styles
 *
 * ========================================================================== */

 .plan-hidden {
	background: #E1F0FF;
    color: #007BFF;
 }

 .payment-daily {
	background: #F8D7DA;
    color: #B02A37;
 }

.copy-image-prompt,
.copy-image-negative-prompt {
    position: absolute;
    right: 1rem;
 }

 .voice-standard {
    background: #D1D3E0;
    color: #1e1e2d;
 }

 /* ==========================================================================           
 *
 *    v3.3 AI Image Styles
 *
 * ========================================================================== */
 #image-side-space {

	#sd-image-to-image,
   #sd-image-upscale,
   #sd-image-masking,
   #sd-multi-prompting,
   #openai-image-masking,
   #openai-image-variations {
		display: none;
	}

   .image-upload-box {
      position: relative;
      padding: 2rem 1rem;

      .image-select {
         display: block;
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background-color: rgba(0,0,0,0);
         opacity: 0;
         outline: none;
         cursor: pointer;
         z-index: 2;
      }
   }

   #source-image,
   #source-image-scale,
   #source-image-mask,
   #source-image-mask-target,
   #source-image-mask-openai,
   #source-image-variations {
      max-height: 300px;
      width: auto;
      display: none;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1rem;
   }
}

.multi-prompt-input {

   .form-control {
      height: 48px;
      line-height: 48px;
      padding: 0 20px;
      background-color: #FFF !important;
      border-radius: 50vh;
      border-color: #f5f9fc;
      width: 100%;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
   }

   .delete-prompt-input {
      &:hover, &:focus {
         color: red;
      }
   }
}

/* ==========================================================================           
 *
 *    v3.4 AI Article Wizard Styles
 *
 * ========================================================================== */
 .wizard-nav {
    .wizard-nav-inner {
      background-color: #fff;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      padding: 10px;
      border-radius: 50vh;

      .wizard-nav-text {
        justify-content: center;
        align-items: center;

        .wizard-step-number {
          text-align: center;
          line-height: 35px;
          height: 35px;
          width: 35px;
          clear: both;
          display: block;
          border-radius: 50%;
          position: relative;
          box-shadow: 0 1px 3px rgba(69,79,91,.2);
        }
  
        .wizard-step-title {
          line-height: 1.4;
  
          .wizard-step-title-number {
            opacity: 0.6;
          }
        }
      }

      .current-step {
        background: $primaryTextActiveColor;
        color: #FFF;
      }

      .wizard-nav-chevron {
        position: absolute;
        right: 0;
        top: 35%;
        font-size: 10px;
        opacity: 0.4;
      }
    }
 }

 #wizard-advanced {
   a {
      text-transform: none;

      &:hover {
         color: $primaryTextActiveColor!important;
      }
   }
 }

 #wizard-advanced-wrapper {
   display: none;
 }

 #next-step {
    i {
      margin-left: 0.25rem;
      font-size: 12px;
      vertical-align: middle;
    }
 }

 .btn-keywords {
    min-width: 80px;
    border-radius: 35px;
    color: #fff !important;
    background-color: #1e1e2d;
    border-color: #1e1e2d;
    transition: all 0.2s ease;
    width: 175px;
    margin-bottom: 1.3rem;
    box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);

    &:hover {
      background-color: $primaryTextActiveColor;
      border-color: $primaryTextActiveColor;
    }

    &:focus {
      box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
    }
}

#generate-ideas,
#next-step,
#generate-keywords,
#generate-outlines,
#generate-images,
#skip-step {
  width: 200px;
  text-transform: none;

  &:focus {
    box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  }
}

#skip-step {
  background-color: #1e1e2d;
  border-color: #1e1e2d;
  transition: all 0.5s ease;
  &:hover {
    background-color: #FFF;
    border-color: #FFF;
    color: #1e1e2d!important;
  }
}

#new-wizard-large {
  text-transform: none;

  &:focus {
    box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  }
}

#generate-points {
  text-transform: none;
  min-width: 200px;

  &:focus {
    box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  }
}

#wizard-results-wrapper {
  margin-left: 1.5rem;

  .wizard-step-number {
    text-align: center;
    line-height: 23px;
    height: 25px;
    width: 25px;
    clear: both;
    display: block;
    border-radius: 50%;
    position: relative;
    border: 1px solid $primaryTextActiveColor;
  }
}

.keywords-title  {
  margin-bottom: 1rem;
  margin-top: 0.6rem;
}

.keywords-actions {
  margin-top: -0.4rem;
}

#keywords-wrapper {
  display: flex;
  flex-wrap: wrap;

  input.checkbox-btn {
    display: none;
  }

  input.checkbox-btn + label {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background: #FFF;
    padding: 5px 15px;
    font-size: 12px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    text-transform: lowercase;
    cursor: pointer;
    border-radius: 50vh;
    transition: all 0.3s ease;
  }
  input.checkbox-btn:not(:checked) + label:hover {
    background: #E1F0FF;
    box-shadow: none;
  }
  input.checkbox-btn + label:active,
  input.checkbox-btn:checked + label {
    background: #E1F0FF
  }
}

#ideas-wrapper {
  margin-top: 1rem;

  input.radio-btn {
    display: none;
  }

  input.radio-btn + label {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background: #FFF;
    padding: 1rem;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 1rem;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    border: 1px solid #fff;
  }
  input.radio-btn:not(:checked) + label:hover {
    box-shadow: none;
    .idea-icon {
      opacity: 1;
      color: $primaryTextActiveColor;
    }
  }
  input.radio-btn + label:active,
  input.radio-btn:checked + label {
    border: 1px solid $primaryTextActiveColor;
    .idea-icon {
      opacity: 1;
      color: $primaryTextActiveColor;
    }
  }

  .idea-icon {
    font-size: 25px;
    opacity: 0.2;
    color: #728096;
    margin-right: 0.8rem;
    transition: all 0.3s ease;
  }
}

.current-sign {
  color: $primaryTextActiveColor;
  opacity: 1!important;
}

#outlines-wrapper {
  margin-top: 1rem;

  input.outline-btn {
    display: none;
  }

  input.outline-btn + label {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background: #FFF;
    margin-bottom: 1rem;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s ease;
    border: 1px solid #FFF;
    width: 100%;
  }

  .outline {
    padding: 1.5rem;
    padding-left: 2.5rem;
    font-size: 14px;
    list-style-type: square;
    
    li {
      margin-bottom: 0.3rem;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  input.outline-btn:not(:checked) + label:hover {
    box-shadow: none;
    border: 1px solid $primaryTextActiveColor;
  }
  input.outline-btn + label:active,
  input.outline-btn:checked + label {
    border: 1px solid $primaryTextActiveColor;
  }
}

.outlines-outer {
  align-items: center;
}

.cursor-grab {
  cursor: grab;
}

#add-new-section {
  &:hover {
    color: $primaryTextActiveColor!important;
  }
}

.remove-section {
  &:hover {
    color: red!important;
  }
}

#outline-results-list {
  li:last-child {
    margin-bottom: 0!important;
  }
}

.talking-point-result-input {
  background-color: transparent!important;
}

.outline-item {
  .outline-item-close {
    opacity: 0;
  }

  &:hover {
    .outline-item-close {
      opacity: 1;
    }
  }
}

#images-wrapper {

  input.image-btn {
    display: none;
  }

  input.image-btn + label {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin-bottom: 1.5rem;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s ease;
    overflow: hidden;
  }

  input.image-btn:not(:checked) + label:hover {
    box-shadow: none;
    border: 2px solid $primaryTextActiveColor;
    cursor: pointer;
  }
  input.image-btn + label:active,
  input.image-btn:checked + label {
    border: 2px solid $primaryTextActiveColor;
  }
}

.add-new-talking-point {
  &:hover {
    color: $primaryTextActiveColor!important;
  }
}

#new-wizard {
  position: absolute;
  right: -3rem;
  top: 17%;

  i {
    width: 40px;
    height: 40px;
    background: #FFF;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
}

#final-wrapper {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 1.5rem 2.5rem;
  margin-top: 1rem;
  font-size: 13px;

  h3 {
    font-size: 16px;
    font-weight: 600;
  }

}

.wizard-icon {
  font-size: 16px;
  padding: 7px;
  background: rgb(235, 232, 255);
  color: rgb(81, 56, 238);
  border-radius: 5px;
}

.category-wizard {
  background: rgb(235, 232, 255);
  color: rgb(81, 56, 238);
}



/* ==========================================================================           
 *
 *    Material Effect for Buttons
 *
 * ========================================================================== */

.ripple {
  	position:relative;
  	overflow:hidden;
  	-webkit-transition: all 0.2s ease;
  	-moz-transition: all 0.2s ease;
  	-o-transition: all 0.2s ease;
  	transition: all 0.2s ease;
}

.ink {
  	display: block;
  	position: absolute;
  	background:rgba(255, 255, 255, 0.3);
  	border-radius: 100%;
  	-webkit-transform:scale(0);
     -moz-transform:scale(0);
       -o-transform:scale(0);
          transform:scale(0);
}

.ink.animate {
	-webkit-animation:ripple 0.65s linear;
   	-moz-animation:ripple 0.65s linear;
    -ms-animation:ripple 0.65s linear;
     -o-animation:ripple 0.65s linear;
        animation:ripple 0.65s linear;
}


@-webkit-keyframes ripple {
  100% {opacity: 0; -webkit-transform: scale(2.5);}
}
@-moz-keyframes ripple {
  100% {opacity: 0; -moz-transform: scale(2.5);}
}
@-o-keyframes ripple {
  100% {opacity: 0; -o-transform: scale(2.5);}
}
@keyframes ripple {
  100% {opacity: 0; transform: scale(2.5);}
}


/* ==========================================================================           
 *
 *    v3.5 AI Vision
 *
 * ========================================================================== */
 
 .image-drop-area {
    .msg {
      justify-content: center;
    }

    .main-image-input {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0);
      opacity: 0;
      outline: none;
      cursor: pointer;
      z-index: 2;
    }

    #main_image_preview {
      margin-top: 1rem;
      max-height: 150px;
      border-radius: 10px;
    }
 }

 .side-menu__new {
    background: #E1F0FF;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: $primaryTextActiveColor;
 }

 .left-msg {
    .msg-text {
      table {
        border: 1px solid #728096;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        thead {
          tr {
            th {
              padding: 0.4rem;
              border: 1px solid #728096;
            }
          }
        }

        tbody {
          tr {
            border: 1px solid #728096;

            td {
              padding: 0.4rem;
              border: 1px solid #728096;
            }
          }
        }
      }
    }
 }

 .chat-container {
    position: relative;
 }

 #generating-status {
  //left: 49%;
}

.upload-button-responsive {
  display: none;
}

/* ==========================================================================           
 *
 *    v3.7 Update
 *
 * ========================================================================== */
 
 .referral-edit-small {
    position: absolute;
    right: 2rem;
    background: #E1F0FF;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 10px;
    color: $primaryTextActiveColor;

    &:hover {
      background: #D1D3E0;
      color: #1e1e2d;
    }
 }

 .referral-body-bg {
    background-image: url(/img/files/waves.png);
    background-size: cover;
 }

 .referral-social-icons {
  .form-control {
    background-color: #FFF;
  }
 }

 #invite-friends-button {
   border-top-right-radius: 0.5rem!important;
   border-bottom-right-radius: 0.5rem!important;
 }

 .category-ad {
    background: #FFE2E5;
    color: #ff0000;
 }

 .category-ecommerce {
  background: #E1F0FF;
  color: #007bff;
 }

 .category-marketing {
  background: #FFE2E5;
    color: #ff0000;
 }

 .category-framework {
  background: #cde4e8;
  color: #087990;
 }

 .message-bubble {
	.listen {
		display: none;
		position: absolute;
		right: 50px;
		background: #fff;
		padding: 10px;
		border-radius: 50%;
		bottom: -15px;
		box-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
		transition: all 1s ease;
	 }

   .download {
      display: none;
      position: absolute;
      right: 20px;
      background: #fff;
      padding: 10px;
      border-radius: 50%;
      top: 25px;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
  }

  &:hover, &:focus {
		.listen {
			display: block;
			transition: all 1s ease;
		}

    .download {
      display: block;
			transition: all 1s ease;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.5), 0 4px 6px -4px rgba(0, 0, 0, 0.5);
      
      &:hover {
        background: #1e1e2d;
        color: #007bff;
      }
    }
	 }
 }

 .left-msg {
    .images-wrapper {
      width: 400px;
    }
 }

/* ==========================================================================           
 *
 *    v3.8 Update
 *
 * ========================================================================== */
 
 .chat-sidebar-container-special {
	width: 400px!important;
  }
 
  #url-process-button {
   position: absolute;
   right: 7px;
   top: 8px;
   font-size: 10px;
   user-select: none;
   background-color: $btnPrimaryBGColor;
   color: #FFF;
   border: 1px solid transparent;
   padding: 0.3rem 0.75rem;
   border-radius: 0.8rem;
   transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
 
   &:hover {
	 background-color: #1e1e2d;
   }
  }
 
  #url-link {
   border-radius: 1rem;
  }
 
  #upload-csv-button,
  #upload-pdf-button {
   text-transform: none!important;
  }
 
  .chat-small {
   width: 220px!important;
  }
 
 .block-display {
   display: none!important;
  }
 
  .chat-title-special {
   width: 290px!important;
  }
 
  .chat-sidebar-message {
   overflow: hidden;
  }
 
  .chat-url {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   display: block;
   width: 290px;
   z-index: 10;
 
   &:hover {
	 color: #007bff!important;
   }
  }
 
  .loading {
   display: inline-flex;
   align-items: center;
 }
 
 .loading .spacer {
   margin-right: 2px;
 }
 
 .loading span {
   animation-name: blink;
   animation-duration: 1.4s;
   animation-iteration-count: infinite;
   animation-fill-mode: both;
   width: 5px;
   height: 5px;
   border-radius: 50%;
   display: inline-block;
   margin: 0 1px;
 }
 
 .loading span:nth-of-type(2) {
   animation-delay: 0.2s;
 }
 
 .loading span:nth-of-type(3) {
   animation-delay: 0.4s;
 }
 
 @keyframes blink {
   0% {
	   opacity: 0.2;
   }
   20% {
	   opacity: 1;
   }
   100% {
	   opacity: 0.2;
   }
 }
 
 .btn-keywords {
   transition: all .3s;
 
   &:hover {
	 transform: translateY(-3px);
	 box-shadow: 0 0.5rem 1rem rgba(29, 39, 59, 0.25);
   }
 }
 
 .file-drop-border {
   border: 1px dashed #c5c5d2;
   padding-top: 1rem;
   border-radius: 0.5rem;
 }

 /* ==========================================================================           
 *
 *    v4.0 Update
 *
 * ========================================================================== */
 .smart-main-page {
	background-color: #FFF;
   }
   
   .smart-header {
	  position: fixed;
	  left: 0;
	  right: 0;
	  top: 0;
	  width: 100%;
	  display: flex;
	  z-index: 999;
	  padding-right: 15px;
	  padding: 0.4rem 0;
	  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	  max-height: 60px!important;
	  background: #2b2d31;
  
	  .desktop-lgo {
		width: 150px!important;
	  }
  
	  .profile-dropdown img {
		max-width: 40px;
		max-height: 40px !important;
		border: 1px solid #2b2d31;
	  }
  
	  #balance {
		margin-top: auto;
		margin-bottom: auto;
		max-width: 170px!important;
		width: 100%;
	  }
  
	  .go-back {
		margin-top: auto;
		margin-bottom: auto;
		min-width: 200px;
  
		a {
		  padding: 5px 15px;
		  border-radius: 5px;
  
		  &:hover {
			color: $primaryTextActiveColor!important;
		  }
		}
	  }
   }
  
  // .smart-article-wrapper {
  //   padding-top: 58px;
  //   max-height: 100vh;
  //   background: #FFF;
  //   overflow: hidden;
  
	#main-templates-container {
	  top: 60px;
	  box-sizing: border-box;
	  display: flex;
	  flex-direction: column;
	  border-right: 1px solid #ebecf1;
	  position: fixed;
	  transition: width 0.05s ease;
	  width: 20%;
	  overflow-y: scroll;
	  left: 0;
  
  
	  .main-templates-title {
		padding: 1.2rem 1.7rem 0;
	  }
  
	  .chat-sidebar-search {
		border-bottom: none;
	  }
  
	  .templates-wrapper {
		flex: 1 1;
		height: 100%;
		overflow-x: hidden;
		padding: 20px;
  
		.template-box a {
		  display: flex;
		  flex-direction: row;
		  padding: 10px;
		  align-items: center;
		  border-radius: 6px;
		  transition: all 0.3s ease;
		  border: 1px solid rgba(203, 213, 224, 0.7);
		  margin-bottom: 1rem;
		  cursor: pointer;
		  width: 100%;
		  height: 100%;
		  position: relative;
  
		  &:hover {
			background: #F5F9FC;
			color: $primaryTextActiveColor;
			border-color: $primaryTextActiveColor;
		  }
  
		  .fa-star {
			font-size: 8px;
			top: 5px;
			position: absolute;
			padding: 3px;
			background: #FFF;
			border-radius: 15px;
			left: 0.4rem;
			color: #FF9D00;
		  }
		}
  
		.btn-package {
		  line-height: 1;
		  padding-left: 12px;
		  padding-right: 12px;
		  border-radius: 30px;
		  color: #fff !important;
		  box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		  position: absolute;
		  right: 10px;
		  z-index: 10;
		}
  
		.btn-free {
		  background-color: #1e1e2d;
		  border-color: #1e1e2d;
		}
  
		.btn-premium {
		  background-color: #AB54EB !important;
		  border-color: #AB54EB !important;
		}
  
		.btn-new {
		  background-color: #007BFF;
		  border-color: #007BFF;
		}
  
		.btn-pro {
		  background-color: #FF9D00;
		  border-color: #FF9D00;
		}
	  }
	}
  
	.smart-article-editor {
	  height: 100vh!important;
	  margin-top: 56px;
  
	  .tox:not(.tox-tinymce-inline) .tox-editor-header {
		box-shadow: none;
	  }
	}
  
	#meta-container-editor {
	  top: 60px;
	  box-sizing: border-box;
	  display: flex;
	  flex-direction: column;
	  border-left: 1px solid #ebecf1;
	  position: fixed;
	  transition: width 0.05s ease;
	  width: 20%;
	  overflow-y: scroll;
	  height: 100%;
	  background-color: #FFF;
	  right: 0;
	  padding: 2rem;
	  z-index: 10;
  
	  #save-button {
		font-family: "Poppins", sans-serif;
		font-size: 12px;
		color: #1e1e2d;
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: none;
		appearance: none;
		background-color: $primaryTextActiveColor;
		text-transform: none;
		border-color: transparent;
		border-radius: 0.5rem;
		border-width: 1px;
		font-weight: 400;
		line-height: 1rem;
		padding: 0.75rem 1rem;
		width: 100%;
  
		&:hover {
		  background-color: #1e1e2d;
		}
	  }
  
	  .btn-group {
		
		.dropdown-toggle::after {
		  position: absolute;
		  right: 1.5rem;
		  top: 1.1rem;
		}
  
		.dropdown-menu {
		  width: 100%;
		  text-align: center;
		  margin-top: 5px;
		  border-color: rgba(203, 213, 224, 0.7);
		  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
		  top: 0px; 
		  left: 0px; 
		  transform: translate3d(0px, 42px, 0px);
		  font-size: 12px;
		  cursor: pointer;
		  padding: 0;
		  border-radius: 7px;
  
		  .dropdown-item {
			&:hover{
			  i {
				color: $primaryTextActiveColor!important;
			  }
			}
		  }
		  
		}
	  }
  
	  #export {
		width: 100%;
		border: 1px solid rgba(203, 213, 224, 0.7);
		padding: 11px 16px;
		border-radius: 0.5rem;
		font-size: 12px;
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: none!important;
		appearance: none!important;
		box-shadow: none!important;
  
		&:hover {
		  background: #F5F9FC;
		  outline: none;
		  appearance: none;
		}
	  }
  
	  #wordpress {
		font-family: "Poppins", sans-serif;
		font-size: 12px;
		color: #212529!important;
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: none;
		appearance: none;
		background-color: #FFF;
		text-transform: none;
		border-color: transparent;
		border-radius: 0.5rem;
		border-width: 1px;
		font-weight: 400;
		line-height: 1rem;
		border: 1px solid rgba(203, 213, 224, 0.7);
		padding: 0.75rem 1rem;
		width: 100%;
		box-shadow: none;
  
		&:hover {
		  background-color: #1e1e2d;
		  color: #FFF!important;
		  border: 1px solid #1e1e2d;
		  box-shadow: 0 0.5rem 1rem rgba(29, 39, 59, 0.25);
		}
  
	  }
  
	  .dropdown-toggle:focus {
		outline: 0 !important;
	  }
  
	  .dropdown-toggle.show > .dropdown-toggle {
		background: #F5F9FC;
		box-shadow: none!important;
	  }
	}
  //}
  
  .smart-article-editor {
	.tox-tinymce {
	  border: none!important;
	  border-radius: 0!important;
	  height: 100%!important;
	  position: fixed;
	}
	
	.tox .tox-toolbar__primary {
	  border-bottom: 0 !important;
	  justify-content: center!important;
	  position: static;
	}
	
	.tox-edit-area {
	  margin: 60px 60px!important;
	  transition: all 0.3s ease!important;
	  line-height: 1.4!important;
	}
  }
  
  #single-templates-container {
	padding: 20px 20px 20px 20px;
  
	.single-template-back {
	  margin-left: auto;
  
	  &:hover {
		a {
		  color: $primaryTextActiveColor!important;
		}
  
		i {
		  color: #1e1e2d!important;
		}
	  }
  
	}
  
	.star {
	  font-size: 14px;
	  color: #FF9D00;
	  cursor: pointer;
	}
  
	.single-templates {
	  .nav-link.dropdown-toggle {
		border: 1px solid rgba(203, 213, 224, 0.7);
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		padding: 10px 24px;
  
		h6 {
		  margin-top: auto;
		  margin-bottom: auto;
		}
	  }
  
	  .dropdown-toggle::after {
		position: absolute;
		right: 1.5rem;
	  }
  
	  .dropdown .dropdown-menu {
		left: 0 !important;
		transform: none !important;
		max-height: 450px !important;
		top: 60px !important;
		border: 1px solid rgba(203, 213, 224, 0.7) !important;
		border-radius: 0.5rem;
		overflow-y: scroll !important;
		padding: 0 0 !important;
		width: 100%;
		padding-left: 4px!important;
		overflow-x: hidden;
	  }
  
	  .dropdown-item {
		padding-top: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid rgba(203, 213, 224, 0.7) !important;
		position: relative;
  
		h6 {
		  margin-top: auto;
		  margin-bottom: auto;
		}
  
		.fa-star {
		  font-size: 8px;
		  top: 5px;
		  position: absolute;
		  padding: 3px;
		  background: #FFF;
		  border-radius: 15px;
		  left: 1.3rem;
		  color: #FF9D00;
		}
		
	  }
  
	  .dropdown-item:last-child {
		border-bottom: none!important;
	  }
  
	  .btn-package {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 30px;
		color: #fff !important;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		position: absolute;
		right: 10px;
		z-index: 10;
		top: 13px;
	  }
  
	  .btn-free {
		background-color: #1e1e2d;
		border-color: #1e1e2d;
	  }
  
	  .btn-premium {
		background-color: #AB54EB !important;
		border-color: #AB54EB !important;
	  }
  
	  .btn-new {
		background-color: #007BFF;
		border-color: #007BFF;
	  }
  
	  .btn-pro {
		background-color: #FF9D00;
		border-color: #FF9D00;
	  }
  
	}
  
	#single-template-view {
  
	  input, 
	  textarea {
		border: 1px solid rgba(203, 213, 224, 0.7);
  
		&:hover, &:focus {
		  border-color: $primaryTextActiveColor;
		}
	  }
  
	  .text-required {
		position: relative!important;
		top: -2px!important;
	  }
  
	  #generate {
		font-family: "Poppins", sans-serif;
		font-size: 12px;
		color: #1e1e2d;
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: none;
		appearance: none;
		background-color: $primaryTextActiveColor;
		text-transform: none;
		border-color: transparent;
		border-radius: 0.5rem;
		border-width: 1px;
		font-weight: 400;
		line-height: 1rem;
		padding: 0.75rem 1rem;
		width: 100%;
  
		&:hover {
		  background-color: #1e1e2d;
		}
	  }
	}
  } 
  
  .hidden {
	display: none!important;
  }
  
  #loader-line {
	width: 100%;
	height: 3px;
	position: fixed;
	overflow: hidden;
	background-color: transparent;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999999;
  }
  
  #loader-line:before {
	content: "";
	position: absolute;
	left: -50%;
	height: 3px;
	width: 5%;
	background: #007bff;
	-webkit-animation: lineAnim 1s linear infinite;
	-moz-animation: lineAnim 1s linear infinite;
	animation: lineAnim 1s linear infinite;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	z-index: 10000000;
  }
  
  @keyframes lineAnim {
	0% {
		left: -40%;
	}
	50% {
		left: 20%;
		width: 80%;
	}
	100% {
		left: 100%;
		width: 100%;
	}
  }
  
  .opacity-on {
	opacity: 0!important;
  }
  
  #template-textarea {
	.tox-tinymce {
	  border: none!important;
	  border-radius: 0!important;
	}
	
	.tox .tox-toolbar__primary {
	  border-bottom: 0 !important;
	}
  
	.tox:not(.tox-tinymce-inline) .tox-editor-header {
	  box-shadow: none;
	}
  
	.tox .tox-toolbar__group { 
	  padding: 0 15px 0 0px!important;
	}
  }
  
  
  div.tox .tox-menu.tox-collection.tox-collection--list {
	padding: 1rem!important;
  }
  
  div.tox .tox-menu.tox-collection.tox-collection--list .tox-collection__item {
	padding: 5px 12px;
	border-radius: 5px;
	cursor: pointer;
  }
  
  div.tox .tox-custom-label {
	font-size: 10px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: .05em;
	margin-bottom: 1em;
  }
  
  .tox .tox-tbtn--bespoke {
	background: #f5f9fc!important;
	border-radius: 7px!important;
  }
  
  #template-output {
	min-height: 730px;
  }
  
  .template-action-buttons {
	.btn {
	  padding: 0;
	}
  
	.dropdown-menu {
	  padding: 0;
	  font-size: 12px;
	  cursor: pointer;
	  border-radius: 7px;
	  margin-top: 3px;
	  -webkit-appearance: none;
	  -moz-appearance: none;
	  outline: none!important;
	  appearance: none!important;
	}
  
	.dropdown-toggle {
	  -webkit-appearance: none;
	  -moz-appearance: none;
	  outline: none!important;
	  appearance: none!important;
	}
  
	.dropdown-toggle:after {
	  display: none;
	}
  }
  
  #save-button-template {
	position: absolute;
	right: 9px;
  }
  
  .template-action-buttons {
	position: absolute;
	right: 3.6rem;
  }
  
  .rewriter-icon {
	font-size: 16px;
	  padding: 7px;
	  background: #D1D3E0;
	  color: #1e1e2d;
	  border-radius: 5px;
  }
  
  .category-rewriter {
	background: #D1D3E0;
	color: #1e1e2d;
  }
  
  .smart-icon {
	font-size: 16px;
	  padding: 7px;
	  background: #B2DFFF;
	color: #002651;
	  border-radius: 5px;
  }
  
  .category-editor {
	background: #B2DFFF;
	color: #002651;
  }
  
  #generate {
	text-transform: none;
  }
  
  .total-words-templates-box {
	position: absolute;
	bottom: 1rem;
	margin-bottom: 0;
  }
  
  .tox .tox-dialog__title {
	font-size: 14px!important;
	font-weight: bold!important;
  }
  
  .tox .tox-textfield {
	font-size: 14px!important;
	padding-left: 0.8rem!important;
  }
  
  .tox .tox-dialog__footer {
	padding-top: 0!important;
  }
  
  #show-left-menu {
	position: absolute;
	top: 50%;
	left: 3px;
	z-index: 100;
	color: $primaryTextActiveColor;
	background: #FFF;
	border-radius: 50%;
	font-size: 12px;
	width: 30px;
	height: 30px;
	z-index: 500;
	box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  
	i {
	  line-height: 2.5;
	  padding-left: 11px;
	}
  }
  
  #show-right-menu {
	position: absolute;
	top: 50%;
	right: 3px;
	z-index: 100;
	color: $primaryTextActiveColor;
	background: #FFF;
	border-radius: 50%;
	font-size: 12px;
	width: 30px;
	height: 30px;
	z-index: 500;
	box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
  
	i {
	  line-height: 2.5;
	  padding-left: 9px;
	}
  }
  
  #hide-left-menu {
	position: absolute;
	top: 0.7rem;
	right: 1.5rem;
	z-index: 100;
	color: $primaryTextActiveColor;
	background: #FFF;
	border-radius: 50%;
	font-size: 12px;
	width: 30px;
	height: 30px;
	z-index: 500;
	box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
	display: none;
  
	i {
	  line-height: 2.5;
	  padding-left: 9px;
	}
  }
  
  #hide-right-menu {
	position: absolute;
	top: 1rem;
	right: 1.5rem;
	z-index: 100;
	color: $primaryTextActiveColor;
	background: #FFF;
	border-radius: 50%;
	font-size: 12px;
	width: 30px;
	height: 30px;
	z-index: 500;
	box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
	display: none;
  
	i {
	  line-height: 2.5;
	  padding-left: 9px;
	}
  }
  
  #show-left-menu,
  #show-right-menu {
	  display: none;
  }
  
  .show-menu-width {
	width: 100%!important;
	display: fixed!important;
  }
  
  #meta-container-editor {
	z-index: 500;
  }

  /* ==========================================================================           
 *
 *    v4.2 Update
 *
 * ========================================================================== */
 .view-action-button,
 .delete-action-button,
 .edit-action-button,
 .table-action-buttons {
  transition: all .3s;

  &:hover {
    transform: translateY(-3px);
	  box-shadow: 0 0.5rem 1rem rgba(29, 39, 59, 0.15);
  }
 }

 .download-action-button {
  transition: all .3s;
  &:hover {
    color: #1e1e2d;
    transform: translateY(-3px);
	  box-shadow: 0 0.5rem 1rem rgba(29, 39, 59, 0.15);
  }
 }

 .green-audio-player .holder .play-pause-btn {
  margin-bottom: 1.5rem;
 }

 .sample-audio-player {
  position: absolute;
    right: 0;
    top: -5px;

    i {
      font-size: 10px;
      width: 25px;
      height: 25px;
    }
 }

 .custom-voice-select {
  .nav-link.dropdown-toggle h6 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .nav-link.dropdown-toggle {
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    padding: 10px 24px;
    height: 42px;
    background: #f5f9fc;
    color: #1e1e2d;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 1.5rem;
  }

 .dropdown .dropdown-menu {
    left: 0 !important;
    transform: none !important;
    max-height: 450px !important;
    top: 52px !important;
    border: 1px solid rgba(203, 213, 224, 0.7) !important;
    border-radius: 0.5rem;
    overflow-y: scroll !important;
    padding: 0 0 !important;
    width: 100%;
    padding-left: 4px !important;
  }
 }

.video-settings-wrapper {
 .range {
    position: relative;
    display: flex;
    -ms-align-items: center;
    align-items: center;
  }
.range .range_in {
    position: relative;
    width: 100%;
  }
   .range .range_in input {
    display: block;
    height: 4px;
    border-radius: 5px;
    -webkit-appearance: none;
    width: 100%;
    margin: 0;
    outline: 0;
    border: none;
    background-color: #f5f9fc;
  }
.range .range_in .slider {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
  pointer-events: none;
  background: $primaryTextActiveColor;
  }
 .range .range_in .slider:before {
    width: 14px;
    height: 14px;
    background: $primaryTextActiveColor;
    cursor: pointer;
    border-radius: 100%;
    position: absolute;
    display: block;
    right: 0;
    top: 50%;
    margin-top: -7px;
    content: "";
    z-index: 2;
  }
.range .range_in .slider:after {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  content: "";
  border-radius: 5px 0 0 5px;
  }
 .range .range_in .slider, #image-settings-wrapper .image-settings .range .range_in input {
    display: block;
    height: 4px;
    border-radius: 5px;
  }
 .range .range_in input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    background: $primaryTextActiveColor;
    cursor: pointer;
    border-radius: 100%;
    opacity: 0;
  }
 .range .range_in input::-moz-range-thumb {
    width: 14px;
    height: 14px;
    background: $primaryTextActiveColor;
    cursor: pointer;
    border-radius: 100%;
    z-index: 3;
    opacity: 0;
  }
.range .value {
    flex: auto;
    width: 40px;
    text-align: right;
    padding-left: 10px;
    font-size: 12px;
  }
}

#image-drop-box {
  #source-image-variations {
    max-height: 300px;
    width: auto;
    display: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;

  }
}

#video-image-counter {
  p {
    position: absolute;
    right: 2rem;
  }
}

.video-processing {
  background: #D1D3E0;
  color: #1e1e2d;
}

.video-completed {
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
}

.video-failed {
  background: #FFE2E5;
    color: #ff0000;
}

.video-image-view img {
  max-width: 150px;
  border-radius: 10px;
}

.voice-custom {
  background: rgba(0, 188, 126, 0.1);
  color: #00bc7e;
}

/* ==========================================================================           
 *
 *    v4.4 Update
 *
 * ========================================================================== */
 #status-bar {
	height: 10px;
   }
  
   #hundred-percent {
	  position: absolute;
	  font-size: 10px;
	  right: 0;
	  margin-top: 0.5rem;
   }
  
   #zero-percent {
	  margin-top: 0.5rem;
	  position: absolute;
	  font-size: 10px;
   }
  
   #refresh-button {
	  position: absolute;
	  right: 1.5rem;
  
	  i {
		  font-size: 12px;
		line-height: 2.5;
	  }
  }
  
  #create-ai-button {
	 position: absolute;
	 right: 1rem;
	 top: 2rem;
  }
  
  .custom-switch {
	 cursor: pointer;
  }

  /* ==========================================================================           
 *
 *    v4.5 Update
 *
 * ========================================================================== */
 .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered {
	flex-wrap: nowrap;
	width: 100%;
	 font-size: 12px;
	 background-color: #f5f9fc;
	 color: #000;
	 font-family: "Poppins", sans-serif;
	 line-height: 1rem;
	 padding: 0.75rem 1rem;
	 border-radius: 0.5rem;
	 border-color: transparent;
	 background-size: 10px 10px;
  }
 
  .select2-container--bootstrap-5 .select2--small.select2-selection {
	padding: 0;
	border: none;
  }
 
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
   margin-bottom: 0;
  }
 
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
  .select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-results__options .select2-results__option, .select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-search .select2-search__field {
   font-size: 12px;
  }
 
  .product-field {
   border: 1px solid #ebecf1;
	 padding: 1rem;
	 border-radius: 1rem;
  }
 
  .brand-details {
   display: none;
  }

  /* ==========================================================================           
 *
 *    v4.8 Update
 *
 * ========================================================================== */
 .registration-nav {
	.registration-nav-inner {
	  padding: 10px;
	  border-radius: 50vh;
  
	  .wizard-nav-text {
		justify-content: center;
		align-items: center;
  
		.wizard-step-number {
		  text-align: center;
		  line-height: 35px;
		  height: 35px;
		  width: 35px;
		  clear: both;
		  display: block;
		  border-radius: 50%;
		  position: relative;
		  box-shadow: 0 1px 3px rgba(69,79,91,.2);
		}
  
		.wizard-step-title {
		  line-height: 1.4;
  
		  .wizard-step-title-number {
			opacity: 0.6;
		  }
		}
	  }
  
	  .current-step {
		background: limegreen;
		color: #FFF;
	  }
  
	  .current-sign {
		color: limegreen;
	  }
  
	  .wizard-nav-chevron {
		position: absolute;
		right: 0;
		top: 35%;
		font-size: 10px;
		opacity: 0.4;
	  }
	}
  }
  
  .register-logo {
	position: absolute;
	top: 0.5rem;
	left: 0;
  }
  
  .register-continue-button {
	min-width: 200px;
  }
  
  .white-background {
	background-color: #FFF;
  }
  
  .text-required-register {
	  font-size: 8px;
	  position: absolute;
	  margin-left: 2px;
	  color: red;
  }
  
  #registration-prices,
  #registration-form,
  #payment {
	background-image: url(/img/frontend/backgrounds/pattern.svg);
	background-position: top center;
	background-repeat: no-repeat;
  }
  
  .payment-image-iyzico {
	width: 50%;
  }
  
  .payment-image-paddle {
	width: 60%;
  }
  
  .payment-image-flutterwave {
	width: 70%;
  }

  
  /* ==========================================================================           
 *
 *    v4.9 Update
 *
 * ========================================================================== */
 .prompts {
    position: absolute;
    left: 1rem;
    padding-top: 5px;

    &:hover {
      color: $primaryTextActiveColor!important;
    }
 }

 #features-wrapper .features-nav-menu .features-nav-menu-inner .nav-tabs {
  white-space: nowrap !important;
  border-bottom: none;
  justify-content: space-around;
  display: flex;
  flex-wrap: nowrap !important;
  overflow: overlay;
}

.features-nav-menu-inner {
  width: auto;
}

#features-wrapper .features-nav-menu .features-nav-menu-inner .nav-tabs .nav-link {
  margin-right: 5px;
  margin-bottom: 1rem;
}

#features-wrapper .features-nav-menu .features-nav-menu-inner .nav-tabs .nav-link.active {
  box-shadow: 0 10px 25px -12px rgba(0, 0, 0, 0.1);
}

.shadow-0 {
  box-shadow: none;
  border-color: #dbe2ebb0;
}

.btn-primary-chat {
  border: 1px solid #ebecf1;
  min-width: 120px;
  margin-right: 10px;
  border-radius: 5px;
  outline:none;
  

  &:hover, &:focus {
    border-color: $primaryTextActiveColor;
    i, span, svg {
      color: $primaryTextActiveColor;
    }
  }
}

.chat-controllers .chat-button-icon {
  background-color: #ebeff5a6;
  color: #728096;

  &:hover, &:focus {
    background-color: #E1F0FF;
    color: #007BFF;
  }
}


label.model-box {
  margin-bottom: 1rem;
  width: 150px;
  height: 100px;
  line-height: 90px;
  border: 1px solid #ebecf1;

  &:hover, &:focus {
    border: 1px solid $primaryTextActiveColor;
  }

  .active, .focus {
    border: 1px solid $primaryTextActiveColor!important;
  }
}

label.btn:focus, label.btn.focus, label.btn.active {
  border: 1px solid $primaryTextActiveColor!important;
}

#subscription-header {
  background-image: url(/img/files/waves.png);
  background-size: cover;
  background-position: top;

  #subscription-header-inner {
    min-height: 200px;
  }

  .cancel-subscription {
    background: #728096;
    padding: 5px 1rem;
    border-radius: 1rem;
    color: #fff;

    &:hover {
      background-color: #000;
    }
  }
}

.prepaid-view-box {
  border: 1px solid rgba(219, 226, 235, 0.6901960784);
  border-radius: 5px;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
}

.chat-model-box {
  // display: flex;
  // flex-flow: row wrap;
  
  div {
    // flex: 1;
    padding: 0.5rem;
  }
  
  input[type=radio] {
    display: none;
  }

  input[type=radio]:not(:disabled) ~ label {
    cursor: pointer;
  }
  input[type=radio]:disabled ~ label {
    color: #bcc2bf!important;
    box-shadow: none;
    cursor: not-allowed;

    p {
      color: #bcc2bf!important;
    }
  }
  
  label {
    height: 100%;
    display: block;
    border: 1px solid rgba(219, 226, 235, 0.6901960784);
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    position: relative;
  }
  
  input[type=radio]:checked + label {
    border-color: $primaryTextActiveColor;
    h6 {
      color: $primaryTextActiveColor;
    }
  }

  .action-button {
    color: $primaryTextActiveColor;

    &:hover {
      color: #000;
    }
  }
  
}

#chat-export-button {
  button {
    outline: none !important;
    appearance: none !important;
    box-shadow: none!important;
  }

  .dropdown-menu.show {
    border-radius: 7px;
    border-color: transparent;
  }

  .dropdown-item {
    font-size: 12px;
    padding: 4px 12px;
    cursor: pointer;

    &:hover {
      color: $primaryTextActiveColor;
    }
  }
}

/* ==========================================================================           
 *
 *    v5.2 Update
 *
 * ========================================================================== */
 .cms-box {
	border: 1px solid rgba(219, 226, 235, 0.6901960784);
	padding: 2rem;
	border-radius: 1rem;
  
	.cms-status {
	  position: absolute;
	  right: 2rem;
	  font-size: 10px;
	  top: 1rem;
	}
  
	.cms-image {
	  max-width: 9rem;
	}
  
	.cms-action {
	  text-transform: none;
	}
  
	.cms-deactive {
	  background: #FFE2E5; 
	  color: #ff0000;
	  padding: 3px 1rem;
	  border-radius: 1rem;
	}
  
	.cms-active {
	  background: rgba(0,188,126,.1);
	  color: #00bc7e;
	  padding: 3px 1rem;
	  border-radius: 1rem;
	}
  }
  
  .show-text-result {
	.tox .tox-editor-container {
	  margin-left: auto;
	  margin-right: auto;
	}
  }
  
  .publish-action-buttons {
	.dropdown-menu {
	  padding: 0;
	  font-size: 12px;
	  cursor: pointer;
	  border-radius: 7px;
	  margin-top: 3px;
	  -webkit-appearance: none;
	  -moz-appearance: none;
	  outline: none !important;
	  appearance: none !important;
	}
	
  }
  
  .side-plan-wrapper {
	margin-left: 1rem;
	margin-right: 1rem;
	padding: 5px;
	border: 1px solid rgba(235, 236, 241, .9);
	border-radius: 10px;
	span, a {
	  text-transform: none!important;
	  font-size: 11px!important;
	}
  
	.view-credits {
	  margin-top: -5px;
	  a {
		&:hover, &:focus {
		  color: $primaryTextActiveColor!important;
		}
	  }
	}
  
	#referral-button {
	  background-color: #FFF;
	  border-color: #FFF;
	  color: $primaryTextColor!important;
  
	  &:hover, &:focus {
		background-color: #000;
		border-color: #000;
		color: #FFF!important;
	  }
	}
  }
  
  #creditsModel {
	.modal-header {
	  border-bottom: 1px solid #ebecf1;
	  padding: 1rem 1.5rem;
	  
	  h6 {
		margin-top: auto;
		margin-bottom: auto;
	  }
	}
  }

  /* ==========================================================================           
 *
 *    v5.4 Update
 *
 * ========================================================================== */
 .custom-banner-bg {
	background: linear-gradient(15deg, #004999 26.3%, #007bff 86.4%);
	color: #FFF;
	border-radius: 8px;
   }
  
  .custom-banner-bg-image {
	background-image: url(/img/files/wave-line.png);
	background-size: cover;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	opacity: 0.4;
	background-position: top;
  }
  
  .custom-span {
	padding: 3px 10px;
	background: rgba(255, 255, 255, 0.15);
	border-radius: 9px;
	text-transform: uppercase;
  }
  
  .custom-pricing-plan-button {
	background-color: transparent; 
	box-shadow: none; 
	border-color: #FFF;
	text-transform: none;
	padding: 7px 20px;
  }
  
  .favorite-dashboard-panel {
	max-height: 400px;
	overflow-y: scroll;
  
	.chat-boxes-dasboard {
	  position: relative;
	  margin-top: -2rem;
	  cursor: pointer;
	  transition: all 0.2s ease-in-out;
	
	  a {
		position: relative;
		top: 45px;
		margin-right: 1rem;
		float: right;
		z-index: 200;
	
		i.star {
		  color: #FF9D00;
		}
	  }
	
	  .card {
		box-shadow: none;
		border-color: rgba(219, 226, 235, 0.6901960784);
	
		.widget-user-image {
		  margin: 0;
		  height: 45px;
		  width: 45px;
		}
	
		h6 {
		  line-height: 2;
		}
	
		&:hover, &:focus {
		  border-color: $primaryTextActiveColor;
		}
	  }
	
	  .package-badge {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		margin-top: 5px;
		margin-bottom: 6px;
	  }
	
	  .btn-premium {
		background-color: #AB54EB;
		border-color: #AB54EB;
	  }
	
	  .btn-pro {
		background-color: #007BFF;
		border-color: #007BFF;
	  }
	
	  .btn-free {
		background-color: #1e1e2d;
		border-color: #1e1e2d;
	  }
	}
  
	.template-dashboard {
	  position: relative;
	  margin-top: -2rem;
	  cursor: pointer;
	  transition: all 0.2s ease-in-out;
  
	  a {
		position: relative;
		top: 45px;
		margin-right: 1rem;
		float: right;
		z-index: 200;
	
		i.star {
		  color: #FF9D00;
		}
	  }
  
	  .card {
		box-shadow: none;
		border-color: rgba(219, 226, 235, 0.6901960784);
  
		&:hover, &:focus {
		  border-color: $primaryTextActiveColor;
		}
	  }
  
	  .package-badge {
		line-height: 1;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 35px;
		color: #fff !important;
		box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
		margin-top: 5px;
		margin-bottom: 6px;
	  }
	
	  .btn-premium {
		background-color: #AB54EB;
		border-color: #AB54EB;
	  }
	
	  .btn-pro {
		background-color: #007BFF;
		border-color: #007BFF;
	  }
	
	  .btn-free {
		background-color: #1e1e2d;
		border-color: #1e1e2d;
	  }
	}
	
  }
  
  .usage-icon-dashboard {
	font-size: 27px;
	padding-top: 0.5rem;
  }
  
  .dashboard-border-right {
	border-right: 1px solid rgba(219, 226, 235, 0.6901960784);
  }
  
  .user-dashboard-special-box {
	border: 1px solid rgba(219, 226, 235, 0.6901960784);
	&:hover {
	  box-shadow: 0 20px 25px -5px rgba(15, 23, 42, 0.1), 0 8px 10px -6px rgba(15, 23, 42, 0.1);
	  transition: all 0.3s ease;
	}
  }
  
  table.table-hover>tbody>tr:hover td,
  table.table-hover>tbody>tr:hover th {
	background-color: $body-background!important;
  }
  
  .dashboard-timeline {
	.vertical-timeline {
	  width: 100%;
	  position: relative;
	  padding: 1.5rem 0 1rem;
	}
  
	.vertical-timeline::before {
		content: '';
		position: absolute;
		top: 0;
		left: 87px;
		height: 100%;
		width: 4px;
		background: #e9ecef;
		border-radius: .25rem;
	}
  
	.vertical-timeline-element {
		position: relative;
		margin: 0 0 1rem;
	}
  
	.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
		visibility: visible;
		animation: cd-bounce-1 .8s;
	}
	.vertical-timeline-element-icon {
		position: absolute;
		top: 0;
		left: 80px;
	}
  
	.vertical-timeline-element-icon .badge-dot-xl {
		box-shadow: 0 0 0 5px #fff;
	}
  
	.badge-dot-xl {
		width: 18px;
		height: 18px;
		position: relative;
	}
	.badge:empty {
		display: none;
	}
  
  
	.badge-dot-xl::before {
		content: '';
		width: 10px;
		height: 10px;
		border-radius: .25rem;
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -5px 0 0 -5px;
		background: #fff;
	}
  
	.vertical-timeline-element-content {
		position: relative;
		margin-left: 115px;
		font-size: .8rem;
	}
  
	.vertical-timeline-element-content .timeline-title {
		font-size: .8rem;
		text-transform: uppercase;
		margin: 0 0 .5rem;
		padding: 2px 0 0;
		font-weight: bold;
	}
  
	.vertical-timeline-element-content .vertical-timeline-element-date {
		display: block;
		position: absolute;
		left: -117px;
		top: 0;
		padding-right: 10px;
		text-align: right;
		color: #adb5bd;
		font-size: .7619rem;
		white-space: nowrap;
	}
  
	.vertical-timeline-element-content:after {
		content: "";
		display: table;
		clear: both;
	}
  }
  
  .dashboard-panel-500 {
	max-height: 500px;
	overflow-y: scroll;
  }
  
  .dashboard-menu-button {
	position: absolute;
	right: 1rem;
	top: 1rem;
  
	.btn {
	  padding: 0;
	}
  
	.dropdown-menu {
	  padding: 0;
	  font-size: 12px;
	  cursor: pointer;
	  border-radius: 7px;
	  margin-top: 3px;
	  -webkit-appearance: none;
	  -moz-appearance: none;
	  outline: none!important;
	  appearance: none!important;
	}
  
	.dropdown-toggle {
	  -webkit-appearance: none;
	  -moz-appearance: none;
	  outline: none!important;
	  appearance: none!important;
	}
  
	.dropdown-toggle:after {
	  display: none;
	}
  }
  
  #admin-dashboard-panels {
	.widget-user-image-sm {
	  border-radius: 50%;
	}
  
	.card {
	  box-shadow: none;
	  border-color: rgba(219, 226, 235, 0.6901960784);
	  position: relative;
	  cursor: pointer;
	  transition: all 0.2s ease-in-out;
	  margin-bottom: 0.5rem;
  
	  &:hover, &:focus {
		border-color: $primaryTextActiveColor;
	  }
	}
  
	.dashboard-3-column {
	  display:flex;
	}
  
	.dashboard-3-column > *{
	  width:calc(100% / 3);
	}
  
	.dashboard-3-column > :first-child{
	  width:calc(100% / 2);
	}
  }
  
  .height-400 {
	max-height: 400px;
	overflow-y: scroll;
  }
  
  .dashboard-box-border-top {
	border-top: 1px solid rgba(219, 226, 235, 0.6901960784);
  }
  
  .dashboard-box-border-right {
	border-right: 1px solid rgba(219, 226, 235, 0.6901960784);
  }
  
  .dashboard-fixed-457 {
	min-height: 457px;
  }
  
  .dashboard-center-text {
	position: absolute;
	top: 38%;
	left: 41%;
  }
  
  .dashboard-flags {
	height: 30px;
	width: 30px;
	display: block;
	border-radius: 100%;
	box-shadow: 0 1px 3px 0 rgba(50, 50, 50, 0.2), 0 2px 1px -1px rgba(50, 50, 50, 0.12), 0 1px 1px 0 rgba(50, 50, 50, 0.14);
	position: relative;
  }
  
/* ==========================================================================           
 *
 *    v5.5 Update
 *
 * ========================================================================== */

 .photo-studio-tools .nav-link.dropdown-toggle {
	border: 1px solid rgba(203, 213, 224, 0.7);
	  border-radius: 0.5rem;
	  display: flex;
	  align-items: center;
	  padding: 15px 24px;
  
	  h6 {
		margin-top: auto;
		margin-bottom: auto;
	  }
  }
  
  .photo-studio-tools .dropdown-toggle::after {
	position: absolute;
	right: 1.5rem;
  }
  
  .photo-studio-tools .dropdown .dropdown-menu {
	left: 0 !important;
	  transform: none !important;
	  max-height: 450px !important;
	  top: 60px !important;
	  border: 1px solid rgba(203, 213, 224, 0.7) !important;
	  border-radius: 0.5rem;
	  overflow-y: scroll !important;
	  padding: 0 0 !important;
	  width: 100%;
	  padding-left: 4px !important;
	  overflow-x: hidden;
  }
  
  .photo-studio-tools .dropdown-item {
	padding-top: 12px;
	  padding-bottom: 12px;
	  border-bottom: 1px solid rgba(203, 213, 224, 0.7) !important;
	  position: relative;
  
	  h6 {
		margin-top: auto;
		margin-bottom: auto;
	  }
  }
  
  .photo-studio-upload {
	border: 1px dashed rgba(203, 213, 224, 0.7);
  }
  
  #photo-studio-placeholder {
	position: absolute;
	  top: 41%;
	  left: 41%;
	  font-size: 14px;
  }
  
  #photo-studio-result {
	img {
	  border-radius: 1%;
	}
  
	.download-image {
	  position: absolute;
	  background-color: rgba(0, 0, 0, 0.3607843137);
	  color: #fff;
	  width: 40px;
	  height: 40px;
	  border-radius: 5px;
	  line-height: 40px;
	  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	  left: 15px;
	  top: 15px;
	}
  
	.download-image:hover {
	  background: #0f0f11;
	  color: #007BFF;
	}
  }
  
  #source-image-variations-mask {
	display: none;
  }
  
  /* ==========================================================================           
 *
 *    v5.7 Update
 *
 * ========================================================================== */

.locale-action-button {
    position: absolute;
    right: 5px;
    top: 8px;
    min-width: 50px;
    text-transform: none;
    padding: 5px 10px;
}

.language-card {
    border-color: rgba(219, 226, 235, 0.6901960784);
    box-shadow: none;

    &:hover, &:focus {
        border-color: $primaryTextActiveColor;
    }
}

.language-action-buttons {
    background: none;
    transition: all 0.3s;
    border: none!important;
    outline: none;

    &:hover, &:focus {
        transform: translateY(-3px);
        box-shadow: 0 0.5rem 1rem rgba(29, 39, 59, 0.15);
    }
}

.language-ellipsis {
    top: 0.8rem;
}

.language-switch-checkbox {
    position: absolute;
    right: 3rem;
    top: 0.9rem;
}

.language-editor-box {
    box-shadow: none;
    border-color: rgba(219, 226, 235, 0.6901960784);

    .card-table {
        td {
            border-color: rgba(219, 226, 235, 0.6901960784);
        }
    }

    .card-table tr td:last-child, .card-table tr th:last-child {
        padding-right: 1rem;
    }

    .form-control {
        font-size: 12px;
        border-color: rgba(219, 226, 235, 0.6901960784);
    }
}

#search_string {
    border-color: rgba(219, 226, 235, 0.6901960784);
    padding: 1em;
    border-radius: 10px;
    &:hover, &:active, &:focus {
        border-color: $primaryTextActiveColor;
    }
}

.header-languages {
    .dropdown-menu {
        padding: 0;
    }

    .dropdown-menu.show  {
        left: -110px !important;
    }

    .dropdown-menu .dropdown-item {
        padding: 0.4rem 1.5rem;
    }
} 
  

/* ==========================================================================           
 *
 *    v6.0 Update
 *
 * ========================================================================== */

.cost-right-side {
    float: right;
    margin-right: 1rem;
}

.cost-sticky {
    position: sticky;
    top: 10rem;
}

.dashboard-4-column,
.dashboard-5-column {
    display: flex;
}

.dashboard-4-column > * {
    width: 25%;
}

.dashboard-5-column > * {
    width: 20%;
}

.dashboard-center-text-finance {
    position: absolute;
    top: 38%;
    left: 39%;
}
	  















 
  
	  







