/* ==========================================================================           
 *
 *    RTL
 *
 * ========================================================================== */

.rtl {
    .app-sidebar {
        right: 0;

        .side-menu {
            .side-menu__item  {
                padding: 2.5px 13px 3px 22px;
                margin: 0px 0px 0px 5px;

                .side-menu__icon {
                    margin-right: 0;
                }

                .fa-angle-right:before {
                    content: "\f104";
                }
            }

            .side-menu__label {
                text-align: right;
                margin-right: 0.5rem;
            }

            .side-item-category {
                text-align: right;
            }

            a:before {
                display: none;
            }

            a:after {
                display: none;
                // font: var(--fa-font-solid);
                // content: "\f100";
                // display: inline-block;
                // margin-right: 8px;
                // vertical-align: middle;
                // font-weight: 400;
                // opacity: 0.7;
                // font-size: 8px;
            }
        }
    }

    .app-header {
        padding-left: 0;

        .order-lg-2 {
            margin-left: unset!important;
            margin-right: auto;
        }
    }

    .app-content {
        margin-left: 0;
        margin-right: 250px;
        text-align: right;

        .app-sidebar__toggle a {
            display: none;
        }

        .card-header {
            .card-title {
                i {
                    margin-right: 0!important;
                    margin-left: 0.5rem;
                }
            }
        }

        .dashboard-menu-button {
            left: 1rem;
            right: unset;
        }
    }

    .page-leftheader .breadcrumb-item + .breadcrumb-item::before, .page-rightheader .breadcrumb-item + .breadcrumb-item::before {
        display: none;
    }

    .page-leftheader .breadcrumb-item + .breadcrumb-item::after, .page-rightheader .breadcrumb-item + .breadcrumb-item::after {
        color: #1e1e2d;
        padding-left: 0;
        font: var(--fa-font-solid);
        content: "\f100";
        font-weight: 400;
        float: none;
        font-size: 8px;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }

    .page-leftheader .breadcrumb-item a i {
        margin-right: 0!important;
        margin-left: 0.5rem;
    }

    .widget-user-image-sm {
        margin-right: 0!important;
        margin-left: 1rem;
    }

    .rtl-main-button {
        left: 0;
        right: unset!important;
    }

    .rtl-small-icon {
        margin-right: 0!important;
        margin-left: 0.5rem;;
    }

    .card-body {
        .template-icon {
            margin-left: 1rem;
        }
    }

    .favorite-dashboard-panel .template-dashboard a,
    .favorite-dashboard-panel .chat-boxes-dasboard a {
        margin-left: 1rem;
        float: left;
    }

    .favorite-dashboard-panel .chat-boxes-dasboard .card .widget-user-image {
        margin-left: 1rem;
    }
}