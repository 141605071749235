/* ==========================================================================           
 *
 *    BACKEND RESPONSIVE STYLES
 *
 * ========================================================================== */
@media (max-width: 768px) {
    .header-brand-img {
        display: none;
    }

    .header-brand {
        min-width: 30px!important;
    }

    .app-header .header-brand-img.mobile-logo {
        display: block!important;
        height: 3rem;
    }

    #search-bar {
        display: none;
    }

    .header-notify {
        display: none;
    }

    .header-expand {
        display: none;
    }

    #special-buttons {
        display: inline-block!important;

        .dropdown {
            margin-bottom: 1rem;
        }
    }

    .pl-minify {
        padding-left: 12px!important;
    }

    .pr-minify {
        padding-right: 12px!important;
    }

    #card-minify {
        padding-left: 5px!important;
        padding-right: 5px!important;
    }

    #card-body-minify {
        padding-left: 10px!important;
        padding-right: 10px!important;
    }

    #database-backup {
        overflow-x: scroll;
        th, td { min-width: 200px; }
    }

    .prices-responsive {
        padding-left: 0!important;
        padding-right: 0!important;
    }

    #openai-form {
        > div.row {
            width: 100%!important;
            margin-left: 0!important;
            margin-right: 0!important;
            padding-top: 2rem!important;
        }

        >div.row > div {
            padding-left: 0;
            padding-right: 0;
        }

        .create-project {
            display: none;
        }

        #template-output .card-title {
            font-size: 12px;
        }
    }

    #awselect_template-selection {
        display: none!important;
    }

    .favorite-templates-panel {
        .nav-tabs {
            overflow-x: auto;
            overflow-y:hidden;
            flex-wrap: nowrap;
            padding-bottom: 10px;
         }
        .nav-tabs .nav-link {
           white-space: nowrap;
         }
    }

    .message-img {
        display: none!important;
    }

    #generating-message {
        left: 18%;
    }

    #generating-status {
        left: 12%;
    }

    #openai-form > div.row {
        padding-top: 0!important;
    }

    #balance-status span {
        padding-left: 0!important;
    }

    .chat-main-container .chat-sidebar-container {
        position: absolute;
        left: -100%;
        z-index: 1000;
        height: 100%;
        max-height: 100%;
        background-color: white;
        transition: all .5s ease;
        width: 270px;
    }

    .chat-main-container .chat-message-container {
        width: inherit!important;
    }

    .chat-main-container {
        min-width: inherit!important;
    }

    .chat-main-container #expand {
        display: block!important;
    }

    .chat-sidebar-container .extend {
		left: 0!important;
        transition: all .5s ease!important;
	}

    #voiceover-character-counter-top {
        display: block!important;

        #voiceover-character-counter {
            left: 0!important;
            margin-top: 14px;
            margin-left: 12px;
        }
    }

    #listen-text {
        margin-right: 0px!important;
        margin-bottom: 1rem;
    }

    #template-buttons-group {
        margin-top: 0.5rem;
    }

    .chat-main-container {
        max-height: max-content;
    }

    .chat-controllers {
        display: block;

        #message {
            margin-bottom: 1.5rem;
            margin-top: 1rem;
        }

        .chat-button-box {
            display: inline-flex;
            margin-right: 1rem;
        }

        .upload-button-nonresponsive {
            display: none;
        }

        .upload-button-responsive {
            display: inline-flex;
        }

        #chat-button {
            margin-top: 1.5rem;
            margin-bottom: 0.5rem;
            margin-right: 0;
        }

        .no-margin-right {
            margin-right: 0;
        }
    }

    #chat-system {
        .card-footer {
            text-align: center;
        }
    }

    .wizard-nav .wizard-nav-inner .wizard-nav-text .wizard-step-number {
        display: none;
    }

    .wizard-nav .wizard-nav-inner .wizard-nav-text .wizard-step-title .wizard-step-title-number {
        display: none;
    }

    .wizard-nav .wizard-nav-inner .wizard-nav-text .responsive {
        padding-top: 0.6rem;
    }

    #wizard-results-wrapper {
        margin-left: 0;
    }

    #new-wizard {
        right: 0.9rem;
        top: -80%;
    }

    #template-textarea {
        margin-top: 2.5rem;
    }

    .chat-sidebar-container-special {
        width: 300px !important;
    }

    .responsive-chat-web {
        width: 10%!important;
    }

    .smart-header .go-back {
        margin-top: 0.7rem!important;
        margin-bottom: 0!important;
        margin-left: 0px!important;
    }

    .smart-header #balance {
        display: none!important;
    }

    #main-templates-container {
        margin-top: 0;
    }

    .smart-article-editor .tox-tinymce {
        margin-left: 0;
        margin-right: 0;
    }

    .smart-article-editor .tox-edit-area {
        margin: 25px 15px !important;
    }

    .responsive-left-column,
    .responsive-right-column {
        width: 0%!important;
    }

    #meta-container-editor {
        position: fixed;
        right: auto;
    }

    #show-left-menu,
    #show-right-menu,
    #hide-right-menu,
    #hide-left-menu {
        display: block;
    }

    #chat-internet-button,
    #chat-export-button {
        display: none;
    }

    .favorite-dashboard-panel .chat-boxes-dasboard,
    .favorite-dashboard-panel .template-dashboard {
        min-width: 450px;
    }

    .responsive-dashboard-table {
        width: 100%;
        overflow-x: scroll;
    }

}


/* ==========================================================================           
 *
 *    FRONTEND RESPONSIVE STYLES
 *
 * ========================================================================== */
@media (max-width: 768px) {

    html, body {
        width: auto!important; 
        overflow-x: hidden!important;
    }

    .fixed-top {
        position: relative!important;
    }

    form {
        width: 100%!important;
    }

    .upload-responsive {
        padding-left: auto!important;
        margin-left: auto!important;
    }

    .pl-8 {
        padding-left: 1rem!important;
    }

    #main-wrapper {

        .fixed-top {
            background: #FFF;
        }

        .ah-words-wrapper {
            text-align: center!important;
        }

        .text-container {

            h1 {
                font-size: 30px!important;
            }

            p {
                display: none!important;
                font-size: 14px!important;
            }

            a {
                margin-top: 1.5rem;
            }
        }
        
        #special-image-margin {
            margin-top: 3rem!important;
        }

        .frontend-buttons {
            margin-left: 0rem!important;
          }
    }

    #features-wrapper {
        padding-top: 64px!important;
    }

    #solutions-list {
        text-align: center;
    }

    #contact-wrapper {
        img {
            display: none;
        }

        form {
            width: 100%;
        }
    }
    .central-banner {
        position: relative!important;
    }

    .copyright-left {
        float: none!important;
        text-align: center;
    }

    .copyright-right {
        display: none!important;
    }

    #back-to-top {
        display: none!important;
    }

    #footer-logo {
        margin-bottom: 2rem;
    }

    .central-banner {
        .pl-9 {
            padding-left: 0!important;
        }

        .pr-9 {
            padding-right: 0!important;
        }

        h2 {
            font-size: 1.5rem;
        }

        p {
            font-size: 12px;
        }

    }

    .features-nav-menu-inner .nav-tabs {
        overflow: overlay;
        flex-wrap: nowrap !important;
        white-space: nowrap !important;
        overflow-y: hidden;
        justify-content: normal;

        .nav-item {
            margin-right: 7px;
        }
    }

    #info-banner .container .info-box {
        margin-bottom: 1.5rem;
        margin-left: auto!important;
        margin-right: auto!important;
    }

    #login-buttons {
        width: 100%!important;
        text-align: center!important;
    }

    #login-button {
        color: $primaryTextColor!important;
    }

    #login-responsive {
        .card-body {
            padding: 5rem 1.5rem!important;
        }

        #login-footer {
            position: relative;
            left: auto!important;
        }
    }

    #login-background {
        display: none;
    }

    .nav-item {
        width: 100%!important;
    }

    #footer {
        padding-left: 0!important;
    }

    .frontend-body {

        .navbar {
            padding: 0;
        }
        
        .navbar-collapse {
            background: #FFF;
            padding: 1rem;
            box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
        }

        .navbar-nav li.nav-item a.nav-link {
            color: #1e1e2d!important;
        }
    }

    #welcome-footer #footer h1 {
        font-size: 24px;
    }

    #welcome-footer #footer h3 {
        font-size: 14px;
    }

    #copyright #frontend-local,
    #copyright #frontend-copyright {
        float: none!important;
        text-align: center!important;
    }

    #copyright #footer-icons {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .policy {

        .card-body {
            padding: 4rem 1rem!important;
        }
    }

    #blog-view-mobile {
        .richText-editor {
            height: auto!important;
        }
    }

    #voice-minify {
        padding-right: 13px!important;
    }

    .special-buttons {
        margin-bottom: 1rem;
    }

    #textarea-box {
        margin-bottom: 1rem!important;
    }

    .textarea-voice,
    .textarea-actions {
        display: none!important;
    }

    .textarea-text {
        margin: 0!important;
    }

    .textarea-row {
        padding: 10px 8px!important;
    }

    #tts-body-minify {
        padding-left: 1rem!important;
        padding-right: 1rem!important;
        padding-top: 1.5rem!important;
    }

    #synthesize-text-form {
        width: 100%;
    }

    #preview-box {
        display: none;
    }

    #special-buttons {
        display: inline-block!important;

        .dropdown {
            margin-bottom: 1rem;
        }
    }

    .review-card-scroll {
        width: 300px;
    }

    .row .title h3 {
        font-size: 22px;
    }

    .row .title p {
        font-size: 10px;
    }

    .slider-container .marquee-slider-content {
        min-width: auto;

        .visualize-responsize {
            font-size: 14px;
        }
    }

    #features-wrapper .features-outer-wrapper .features-inner-wrapper .feature-title h4 {
        font-size: 24px!important;
    }

    #steps-wrapper .steps-box-wrapper {
        margin-bottom: 1.5rem;
    }

    #templates-wrapper .template-nav-menu-inner {
        width: auto;
    }

    .registration-nav .registration-nav-inner .wizard-nav-text {
        justify-content: left;
        margin-top: 5px;
    }

    .current-sign {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1480px) {
    #login-responsive {
        .card-body {
            padding-left: 1rem!important;
            padding-right: 1rem!important;
        }
    }
}

@media screen and (max-width: 1300px) {
    .slider-image-container.left-60 {
        justify-content: center;
        margin-left: 0;
        position: absolute;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
    }
  
    .marquee-slider-content {
      max-width: none;
      background-color: #000;
      margin-left: 0;
      margin-right: 0;
      padding: 46px 24px;
      position: relative;
    }
  
    .m-marquee-slider-content.bottom {
      background-image: linear-gradient(#000 32%,rgba(255,255,255,0));
      margin-top: 0;
      margin-bottom: -40px;
      top: auto;
      bottom: 0%;
      left: 0%;
      right: 0%;
    }
  
    .m-marquee-slider-content {
      width: 100%;
      height: 60px;
      background-image: linear-gradient(rgba(255,255,255,0),#000 68%);
      margin-top: -40px;
      padding-bottom: 0;
      padding-right: 0;
      display: block;
      position: absolute;
      top: 0%;
      bottom: auto;
      left: 0%;
      right: 0%;
    }
  
    .slider-image-container.right-60 {
      display: none;
    }
  
  }
  
  @media only screen and (max-width: 768px) {
    .marquee-slider-content {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
  }

  @media screen and (max-width: 940px) { 
    #main-settings-toggle-minimized {
        display: block!important;
    }

    #style-button {
        display: none;
    }

    .hide-all {
        display: none!important;
    }
    
    .show-all {
        display: block!important;
    }

    .image-prompt-wrapper .image-prompt .input-box {
        width: 95%;
    }

    .image-prompt-wrapper .image-prompt #image-generate {
        padding-left: 0;
        padding-right: 0;
        min-width: 130px;
        font-size: 12px;
    }

  }